import React from "react";
import { Row, Col } from "antd";
import { useMedia } from "use-media";

const TitleAndValue = (props) => {
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });

  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const { title, value } = props;
  return (
    <Row gutter={[10, 0]} align="middle">
      <Col style={{ color: "white", fontSize: isXxl ? "22px" : "15px" }}>
        {title} :
      </Col>
      <Col style={{ color: "white", fontSize: isXxl ? "22px" : "15px" }}>
        {value}
      </Col>
    </Row>
  );
};

export default TitleAndValue;
