// import { processColor } from "react-native";

const Colors = {
  WhiteOpacity: (opacity = "0.5") => `rgba(255, 255, 255, ${opacity})`,
  BlackOpacity: (opacity = "0.5") => `rgba(0, 0, 0, ${opacity})`,
  PlaceHolder: (opacity = "0.5") => `rgba(63, 63, 65, ${opacity})`,
  Transparent: "transparent",
  Primary: "#E06030",
  //Secondary: "#3D801C",
  Yellow: "#F5BA3C",
  SeaGreen: "#009498",
  Secondary: "#4E5FF8",
  Purple: "#7787FF",
  Red: "#FF494C",
  Blue: "#154D94",
  LightBlue: "#7787FF",
  Orange: "#FFA500",
  Black: "#1E1E22",
  White: "#ffffff",
  Shadow: "#3570AB12",
  WhiteText: "#F5F5F5",
  Text: "#40514E",
  TextColorOpacity: (opacity = 0.15) => `rgba(64, 81, 78, ${opacity})`,
  Danger: "#FF494C",
  Cyan: "#4BE5C0",
  LightRed: "#F8604E",
  LightCyan: "#fbfcfc",
};

export default Colors;
