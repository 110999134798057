import React from "react";
import { Row, Col, Progress } from "antd";
import { Colors, Images } from "../../config";
import { useHistory } from "react-router-dom";
import { Image } from "..";
import useMedia from "use-media";
const LoginBox = (props) => {
  const history = useHistory();
  const isLow = useMedia({ minWidth: "500px" });
  const {
    isLogo,
    isChangePassword,
    children,
    isDontShow,
    isLabel,
    timer,
    countdown,
    apiCall
  } = props;
  return (
    <Row
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      justify="center"
    >
      <Col span={24} style={{width: !isLow && "100%"}}>
        <Row justify="center" align="middle">
          {isLogo ? (
            <Col span={24} style={{ marginBottom: "10px" }}>
              <Image src={Images.Logo}></Image>
            </Col>
          ) : null}
        </Row>
      </Col>
      <Col span={24} style={{width: !isLow && "100%"}}>
        <Row
          gutter={[0, 50]}
          justify="center"
          align="middle"
          style={{ flexDirection: "column" }}
        >
          <Col style={{width: !isLow ? "29%" : "24rem", minWidth: !isLow && "320px"}}>
            <Row
              style={{
                boxShadow: "0px 5px 20px #1E1E2214",
                height: isChangePassword ? "27.5rem" : "22rem",
                borderRadius: "10px",
                opacity: "1"
              }}
              justify="center"
              align="middle"
            >
              {children}
            </Row>
          </Col>
          <Col>
            <Row justify="center" align="middle">
              {isLogo || isDontShow ? null : (
                <Col span={24}>
                  {isLogo ? null : isDontShow ? null : isLabel ? (
                    timer == true ? (
                      <p>
                        Didn't Recieve a Code?{" "}
                        <Progress
                          //className="card-link-out"
                          width="50px"
                          format={(per) => countdown}
                          type="circle"
                          strokeColor={{
                            "0%": Colors.Primary,
                            "100%": Colors.Primary,
                          }}
                          percent={(countdown / 60) * 100}
                        />
                      </p>
                    ) : (
                      <p>
                        Didn't Recieve a Code?{" "}
                        <label
                          style={{
                            cursor: "pointer",
                            color: Colors.Red,
                            textDecoration: "underline",
                          }}
                          onClick={() => apiCall()}
                          //className="card-link-out"
                        >
                          Resend
                        </label>
                      </p>
                    )
                  ) : (
                    <label
                      style={{
                        cursor: "pointer",
                        color: Colors.Red,
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      Back to Login
                    </label>
                  )}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default React.memo(LoginBox);