import React, { useEffect, useState } from "react";
import Info from "../../components/message";
import { Row, Col, Modal } from "antd";
import { useMedia } from "use-media";
import adGender from "../../assets/images/adGender.svg";
import adCountry from "../../assets/images/adCountry.svg";
import adUrl from "../../assets/images/adUrl.svg";
import adTopic from "../../assets/images/adTopic.svg";
import addAdIcon from "../../assets/images/addAdIcon.svg";
import { AD_GENDER_OPTIONS } from "../../config/variables";
import "./styles.css";
import Select from "react-select";
import CustomeAdsManagementAction from "../../store/actions/appactions/CustomAdsManagementAction";
import { DashboardAction } from "../../store/actions";
import InputWrapper from "../../components/input";
import Image from "../../components/image/index";
import { ButtonForm } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const BasicForm = (props) => {
  const {
    countriesOptions,
    topicsOptions,
    basic,
    isUpdate,
    modal,
    offset,
    data,
    onCancel,
    type,
  } = props;

  const [values, setValues] = useState({
    gender: [],
    topic_ids: [],
    countries: [],
    primary_file: "",
    primary_url: "",
    advertisement_category: "",
    save_as: "",
    isPublish: false,
    isDraft: false,
    isUpdateImg: false,
    img: "",
    showCountries: true,
    showTopics: true,
    showGender: true,
  });
  const [options, setOptions] = useState([]);
  const [countryOp, setCountryOp] = useState([{ label: "All", value: 0 }]);
  const [genderOp, setGenderOp] = useState([{ label: "All", value: 0 }]);
  const [topicOp, setTopicOp] = useState([{ label: "All", value: 0 }]);

  useEffect(() => {
    setCountryOp([{ label: "All", value: 0 }, ...countriesOptions]);
    setTopicOp([{ label: "All", value: 0 }, ...topicsOptions]);
    setGenderOp([{ label: "All", value: 0 }, ...AD_GENDER_OPTIONS]);
  }, [countriesOptions, topicsOptions, AD_GENDER_OPTIONS]);
  const {
    gender,
    topic_ids,
    countries,
    primary_file,
    primary_url,
    isPublish,
    isDraft,
    advertisement_category,
    save_as,
    isUpdateImg,
    img,
    showCountries,
    showTopics,
    showGender,
  } = values;

  const [errors, setErrors] = useState({
    genderError: "",
    topicIdsError: "",
    countriesError: "",
    primaryFileError: "",
    primaryUrlError: "",
  });

  const {
    genderError,
    topicIdsError,
    countriesError,
    primaryFileError,
    primaryUrlError,
  } = errors;

  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  const dispatch = useDispatch();
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setValues({
        ...values,
        isUpdateImg: true,
        img: reader.result,
        primary_file: file,
      });
    };
    reader.onerror = function (error) {};
  }

  function handleUpload(val) {
    const fileSize = val.target.files[0].size / 1024 / 1024;
    const fileType = val.target.files[0].type;
    if (fileSize > 15) {
      Info("error", "Image should be less than 15mb!");
      return;
    }

    if (fileType == "image/gif") {
      Info("error", "Please upload image only!");
      return;
    }

    if (
      fileType == "video/mp4" ||
      fileType == "video/quicktime" ||
      fileType == "video/x-matroska"
    ) {
      Info("error", "Please upload image only");
    } else {
      getBase64(val.target.files[0]);
      setErrors({ ...errors, primaryFileError: "" });
    }
  }
  function onHandlePublish(obj, _isPublish) {
    const { gender, countries, topic_ids, primary_url, primary_file } = obj;
    let objError = {};
    if (
      !gender.length ||
      !topic_ids.length ||
      !countries.length ||
      primary_url == "" ||
      primary_file == ""
    ) {
      if (!gender?.length) {
        objError = {
          ...objError,
          genderError: "Please Select the gender!",
        };
      }
      if (!countries?.length) {
        objError = {
          ...objError,
          countriesError: "Please Select the country!",
        };
      }
      if (!topic_ids?.length) {
        objError = {
          ...objError,
          topicIdsError: "Please Select the topic!",
        };
      }
      if (primary_url == "") {
        objError = {
          ...objError,
          primaryUrlError: "Please Enter the URL!",
        };
      }

      if (primary_file == "") {
        objError = {
          ...objError,
          primaryFileError: "Please Attach the image!",
        };
      }

      setErrors({
        ...objError,
      });
    } else {
      dispatch(
        CustomeAdsManagementAction.AddCustomAdRequest(
          {
            countries,
            gender,
            topic_ids,
            primary_file: values.primary_file,
            type: "image",
            action: "clickable",
            primary_url,
            save_as: _isPublish ? "publish" : "draft",
            current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
            advertisement_category: "basic",
          },
          () => {
            onCancel();
            dispatch(
              CustomeAdsManagementAction.CustomAdsRequest({
                limit: 10,
                offset: offset,
                filter: Object.keys(type).filter((key) => type[key]),
              })
            );
          }
        )
      );
    }
  }
  var refImage;
  const createAdLoader = useSelector(
    (state) => state.CustomAdsManagementReducer.createAdLoader
  );

  useEffect(() => {
    setValues({
      ...values,
      gender: [],
      topic_ids: [],
      countries: [],
      primary_file: "",
      primary_url: "",
      advertisement_category: "",
      save_as: "",
      isPublish: false,
      isDraft: false,
      isUpdateImg: false,
      img: "",
    });
  }, []);

  return (
    <Row justify="center" style={{ heigth: "100%" }}>
      <Col span={20}>
        <Row gutter={[0, 30]} align="middle" style={{ padding: "20px 0px" }}>
          <Col span={24} style={{ height: isXxl ? "320px" : "270px" }}>
            <Row
              justify="space-between"
              style={{ height: "100%" }}
              align="middle"
            >
              <Col
                span={8}
                style={{
                  height: isXxl ? "215px" : "180px",
                  width: "100%",
                  border: "2px #00767A solid ",
                  cursor: "pointer",
                }}
                onClick={() => refImage.click()}
              >
                <Row justify="center" align="middle" style={{ height: "100%" }}>
                  <Col
                    style={{
                      height: isUpdateImg && "100%",
                      opacity: isUpdateImg ? "100%" : "20%",
                    }}
                    span={isUpdateImg ? 24 : 8}
                    align="middle"
                  >
                    <Image
                      src={isUpdateImg ? img : addAdIcon}
                      style={{
                        height: isUpdateImg ? "100%" : "",
                        width: isUpdateImg ? "100%" : "",
                      }}
                    />
                  </Col>
                </Row>
                <div
                  style={{
                    color: "black",
                    alignItems: "center",
                  }}
                >
                  {primaryFileError}
                </div>
              </Col>

              <input
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={(e) => handleUpload(e)}
                onClick={(event) => {
                  event.target.value = null;
                }}
                style={{ display: "none" }}
                ref={(ref) => (refImage = ref)}
              />

              <Col span={15}>
                <Row gutter={[10, 10]}>
                  <Col span={12}>
                    <Select
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 40,
                          minHeight: 20,
                          overflow: "auto",
                          background: "#5bbbbd",
                          borderRadius: "15px",
                          border: "none",
                        }),
                      }}
                      options={genderOp}
                      className="newControl "
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder={
                        <Row gutter={[5, 0]}>
                          <Col>
                            <Image src={adGender} />
                          </Col>
                          <Col
                            style={{
                              color: "white",
                              fontSize: isXxl ? "18px" : "16px",
                            }}
                          >
                            Gender
                          </Col>
                        </Row>
                      }
                      defaultValue={[]}
                      isMulti
                      onChange={(e) => {
                        if (!e.length) {
                          values.showGender = true;
                        }
                        if (!e.filter((i) => i.label == "All").length) {
                          setGenderOp([
                            { label: "All", value: 0 },
                            ...AD_GENDER_OPTIONS,
                          ]);
                        }
                        const gendersToShow = e.map((item) => {
                          if (item.label == "All") {
                            values.showGender = false;
                            setGenderOp([]);

                            return item.value;
                          } else {
                            if (genderOp.find((item) => item.label === "All")) {
                              genderOp.shift();
                            }
                            setGenderOp(genderOp);
                            return item.value;
                          }
                        });

                        setValues({ ...values, gender: gendersToShow });
                        setErrors({ ...errors, genderError: "" });
                      }}
                    />
                    <span
                      style={{
                        color: "black",
                        alignItems: "center",
                      }}
                    >
                      {genderError}
                    </span>
                  </Col>
                  <Col span={12}>
                    <Select
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 40,
                          minHeight: 20,
                          overflow: "auto",
                          background: "#5bbbbd",
                          borderRadius: "15px",
                          border: "none",
                        }),
                      }}
                      options={countryOp}
                      className="newControl"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder={
                        <Row gutter={[5, 0]}>
                          <Col>
                            <Image src={adCountry} />
                          </Col>
                          <Col
                            style={{
                              color: "white",
                              fontSize: isXxl ? "18px" : "16px",
                            }}
                          >
                            Country
                          </Col>
                        </Row>
                      }
                      defaultValue={[]}
                      isMulti
                      onChange={(e) => {
                        if (!e.length) {
                          values.showCountries = true;
                        }
                        if (!e.filter((i) => i.label == "A").length) {
                          setCountryOp([
                            { label: "All", value: 0 },
                            ...countriesOptions,
                          ]);
                        }

                        const countryToShow = e.map((item) => {
                          if (item.label == "All") {
                            values.showCountries = false;
                            setCountryOp([]);
                            return item.value;
                          } else {
                            if (
                              countryOp.find((item) => item.label === "All")
                            ) {
                              countryOp.shift();
                            }
                            setCountryOp(countryOp);
                            return item.value;
                          }
                        });
                        setValues({ ...values, countries: countryToShow });
                        setErrors({ ...errors, countriesError: "" });
                      }}
                    />
                    <span
                      style={{
                        color: "black",
                        alignItems: "center",
                      }}
                    >
                      {countriesError}
                    </span>
                  </Col>
                  <Col span={24}>
                    <Select
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 40,
                          minHeight: 20,
                          overflow: "auto",
                          background: "#5bbbbd",
                          borderRadius: "15px",
                          border: "none",
                        }),
                      }}
                      options={topicOp}
                      className="newControl"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder={
                        <Row gutter={[5, 0]}>
                          <Col>
                            <Image src={adTopic} />
                          </Col>
                          <Col
                            style={{
                              color: "white",
                              fontSize: isXxl ? "18px" : "16px",
                            }}
                          >
                            Topics
                          </Col>
                        </Row>
                      }
                      defaultValue={[]}
                      isMulti
                      onChange={(e) => {
                        if (!e.length) {
                          values.showTopics = true;
                        }
                        if (!e.filter((i) => i.label == "All").length) {
                          setTopicOp([
                            { label: "All", value: 0 },
                            ...topicsOptions,
                          ]);
                        }

                        const topicsToShow = e.map((item) => {
                          if (item.label == "All") {
                            values.showTopics = false;
                            setTopicOp([]);
                            return item.value;
                          } else {
                            if (topicOp.find((item) => item.label === "All")) {
                              topicOp.shift();
                            }
                            setTopicOp(topicOp);
                            return item.value;
                          }
                        });

                        setValues({ ...values, topic_ids: topicsToShow });
                        setErrors({ ...errors, topicIdsError: "" });
                      }}
                    />
                    <span
                      style={{
                        color: "black",
                        alignItems: "center",
                      }}
                    >
                      {topicIdsError}
                    </span>
                  </Col>
                  <Col span={24}>
                    <InputWrapper
                      value={primary_url}
                      placeholder="url"
                      className="url-input"
                      prefix={<Image src={adUrl} />}
                      AdInput
                      style={{
                        height: isXxl ? "65px" : "40px",
                        color: "white",
                      }}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          primary_url: e.target.value.trim(),
                        });

                        setErrors({
                          ...errors,
                          primaryUrlError: "",
                        });
                      }}
                    />
                    <span
                      style={{
                        color: "black",
                        alignItems: "center",
                      }}
                    >
                      {primaryUrlError}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="center" gutter={[20, 0]}>
                  <Col span={8}>
                    <ButtonForm
                      isModal
                      loading={isPublish && createAdLoader}
                      onClick={() => {
                        onHandlePublish({ ...values }, true);
                        setValues({ ...values, isPublish: true });
                      }}
                      style={{
                        backgroundColor: "#E06030",
                        color: "white",
                        fontSize: isXxl ? "18px" : "14px",
                        height: isXxl ? "56px" : "40px",
                      }}
                      span={12}
                    >
                      Publish
                    </ButtonForm>
                  </Col>
                  <Col span={8}>
                    <ButtonForm
                      isModal
                      loading={isDraft && createAdLoader}
                      onClick={() => {
                        onHandlePublish({ ...values }, false);
                        setValues({ ...values, isDraft: true });
                      }}
                      style={{
                        backgroundColor: "#F5BA3C",
                        color: "white",
                        fontSize: isXxl ? "18px" : "14px",
                        height: isXxl ? "56px" : "40px",
                      }}
                      span={12}
                    >
                      Draft
                    </ButtonForm>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BasicForm;
