import React, { useState, useEffect } from "react";
import { Row, Col, Spin } from "antd";
import Loader from "../../components/loading";
import {
  Blip,
  SearchBar,
  HeaderComponent,
  TransactionTable,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import Posts from "./Posts";
import PostRanking from "./PostRanking";
import Image from "../../components/image";
import Users from "./Users";
import ReportedPostBlockParameters from "./ReportedPostBlockParameters";
import AlternativePostWeight from "./AlternativePostWeight";
import configAndSettingsIcon from "../../../src/assets/images/configAndSettingsIcon.svg";
import ConfigAndSettingAction from "../../store/actions/appactions/ConfigAndSettingAction";
import { ButtonForm } from "../../components";
import Info from "../../components/message";
const ConfigurationAndSettings = () => {
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const dashboard_array = useSelector(
    (state) => state?.ConfigAndSettingReducer?.configAndSettings
  );
  useEffect(() => {
    dispatch(ConfigAndSettingAction.ConfigAndSettingsRequest());
  }, []);
  const config_and_settings_data = useSelector(
    (state) => state?.ConfigAndSettingReducer?.configAndSettings
  );

  const updateConfigSettingLoader = useSelector(
    (state) => state?.ConfigAndSettingReducer?.updateConfigSettingLoader
  );

  const ConfigSettingRequestLoader = useSelector(
    (state) => state?.ConfigAndSettingReducer?.configAndSettingLoader
  );

  useEffect(() => {
    setValues({
      //Posts State
      post_uploaded_point: valuesOfPosts ? valuesOfPosts[0] : 0,
      post_retention_period: valuesOfPosts ? valuesOfPosts[1] : 0,
      new_post_days: valuesOfPosts ? valuesOfPosts[2] : 0,

      //Users State

      allow_auto_login: statusOfUsers ? statusOfUsers[0] : "",
      add_mob: statusOfUsers ? statusOfUsers[1] : "",
      custom_ad: statusOfUsers ? statusOfUsers[2] : "",
      minimum_reach_points: valuesOfUsers ? valuesOfUsers[3] : 0,
      show_ads_interval: valuesOfUsers ? valuesOfUsers[4] : 0,

      //post ranking state

      followers: valuesOfPostRanking ? valuesOfPostRanking[0] : 0,
      repeat_limit: valuesOfPostRanking ? valuesOfPostRanking[1] : 0,
      repeat_weight: valuesOfPostRanking ? valuesOfPostRanking[2] : 0,
      new_age: valuesOfPostRanking ? valuesOfPostRanking[3] : 0,
      age_gt_7: valuesOfPostRanking ? valuesOfPostRanking[4] : 0,
      age_gt_29: valuesOfPostRanking ? valuesOfPostRanking[5] : 0,
      age_gt_100: valuesOfPostRanking ? valuesOfPostRanking[6] : 0,

      //reported post block parameter state
      percentage_of_views: valuesOfReportedPostBlockParameter
        ? valuesOfReportedPostBlockParameter[0]
        : 0,
      min_number_of_views: valuesOfReportedPostBlockParameter
        ? valuesOfReportedPostBlockParameter[1]
        : 0,

      within_10: valuesOfAlternativePostWeights
        ? valuesOfAlternativePostWeights[0]
        : 0,
      score_lt_20: valuesOfAlternativePostWeights
        ? valuesOfAlternativePostWeights[1]
        : 0,
      score_lt_30: valuesOfAlternativePostWeights
        ? valuesOfAlternativePostWeights[2]
        : 0,
      score_lt_40: valuesOfAlternativePostWeights
        ? valuesOfAlternativePostWeights[3]
        : 0,
      score_lt_50: valuesOfAlternativePostWeights
        ? valuesOfAlternativePostWeights[4]
        : 0,
      post_lt_y_days_old: valuesOfAlternativePostWeights
        ? valuesOfAlternativePostWeights[5]
        : 0,
      post_gt_x_days_old: valuesOfAlternativePostWeights
        ? valuesOfAlternativePostWeights[6]
        : 0,
      disabled: true,
    });
  }, [config_and_settings_data]);

  //arrays from reducer

  const posts_data = useSelector(
    (state) => state?.ConfigAndSettingReducer?.posts
  );

  const users = useSelector((state) => state?.ConfigAndSettingReducer?.users);
  const post_ranking = useSelector(
    (state) => state?.ConfigAndSettingReducer?.post_ranking
  );
  const reported_post_block_parameters = useSelector(
    (state) => state?.ConfigAndSettingReducer?.reported_post_block_parameters
  );
  const alternative_post_weights = useSelector(
    (state) => state?.ConfigAndSettingReducer?.alternative_post_weights
  );

  //values of array

  const valuesOfPosts = posts_data?.map((item) => {
    return item.value;
  });

  const valuesOfPostRanking = post_ranking?.map((item) => {
    return item.value;
  });

  const valuesOfReportedPostBlockParameter =
    reported_post_block_parameters?.map((item) => {
      return item.value;
    });

  const valuesOfAlternativePostWeights = alternative_post_weights?.map(
    (item) => {
      return item.value;
    }
  );

  const valuesOfUsers = users.map((item) => {
    return item.value;
  });

  const statusOfUsers = users.map((item) => {
    return item.status;
  });

  const [errors, setErrors] = useState({
    post_uploaded_pointError: "",
    post_retention_periodError: "",
    new_post_daysError: "",
    minimum_reach_pointsError: "",
    show_ads_intervalError: "",
    followersError: "",
    repeat_limitError: "",
    repeat_weightError: "",
    new_ageError: "",
    age_gt_7Error: "",
    age_gt_29Error: "",
    age_gt_100Error: "",
    percentage_of_viewsError: "",
    min_number_of_viewsError: "",
    within_10Error: "",
    score_lt_20Error: "",
    score_lt_30Error: "",
    score_lt_40Error: "",
    score_lt_50Error: "",
    post_lt_y_days_oldError: "",
    post_gt_x_days_oldError: "",
  });
  const handleNumber = (e) => {
    const numberRegx = /^[0-9]\d*(\.\d+)?$/;
    if (!numberRegx.test(e.target.value)) {
      setErrors({
        ...errors,
        [`${e.target.name}Error`]: "Please enter numbers only",
      });
      Info("error", "Please enter numbers only");
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        disabled: false,
      });

      return;
    }
    if (e.target.value > 1000) {
      setErrors({
        ...errors,
        [`${e.target.name}Error`]: "Number must be inside 1000",
      });
      Info("error", "Number must be inside 1000");

      setValues({
        ...values,
        [e.target.name]: e.target.value,
        disabled: false,
      });

      return;
    }
    setValues({ ...values, [e.target.name]: e.target.value, disabled: false });
    setErrors({
      ...errors,
      [`${e.target.name}Error`]: "",
    });
  };

  const handleToggle = (e, a) => {
    setValues({
      ...values,
      [a.target.name]: e == true ? "true" : "false",
      disabled: false,
    });
  };

  const onHandleSubmit = (obj) => {
    const {
      post_uploaded_point,
      post_retention_period,
      new_post_days,
      allow_auto_login,
      add_mob,
      custom_ad,
      minimum_reach_points,
      show_ads_interval,
      followers,
      repeat_limit,
      repeat_weight,
      new_age,
      age_gt_7,
      age_gt_29,
      age_gt_100,
      percentage_of_views,
      min_number_of_views,
      within_10,
      score_lt_20,
      score_lt_30,
      score_lt_40,
      score_lt_50,
      post_lt_y_days_old,
      post_gt_x_days_old,
    } = obj;

    if (
      !post_uploaded_point?.toString().length ||
      !post_retention_period?.toString().length ||
      !new_post_days?.toString().length ||
      !minimum_reach_points?.toString().length ||
      !show_ads_interval?.toString().length ||
      !followers?.toString().length ||
      !repeat_limit?.toString().length ||
      !repeat_weight?.toString().length ||
      !new_age?.toString().length ||
      !age_gt_7?.toString().length ||
      !age_gt_29?.toString().length ||
      !age_gt_100?.toString().length ||
      !percentage_of_views?.toString().length ||
      !min_number_of_views?.toString().length ||
      !within_10?.toString().length ||
      !score_lt_20?.toString().length ||
      !score_lt_30?.toString().length ||
      !score_lt_40?.toString().length ||
      !score_lt_50?.toString().length ||
      !post_lt_y_days_old?.toString().length ||
      !post_gt_x_days_old?.toString().length
    ) {
      const err = { ...errors };
      if (!post_uploaded_point?.toString().length) {
        err.post_uploaded_pointError = "Please Enter Post Uploaded Time!";
      }

      if (!post_retention_period?.toString().length) {
        err.post_retention_periodError = "Please Enter Post Retention Period!";
      }

      if (!new_post_days?.toString().length) {
        err.new_post_daysError = "Please Enter New Post Days!";
      }

      if (!minimum_reach_points?.toString().length) {
        err.minimum_reach_pointsError = "Please Enter Minimum Reach Points!";
      }

      if (!show_ads_interval?.toString().length) {
        err.show_ads_intervalError = "Please Enter show Ads Interval!";
      }

      if (!followers?.toString().length) {
        err.followersError = "Please Enter Followers!";
      }

      if (!repeat_limit?.toString().length) {
        err.repeat_limitError = "Please Enter Repeat limit!";
      }

      if (!repeat_weight?.toString().length) {
        err.repeat_weightError = "Please Enter Repeat Weight!";
      }

      if (!new_age?.toString().length) {
        err.new_ageError = "Please Enter New Age!";
      }

      if (!age_gt_7?.toString().length) {
        err.age_gt_7Error = "Please Enter age greater than 7!";
      }

      if (!age_gt_29?.toString().length) {
        err.age_gt_29Error = "Please Enter age greater than 29!";
      }

      if (!age_gt_100?.toString().length) {
        err.age_gt_100Error = "Please Enter age greater than 100!";
      }

      if (!percentage_of_views?.toString().length) {
        err.percent_age_of_viewsError = "Please Enter age greater than 100!";
      }

      if (!min_number_of_views?.toString().length) {
        err.min_number_of_viewsError = "Please Enter Minimum number of views";
      }

      if (!within_10?.toString().length) {
        err.within_10Error = "Please Enter within10";
      }

      if (!score_lt_20?.toString().length) {
        err.score_lt_20Error = "Please Enter score than 20";
      }

      if (!score_lt_30?.toString().length) {
        err.score_lt_30Error = "Please Enter score lt 30";
      }

      if (!score_lt_40?.toString().length) {
        err.score_lt_40Error = "Please Enter score lt 40";
      }

      if (!score_lt_50?.toString().length) {
        err.score_lt_50Error = "Please Enter score lt 50";
      }

      if (!post_lt_y_days_old?.toString().length) {
        err.post_lt_y_days_oldError = "Please Enter score lt 50";
      }

      if (!post_gt_x_days_old?.toString().length) {
        err.post_gt_x_days_oldError = "Please Enter score lt 50";
      }
      setErrors(err);

      return;
    } else {
      dispatch(
        ConfigAndSettingAction.UpdateConfigAndSettingsRequest(
          {
            ...values,
          },
          () => {
            dispatch(ConfigAndSettingAction.ConfigAndSettingsRequest());
            setValues({ ...values, disabled: true });
          }
        )
      );
    }
  };

  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  return (
    <HeaderComponent>
      {ConfigSettingRequestLoader ? (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col span={24}>
            <Spin size="large" />
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ padding: isXxl ? "4rem" : "2rem" }} gutter={[0, 20]}>
            <Col span={1}>
              <Image src={configAndSettingsIcon} />
            </Col>
            <Col
              span={24}
              style={{
                fontSize: isXxl ? "46px" : "30px",
                color: "#415465",
                fontWeight: "700",
              }}
              align="left"
            >
              Configuration & Settings
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={16}>
                  <Row gutter={[0, 20]}>
                    <Col xl={14} xxl={10}>
                      <Posts
                        data={values}
                        handleNumber={handleNumber}
                        errors={errors}
                      />
                    </Col>
                    <Col xl={24} xxl={23}>
                      <PostRanking
                        data={values}
                        handleNumber={handleNumber}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={8}>
                  <Users
                    data={values}
                    handleToggle={handleToggle}
                    handleNumber={handleNumber}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={14} xxl={10}>
              <ReportedPostBlockParameters
                data={values}
                handleNumber={handleNumber}
                errors={errors}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={20} xxl={20}>
              <AlternativePostWeight
                data={values}
                handleNumber={handleNumber}
                errors={errors}
              />
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: "10px" }}>
            <Col xs={20} sm={20} md={18} lg={8} xl={6} xxl={4}>
              {values.disabled ? null : (
                <ButtonForm
                  loading={updateConfigSettingLoader}
                  onClick={() => onHandleSubmit({ ...values })}
                >
                  Save
                </ButtonForm>
              )}
            </Col>
          </Row>
        </>
      )}
    </HeaderComponent>
  );
};

export default ConfigurationAndSettings;
