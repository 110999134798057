import logo from './logo.svg';
import './App.css';
import React from 'react';
import AuthRoutes from './config/routes/AuthRoutes';
function App() {
  return (
    <div className="App">
      <AuthRoutes />
    </div>
  );
}

export default React.memo(App);
