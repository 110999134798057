import React from "react";
import { Row, Col } from "antd";
import InputWrapper from "../../components/input";
import SwitchWrapper from "../../components/switch";
import { useMedia } from "use-media";

const TextAndToggle = (props) => {
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const { title, handleToggle, name, isPost } = props;
  return (
    <Row
      gutter={isPost ? [30, 0] : [0, 0]}
      justify={isPost ? null : "space-between"}
      align="middle"
    >
      <Col
        xxl={11}
        style={{
          fontSize: isXxl ? "16px" : isXl ? "12px" : isLg ? "15px" : "17px",
          color: isPost ? "white" : "black",
        }}
        align="left"
      >
        {title}
      </Col>
      <Col span={4}>
        <SwitchWrapper
          onChange={(e, a) => {
            handleToggle(e, a);
          }}
          checked={props.value == "true" ? true : false}
          name={name}
          style={{ width: "100%" }}
        />
      </Col>
    </Row>
  );
};

export default TextAndToggle;
