import React from "react";
//import { View, Text, Image, TouchableOpacity } from "react-native";
import { connect, useDispatch } from "react-redux";
import { Menu, Layout, Row, Col, Dropdown } from "antd";
import Image from "../image";
import { withRouter } from "react-router-dom";
import { FooterNav, NavigationBar } from "../../components";
import { Colors, Images } from "../../config";
import { AuthAction } from "../../store/actions";
import { RiShutDownLine, RiLock2Line } from "react-icons/ri";
import "../../index.css";
import { useState } from "react";
import useMedia from "use-media";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
const { Header, Content } = Layout;

const HeaderComponent = (props) => {
  const isWide = useMedia({ minWidth: "800px" });
  const isLow = useMedia({ minWidth: "370px" });
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, setUser] = useState(props?.user ? props?.user : "");
  const [username, setUsername] = useState("");
  const pathName = window.location.pathname;
  const isForm = pathName.includes("forms");
  //console.log("ISFORM: ", isForm, isWide, isLow)
  // const handleLogout = () => {
  //   //const {Signout} = this.props;
  //   dispatch(AuthAction.Signout());
  // };
  useEffect(() => {
    setUsername(user.firstName + " " + user.lastName);
  }, []);
  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#FFFFFF" }}>
      {isWide ? <NavigationBar /> : null}
      <Layout>
        {/* <Header style={{ backgroundColor: Colors.White }}>
          <div style={{ float: "right" }}>
            <Dropdown
              overlay={
                <Menu style={{ width: "230px" }}>
                  <div style={{ backgroundColor: Colors.Primary }}>
                    <h3
                      style={{
                        color: Colors.White,
                        marginLeft: "5px",
                        font: "normal normal bold 16px/19px Montserrat",
                        fontSize: "14px",
                        letterSpacing: "0.4px",
                      }}
                    >
                      {username}
                    </h3>
                    <label
                      style={{
                        color: Colors.White,
                        marginLeft: "5px",
                        font: "normal normal normal 15px/19px Montserrat",
                        opacity: "0.85",
                        fontSize: "12px",
                        letterSpacing: "0.38px",
                      }}
                    >
                      Admin
                    </label>
                  </div>
                  <Menu.Item
                    key="0"
                    onClick={() => {
                      history.push("/change-password");
                    }}
                  >
                    <Row>
                      <Col span={3}>
                        <RiLock2Line
                          style={{ color: Colors.Primary }}
                          size={20}
                        />
                      </Col>
                      <Col span={20}>Change Password</Col>
                    </Row>
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    key="1"
                    style={{ color: Colors.Red }}
                    onClick={handleLogout}
                  >
                    <Row>
                      <Col span={3}>
                        <RiShutDownLine size={20} />
                      </Col>
                      <Col span={20}>Logout</Col>
                    </Row>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              arrow
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Image
                  src={Images.Avatar}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    height: "2rem",
                    marginTop: "10px",
                  }}
                ></Image>
              </a>
            </Dropdown>
          </div>
        </Header> */}
        <Content
          style={{
            backgroundColor: Colors.White,
            boxShadow: "15px 0px 20px #1E1E220C",
            marginBottom:
              isForm && !isWide && !isLow
                ? "80px"
                : isForm && !isWide
                ? "92px"
                : !isWide
                ? "72px"
                : "0",
          }}
        >
          {props.children}
        </Content>
        {!isWide ? <FooterNav /> : null}
      </Layout>
    </Layout>
  );
};

export default React.memo(
  withRouter(
    connect((storeState) => ({ user: storeState?.AuthReducer?.user }))(
      HeaderComponent
    )
  )
);
