import { DashboardAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  COUNTRIES_REQUEST_ENDPOINT,
  DASHBOARD_REQUEST_ENDPOINT,
  NON_LIVE_REPORTS_REQUEST_ENDPOINT,
  SUSPICIOUS_ACTIVITY_ENDPOINT,
  GET_INTERACTIONS_DATA_ENDPOINT
} from "../../../config/variables";
import { put } from "redux-saga/effects";
import moment from "moment";
export default class DashboardMiddleware {
  static *GetDashboardStats(action) {
    let { params } = action;

    const qureyParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(
        `${DASHBOARD_REQUEST_ENDPOINT}?${qureyParams}`
      );

      if (res.status == 200) {
        yield put(DashboardAction.DashboardSuccess(res.data.success));
      } else {
        yield put(DashboardAction.DashboardFailure());
      }
    } catch (err) {
      yield put(DashboardAction.DashboardFailure());
    }
  }
  static *GetCountries(action) {
    try {
      let res = yield ApiCaller.Get(`${COUNTRIES_REQUEST_ENDPOINT}`);

      if (res.status == 200) {
        yield put(DashboardAction.CountriesSuccess(res.data.data));
      } else {
        yield put(DashboardAction.CountriesFailure());
      }
    } catch (err) {
      yield put(DashboardAction.CountriesFailure());
    }
  }

  static *NonLiveReportsStats(action) {
    console.log('NonLiveReportsStats_middleware: ', action);
    let { params } = action;

    const qureyParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(
        `${NON_LIVE_REPORTS_REQUEST_ENDPOINT}?${qureyParams}`
      );

      if (res.status == 200) {
        yield put(DashboardAction.NonLiveReportsSuccess(res.data.success));
      } else {
        yield put(DashboardAction.NonLiveReportsFailure());
      }
    } catch (err) {
      yield put(DashboardAction.NonLiveReportsFailure());
    }
  }

  static *SuspiciousAcitivityRequest(action) {
    try {
      let res = yield ApiCaller.Get(`${SUSPICIOUS_ACTIVITY_ENDPOINT}`);
      if (res.status == 200) {
        yield put(DashboardAction.SuspiciousActivitySuccess(res.data.data));
      } else {
        yield put(DashboardAction.SuspiciousActivityFailure());
      }
    } catch (err) {
      yield put(DashboardAction.SuspiciousActivityFailure());
    }
  }

  static *GetInteractionsData({payload}) {
    console.log('GetInteractionsData_middleware: ', payload);


    const qureyParams = new URLSearchParams(payload);
    console.log('qureyParams: ', qureyParams);
    try {
      let res = yield ApiCaller.Get(
        `${GET_INTERACTIONS_DATA_ENDPOINT}?${qureyParams}`
        );
        console.log('GetInteractionsData_res: ', res);

      if (res.status == 200) {
        yield put(DashboardAction.GetInteractionData_Success(res.data.success));
      } else {
        yield put(DashboardAction.GetInteractionData_Failure());
      }
    }
    catch(err){

    }
  }
}
