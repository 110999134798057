import { loginSchema } from "./login";
import { forgetPasswordSchema } from "./forgetpassword";
import { resetPasswordSchema } from "./resetpassword";
import { changePasswordSchema } from "./changepassword";

const loginValidations = loginSchema;
const forgetPasswordValidations = forgetPasswordSchema;
const resetPasswordValidations = resetPasswordSchema;
const changePasswordValidations = changePasswordSchema;

const Schemas = {
  loginValidations,
  forgetPasswordValidations,
  resetPasswordValidations,
  changePasswordValidations
};

export default Schemas;