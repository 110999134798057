import React from "react";
import { Row, Col } from "antd";
import InputWrapper from "../../components/input";
import TextAndInput from "./TextAndInput";
import { useMedia } from "use-media";

const Posts = (props) => {
  const { data, disabled, handleNumber, errors } = props;
  const { post_uploaded_point, post_retention_period, new_post_days } = data;
  const {
    post_uploaded_pointError,
    post_retention_periodError,
    new_post_daysError,
  } = errors;
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <Row
      style={{
        borderLeft: "8px solid #E06030",
        borderRadius: "9px",
        padding: "0rem 1rem",
      }}
    >
      <Col
        xl={7}
        xxl={5}
        style={{ fontSize: isXxl ? "28px" : isXl ? "20px" : "24px" }}
        align="left"
      >
        <h1>Posts</h1>
      </Col>
      <Col span={24}>
        <Row justify="left" gutter={[0, 10]}>
          <Col span={24}>
            <TextAndInput
              isPosts
              title="Post Uploaded Point"
              name="post_uploaded_point"
              placeholder={post_uploaded_point ? post_uploaded_point : 0}
              value={post_uploaded_point ? post_uploaded_point : 0}
              handleNumber={handleNumber}
              errors={post_uploaded_pointError}
            />
          </Col>

          <Col span={24}>
            <TextAndInput
              isPosts
              title="Post Retention Period"
              placeholder={post_retention_period ? post_retention_period : 0}
              name="post_retention_period"
              value={post_retention_period ? post_retention_period : 0}
              handleNumber={handleNumber}
              errors={post_retention_periodError}
            />
          </Col>

          <Col span={24}>
            <TextAndInput
              isPosts
              title="New Post days"
              placeholder={new_post_days ? new_post_days : 0}
              value={new_post_days ? new_post_days : 0}
              handleNumber={handleNumber}
              name="new_post_days"
              errors={new_post_daysError}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Posts;
