import {
  CONTENTS_REQUEST,
  CONTENTS_SUCCESS,
  CONTENTS_FAILURE,
  UPDATE_CONTENT_REQUEST,
  UPDATE_CONTENT_SUCCESS,
  UPDATE_CONTENT_FAILURE,
  POST_DETAILS_REQUEST,
  POST_DETAILS_SUCCESS,
  POST_DETAILS_FAILURE,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAILURE,
} from "../../../store/constants";

export default class ContentManagementAction {
  static ContentsRequest(params) {
    return {
      type: CONTENTS_REQUEST,
      params,
    };
  }
  static ContentsSuccess(payload, count) {
    return {
      type: CONTENTS_SUCCESS,
      count,
      payload,
    };
  }
  static ContentsFailure() {
    return {
      type: CONTENTS_FAILURE,
    };
  }

  static UpdateContentRequest(params, cb) {
    return {
      type: UPDATE_CONTENT_REQUEST,
      params,
      cb,
    };
  }
  static UpdateContentSuccess(payload) {
    return {
      type: UPDATE_CONTENT_SUCCESS,
      payload,
    };
  }
  static UpdateContentFailure() {
    return {
      type: UPDATE_CONTENT_FAILURE,
    };
  }

  static PostDetailsRequest(params) {
    return {
      type: POST_DETAILS_REQUEST,
      params,
    };
  }
  static PostDetailsSuccess(payload) {
    return {
      type: POST_DETAILS_SUCCESS,
      payload,
    };
  }
  static PostDetailsFailure() {
    return {
      type: POST_DETAILS_FAILURE,
    };
  }

  static UpdatePostRequest(params, cb) {
    return {
      type: UPDATE_POST_REQUEST,
      params,
      cb,
    };
  }
  static UpdatePostSuccess(payload) {
    return {
      type: UPDATE_POST_SUCCESS,
      payload,
    };
  }
  static UpdatePostFailure() {
    return {
      type: UPDATE_POST_FAILURE,
    };
  }
}
