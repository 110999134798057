import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  SignIn,
  ForgetPassword,
  VerifyCode,
  ResetPassword,
} from "../../containers";
import AppRoutes from "./AppRoutes";

class AuthRoutes extends React.Component {
  render() {
    const { isAuthenticated } = this.props;
    return (
      <div className="App">
        <Switch>
          {!isAuthenticated ? (
            <React.Fragment>
              <Switch>
                <Route exact path="/login" component={SignIn} />
                <Route
                  exact
                  path="/forget-password"
                  component={ForgetPassword}
                />
                <Route exact path="/verify-code" component={VerifyCode} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route path="*" render={() => <Redirect to="/login" />} />
              </Switch>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Switch>
                <Route path="/" component={AppRoutes} />
              </Switch>
            </React.Fragment>
          )}
        </Switch>
      </div>
    );
  }
}

export default withRouter(
  connect((storeState) => ({
    isAuthenticated: storeState.AuthReducer.isAuthenticated,
  }))(AuthRoutes)
);
