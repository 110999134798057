import {
  LOCATIONS_REQUEST,
  LOCATIONS_SUCCESS,
  LOCATIONS_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
} from "../../constants";

const initialState = {
  locations: [],
  getLocationsLoader: false,
  locationsCount: 0,
  updateLocationLoader: false,
};

export default function ConfigAndSettingReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATIONS_REQUEST:
      state = {
        ...state,
        getLocationsLoader: true,
      };
      break;

    case LOCATIONS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          locations: action ? action?.payload : [],
          locationsCount: action.count,
          getLocationsLoader: false,
        };
      }
    }

    case LOCATIONS_FAILURE:
      state = {
        ...state,
        getLocationsLoader: false,
      };
      break;

    case UPDATE_LOCATION_REQUEST:
      state = {
        ...state,
        updateLocationLoader: true,
      };
      break;

    case UPDATE_LOCATION_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          updateLocationLoader: false,
        };
      }
      break;

    case UPDATE_LOCATION_FAILURE:
      state = {
        ...state,
        updateLocationLoader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
