import {
  CONFIG_AND_SETTINGS_REQUEST,
  CONFIG_AND_SETTINGS_SUCCESS,
  CONFIG_AND_SETTINGS_FAILURE,
  UPDATE_CONFIG_AND_SETTINGS_REQUEST,
  UPDATE_CONFIG_AND_SETTINGS_SUCCESS,
  UPDATE_CONFIG_AND_SETTINGS_FAILURE,
} from "../../../store/constants";

export default class ConfigAndSettingAction {
  static ConfigAndSettingsRequest() {
    return {
      type: CONFIG_AND_SETTINGS_REQUEST,
    };
  }
  static ConfigAndSettingsSuccess(payload) {
    return {
      type: CONFIG_AND_SETTINGS_SUCCESS,
      payload,
    };
  }
  static ConfigAndSettingsFailure() {
    return {
      type: CONFIG_AND_SETTINGS_FAILURE,
    };
  }

  static UpdateConfigAndSettingsRequest(body, cb) {
    return {
      type: UPDATE_CONFIG_AND_SETTINGS_REQUEST,
      body,
      cb,
    };
  }
  static UpdateConfigAndSettingsSuccess(payload) {
    return {
      type: UPDATE_CONFIG_AND_SETTINGS_SUCCESS,
      payload,
    };
  }
  static UpdateConfigAndSettingsFailure() {
    return {
      type: UPDATE_CONFIG_AND_SETTINGS_FAILURE,
    };
  }
}
