import {
  TOPICS_REQUEST,
  TOPICS_SUCCESS,
  TOPICS_FAILURE,
  UPDATE_TOPIC_REQUEST,
  UPDATE_TOPIC_SUCCESS,
  UPDATE_TOPIC_FAILURE,
  ADD_TOPIC_REQUEST,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_FAILURE,
} from "../../../store/constants";

export default class LocationManagementAction {
  static TopicsRequest(params) {
    return {
      type: TOPICS_REQUEST,
      params,
    };
  }
  static TopicsSuccess(payload, count) {
    return {
      type: TOPICS_SUCCESS,
      payload,
      count,
    };
  }
  static TopicsFailure() {
    return {
      type: TOPICS_FAILURE,
    };
  }

  static UpdateTopicRequest(params, cb) {
    return {
      type: UPDATE_TOPIC_REQUEST,
      params,
      cb,
    };
  }
  static UpdateTopicSuccess(payload) {
    return {
      type: UPDATE_TOPIC_SUCCESS,
      payload,
    };
  }
  static UpdateTopicFailure() {
    return {
      type: UPDATE_TOPIC_FAILURE,
    };
  }

  static AddTopicRequest(body, cb) {
    return {
      type: ADD_TOPIC_REQUEST,
      body,
      cb,
    };
  }
  static AddTopicSuccess(payload) {
    return {
      type: ADD_TOPIC_SUCCESS,
      payload,
    };
  }
  static AddTopicFailure() {
    return {
      type: ADD_TOPIC_FAILURE,
    };
  }
}
