import {
  GETFORMS,
  GETFORMS_SUCCESS,
  GETFORMS_FAILURE,
  UPLOADFORMS,
  UPLOADFORMS_SUCCESS,
  UPLOADFORMS_FAILURE,
  LOADER_TRUE,
  LOADER_FALSE,
} from "../../constants";

const initialState = {
  tests: [],
  totaltests: "",
  loader: false,
  getFormLoader: false,
};

export default function FormReducer(state = initialState, action) {
  switch (action.type) {
    case GETFORMS:
      state = {
        ...state,
        getFormLoader: true,
      };
      break;

    case GETFORMS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          tests: action?.payload?.tests,
          totaltests: action?.payload?.totaltests,
          getFormLoader: false,
        };
      }
    }

    case GETFORMS_FAILURE:
      state = {
        ...state,
        getFormLoader: false,
      };
      break;

    case UPLOADFORMS:
      state = {
        ...state,
        loader: true,
      };
      break;

    case UPLOADFORMS_SUCCESS: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case UPLOADFORMS_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;

    case LOADER_TRUE:
      state = {
        ...state,
        loader: true,
      };
      break;

    case LOADER_FALSE:
      state = {
        ...state,
        loader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
