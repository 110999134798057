import React from "react";
import styled from "styled-components";
import { Switch } from "antd";

const StyledSwitch = styled(Switch)`
  &.ant-switch {
    background-color:  #FFFFFF !important;
    border:1px solid #017981 !important;
    height:26px
   }
  &.ant-switch-checked {
    background-color: #E06030 !important;
  }
  // &.ant-switch-checked.ant-switch::after {
  //   background-color: #FFFFFF !important;
  // }
  &.ant-switch::after {
    position: absolute;
    top: 1px;
    left: 1px;
    width: ${(props) =>
      props.size ? (props.size === "small" ? "12px" : "18px") : "18px"};
    height: 
    ${(props) =>
      props.size ? (props.size === "small" ? "12px" : "18px") : "18px"};
    background-color:#E06030
    border-radius: 18px;
    cursor: pointer;
    -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
  }
  &.ant-switch-checked::after {
    left: 100%;
    margin-left: -1px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
 
`;

const SwitchWrapper = (props) => {
  return <StyledSwitch {...props}></StyledSwitch>;
};

export default SwitchWrapper;
