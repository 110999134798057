import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const StyledInput = styled(Input).attrs()`
  border-radius: ${(props) =>
    props.isSetting
      ? "21px !important"
      : props.AdInput
      ? "15px"
      : "24px !important"};
  height: ${(props) =>
    props.isSetting
      ? "44px !important"
      : props.isChangePassword
      ? "40px"
      : props.AdInput
      ? "45px !important"
      : "65px"};
  padding: 1rem;
  color: ${(props) =>
    props.isLogin ? "#014247 !important" : props.AdInput ? "#FFFFFF" : ""};
  background: ${(props) =>
    props.isChangePassword ? "#EFEFEF" : props.AdInput ? "#5bbbbd" : ""};
  // opacity: ${(props) => (props.isLogin ? "15%" : "")};
  border: ${(props) =>
    props.isSetting && !props.errors
      ? "1px solid #017981 !important"
      : props.errors
      ? "1px solid red !important"
      : props.AdInput
      ? "none"
      : ""};
`;

const InputWrapper = (props) => {
  return props.password ? (
    <StyledInput as={Input.Password} {...props}></StyledInput>
  ) : (
    <StyledInput {...props}></StyledInput>
  );
};

export default InputWrapper;
// ${(props) =>
//   props.isSetting ? "44px !important" : "65px !important"}
