import { ProfileAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  PROFILE_REQUEST_ENDPOINT,
  ADD_PROFILE_ENDPOINT,
  ADD_TOPIC_ENDPOINT,
  CHANGE_PASSWORD_ENDPOINT,
} from "../../../config/variables";
import { put } from "redux-saga/effects";
import moment from "moment";
export default class ProfileRequestMiddleware {
  static *ProfileRequest(action) {
    try {
      let res = yield ApiCaller.Get(`${PROFILE_REQUEST_ENDPOINT}`);

      if (res.status == 200) {
        yield put(ProfileAction.ProfileSuccess(res.data.data));
      } else {
        yield put(ProfileAction.ProfileFailure());
      }
    } catch (err) {
      yield put(ProfileAction.ProfileFailure());
    }
  }
  static *AddProfileRequest(action) {
    let { body, cb } = action;

    try {
      let formData = new FormData();

      for (const key in body) {
        formData.append(key, body[key]);
      }

      let res = yield ApiCaller.Post(`${ADD_PROFILE_ENDPOINT}`, formData);

      if (res.status == 200) {
        yield put(ProfileAction.AddProfileSuccess(res.data.success));
        Info("success", res?.data?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error);
        yield put(ProfileAction.AddProfileFailure());
      }
    } catch (err) {
      yield put(ProfileAction.AddProfileFailure());
    }
  }

  static *ChangePasswordRequest(action) {
    let { body, cb } = action;
    delete body.confirm_password;
    try {
      let res = yield ApiCaller.Put(`${CHANGE_PASSWORD_ENDPOINT}`, { ...body });

      if (res.status == 200) {
        yield put(ProfileAction.ChangePasswordSuccess(res.data.success));
        Info("success", res?.data?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error);
        yield put(ProfileAction.ChangePasswordFailure());
      }
    } catch (err) {
      yield put(ProfileAction.ChangePasswordFailure());
    }
  }
}
