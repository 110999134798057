import { ConfigAndSettingAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  CONFIG_AND_SETTINGS_REQUEST_ENDPOINT,
  UPDATE_ADMIN_SETTINGS_ENDPOINT,
  DASHBOARD_REQUEST_ENDPOINT,
} from "../../../config/variables";
import { put } from "redux-saga/effects";
import moment from "moment";
export default class ConfigAndSettingMiddleware {
  static *GetConfigAndSetting() {
    try {
      let res = yield ApiCaller.Get(CONFIG_AND_SETTINGS_REQUEST_ENDPOINT);

      if (res.status == 200) {
        yield put(
          ConfigAndSettingAction.ConfigAndSettingsSuccess(res.data.success)
        );
      } else {
        yield put(ConfigAndSettingAction.ConfigAndSettingsFailure());
      }
    } catch (err) {
      yield put(ConfigAndSettingAction.ConfigAndSettingsFailure());
    }
  }
  static *UpdateConfigAndSetting(action) {
    let { body, cb } = action;

    const {
      add_mob,
      age_gt_7,
      age_gt_29,
      age_gt_100,
      allow_auto_login,
      custom_ad,
      disabled,
      followers,
      min_number_of_views,
      minimum_reach_points,
      new_age,
      new_post_days,
      percentage_of_views,
      post_gt_x_days_old,
      post_lt_y_days_old,
      post_retention_period,
      post_uploaded_point,
      repeat_limit,
      repeat_weight,
      score_lt_20,
      score_lt_30,
      score_lt_40,
      score_lt_50,
      show_ads_interval,
      within_10,
    } = body;
    const payload = {
      users: {
        allow_auto_login,
        add_mob,
        custom_ad,
        minimum_reach_points,
        show_ads_interval,
      },
      posts: { post_uploaded_point, post_retention_period, new_post_days },
      post_ranking: {
        followers,
        repeat_limit,
        repeat_weight,
        new_age,
        age_gt_7,
        age_gt_29,
        age_gt_100,
      },
      reported_post_block_parameters: {
        percentage_of_views,
        min_number_of_views,
      },
      alternative_post_weights: {
        within_10,
        score_lt_20,
        score_lt_30,
        score_lt_40,
        score_lt_50,
        post_lt_y_days_old,
        post_gt_x_days_old,
      },
      current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
    };
    try {
      let res = yield ApiCaller.Put(UPDATE_ADMIN_SETTINGS_ENDPOINT, payload);

      if (res.status == 200) {
        yield put(
          ConfigAndSettingAction.UpdateConfigAndSettingsSuccess(
            res.data.success
          )
        );
        Info("success", res?.data?.success?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error.message);
        yield put(ConfigAndSettingAction.UpdateConfigAndSettingsFailure());
      }
    } catch (err) {
      yield put(ConfigAndSettingAction.UpdateConfigAndSettingsFailure());
    }
  }
}
