import React from "react";
import Icon from "../Icon";
import styled from "styled-components";

const Loading = styled.div`
  & {
    //height: 100%;
    text-align: center;
    //position: fixed;
    margin: 30vh 0;
    // top: 50%;
    // left: 50%;
    // right: 50%;
    // bottom: 50%;
    // transform: translate(-50px, -50px);
  }
  & i {
    font-size: 4rem;
  }
`;

const Loader = props => {
  return (
    <Loading>
      <Icon type="loading" spin />
    </Loading>
  );
};

export default Loader;
