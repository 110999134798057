import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Colors } from "../../config";
import { useMedia } from "use-media";
import { Row, Col } from "antd";

const CustomTooltip = ({ payload }) => {
  return (
    <Row>
      <Col span={24}>
        <p>{payload?.[0]?.payload?.name}</p>
      </Col>
      <Col span={24}>
        {payload.map((item, i) => {
          return (
            <p
              style={{
                color:
                  payload?.[i]?.dataKey == "reported"
                    ? "#F5BA3C"
                    : payload?.[i]?.dataKey == "removed"
                      ? "#009498"
                      : payload?.[i]?.dataKey == "cleared"
                        ? "#71797E"
                        : "#E06030",
              }}
            >
              {payload?.[i]?.dataKey == "reported"
                ? "Reported" + " : " + payload?.[i]?.value
                : payload?.[i]?.dataKey == "removed"
                  ? "Admin-Suspended" + " : " + payload?.[i]?.value
                  : payload?.[i]?.dataKey == "cleared"
                    ? "Cleared" + " : " + payload?.[i]?.value
                    : "Auto-Suspended" + " : " + payload?.[i]?.value}
            </p>
          );
        })}
      </Col>
    </Row>
  );
};

class LineChartComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static demoUrl =
    "https://codesandbox.io/s/line-chart-width-xaxis-padding-sujqi";

  render() {
    const { isXs, isSm, isMd, isLg, isXl, isXxl } = this.props;

    const {
      isEvent,
      isSubscription,
      data,
      eventData,
      subscribedData,
      isWide,
      isMedia,
      isMediaLarge,
      STATIC_COLORS,
      isNoOfUsers,
      isReports,
      isInteractions,
      noOfUsersColor,
    } = this.props;
    const COLORS = [Colors.SeaGreen, Colors.Yellow, Colors.Primary];
    //   const arr= data.map(function(item) {
    //     delete item.name;
    //     return item;
    // });

    if (isNoOfUsers) {
      return (
        <>
          {data.length ? (
            <ResponsiveContainer width="100%" height={390}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  padding={{ left: 30, right: 30 }}
                  axisLine={{ stroke: "#fde8f1" }}
                />
                <YAxis padding={{ top: 5 }} axisLine={{ stroke: "#fde8f1" }} />
                <Tooltip />
                {/* <Legend
              verticalAlign="bottom"
              height={isMedia && !isMediaLarge ? 52 : isWide ? 36 : 0}
              payload={isSubscription ? isSubscribedPayload : payloadLegend}
              content={this.renderLegend}
            /> */}
                <Line
                  type="monotone"
                  dataKey={"active"}
                  stroke={"#009498"}
                  strokeWidth="4px"
                />{" "}
                <Line
                  type="monotone"
                  dataKey={"in_active"}
                  stroke={"#E06030"}
                  strokeWidth="4px"
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ height: "100%" }}
            >
              <Col span={24} align="center">
                <h4>No Data exists with these filters</h4>
              </Col>
            </Row>
          )}
        </>
      );
    }

    if (isReports) {
      return (
        <>
          {data.length ? (
            <ResponsiveContainer width="100%" height={390}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  padding={{ left: 30, right: 30 }}
                  axisLine={{ stroke: "#fde8f1" }}
                />
                <YAxis padding={{ top: 5 }} axisLine={{ stroke: "#fde8f1" }} />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey={"removed"}
                  stroke={"#009498"}
                  strokeWidth="4px"
                />{" "}
                <Line
                  type="monotone"
                  dataKey={"reported"}
                  stroke={"#F5BA3C"}
                  strokeWidth="4px"
                />
                <Line
                  type="monotone"
                  dataKey={"suspended"}
                  stroke={"#E06030"}
                  strokeWidth="4px"
                />
                <Line
                  type="monotone"
                  dataKey={"cleared"}
                  stroke={"#71797E"}
                  strokeWidth="4px"
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ height: "100%" }}
            >
              <Col span={24} align="center">
                <h4>No Data exists with these filters</h4>
              </Col>
            </Row>
          )}
        </>
      );
    }
    if (isInteractions) {
      return (
        <>
          {data.length ? (
            <ResponsiveContainer width="100%" height={390}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  padding={{ left: 30, right: 30 }}
                  axisLine={{ stroke: "#fde8f1" }}
                />
                <YAxis padding={{ top: 5 }} axisLine={{ stroke: "#fde8f1" }} />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey={"post_favourite_count"}
                  stroke={"#8F8F8F"}
                  strokeWidth="4px"
                />{" "}
                <Line
                  type="monotone"
                  dataKey={"post_seen_count"}
                  stroke={"#F5BA3C"}
                  strokeWidth="4px"
                />
                <Line
                  type="monotone"
                  dataKey={"post_click_count"}
                  stroke={"#009498"}
                  strokeWidth="4px"
                />{" "}
                <Line
                  type="monotone"
                  dataKey={"ads_seen_count"}
                  stroke={"#E06030"}
                  strokeWidth="4px"
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ height: "100%" }}
            >
              <Col span={24} align="center">
                <h4>No Data exists with these filters</h4>
              </Col>
            </Row>
          )}
        </>
      );
    }
    return (
      <>
        {data.length ? (
          <ResponsiveContainer width="100%" height={390}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                padding={{ left: 30, right: 30 }}
                axisLine={{ stroke: "#fde8f1" }}
              />
              <YAxis padding={{ top: 5 }} axisLine={{ stroke: "#fde8f1" }} />
              <Tooltip />
              {(data || []).map((item, index) => {
                const keys = Object.keys(item);
                keys.shift();

                return (
                  <Line
                    type="monotone"
                    dataKey={keys[index]}
                    strokeWidth="4px"
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ height: "100%" }}
          >
            <Col span={24} align="center">
              <h4>No Data exists with these filters</h4>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const MediaWrapper = (props) => {
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <LineChartComponent
      isXs={isXs}
      isSm={isSm}
      isMd={isMd}
      isLg={isLg}
      isXl={isXl}
      isXxl={isXxl}
      {...props}
    />
  );
};

export default React.memo(MediaWrapper);
