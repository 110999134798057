import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { useMedia } from "use-media";
import Image from "../../components/image/index";
import changePasswordLogo from "../../assets/images/changePasswordLogo.svg";
import adminRoleLogo from "../../assets/images/adminRoleLogo.svg";
import profileBackgroundLogo from "../../assets/images/profileBackgroundLogo.svg";
import { useHistory } from "react-router-dom";
import { ProfileAction } from "../../store/actions";
import profileLogo from "../../../src/assets/images/profileLogo.svg";
import profileCorner from "../../../src/assets/images/profileCorner.svg";
import {
  Blip,
  SearchBar,
  HeaderComponent,
  TransactionTable,
  ButtonForm,
} from "../../components";
import { useSelector, useDispatch } from "react-redux";
const Profile = () => {
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(ProfileAction.ProfileRequest());
  }, []);

  const profile = useSelector((state) => state.ProfileReducer.profile);
  const changePasswordLoader = useSelector(
    (state) => state.ProfileReducer.changePasswordLoader
  );

  const { profile_image, email, username } = profile;
  return (
    <HeaderComponent>
      <Row
        gutter={[0, 10]}
        justify="space-between"
        style={{
          padding: isXxl ? "8rem 4.5rem 0rem 4.5rem" : "5rem 2rem 0rem 2rem",
        }}
      >
        <Col span={1} align="left">
          <Image src={profileCorner} />
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={14} lg={10} xl={10} xxl={8} align="left">
              <h1
                style={{
                  fontSize: isXxl ? "46px" : "35px",
                  color: "#415465",
                  fontWeight: "700",
                }}
              >
                Profile
              </h1>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            backgroundImage: `url(${profileBackgroundLogo})`,
            backgroundRepeat: "no-repeat",
            height: isXxl ? "70vh" : "80vh",
            backgroundPositionY: isXxl ? "50%" : "60%",
          }}
        >
          <Row gutter={[0, 10]} justify="center">
            <Col xs={24} sm={24} md={14} lg={12} xl={8} xxl={8}>
              <Row justify="center" gutter={[0, 20]}>
                <Col span={14}>
                  <Row justify="center" gutter={[0, 20]}>
                    <Col span={24}>
                      <Image
                        src={profile_image}
                        style={{
                          borderRadius: "50%",
                          width: isXxl ? "133px" : "120px",
                          height: isXxl ? "133px" : "120px",
                        }}
                      />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: isXxl ? "61px" : "35px",
                        color: "#E06030",
                        fontWeight: "600",
                        overflow: "hidden",
                      }}
                    >
                      {username}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: isXxl ? "25px" : "18px",
                        color: "#017D86",
                      }}
                    >
                      {email}
                    </Col>
                    <Col
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#009498",
                        fontSize: isXxl ? "22px" : "18px",
                      }}
                      onClick={() =>
                        history.push({
                          pathname: `/profile/edit-profile`,
                          state: profile,
                        })
                      }
                    >
                      Edit Profile
                    </Col>
                  </Row>
                </Col>

                <Col
                  span={24}
                  style={{
                    backgroundColor: "#E06030",
                    borderRadius: "9px",
                    height: "9px",
                  }}
                ></Col>
                <Col span={24}>
                  <Row justify="center" align="middle">
                    <Col xl={10} xxl={8}>
                      <Row justify="center" gutter={[10, 0]} align="middle">
                        <Col>
                          <Image src={adminRoleLogo} />
                        </Col>
                        <Col
                          style={{
                            color: "#8F8F8F",
                            fontSize: isXxl ? "22px" : "20px",
                          }}
                        >
                          Admin Role
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs={16}
                  sm={16}
                  md={14}
                  lg={14}
                  xl={14}
                  xxl={14}
                  style={{
                    background: "#009498",
                    borderRadius: "33px",
                    height: isXxl ? "55px" : "40px",
                    cursor: "pointer",
                  }}
                >
                  <ButtonForm
                    loading={changePasswordLoader}
                    style={{ backgroundColor: "#009498" }}
                    onClick={() =>
                      history.push({
                        pathname: `/profile/change-password`,
                      })
                    }
                  >
                    <Row gutter={[10, 0]} justify="center" align="middle">
                      <Col>
                        <Image src={changePasswordLogo} />
                      </Col>
                      <Col
                        style={{
                          fontSize: isXxl ? "22px" : "16px",
                          color: "#FFFFFF",
                          fontWeight: "500",
                        }}
                      >
                        Change Password
                      </Col>
                    </Row>
                  </ButtonForm>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </HeaderComponent>
  );
};

export default Profile;
