import { AuthAction } from "../actions";
import { ApiCaller } from "../../config";
import {
  LOGIN_ENDPOINT,
  FORGETPASSWORD_ENDPOINT,
  VERIFYCODE_ENDPOINT,
  RESETPASSWORD_ENDPOINT,
  CHANGEPASSWORD_ENDPOINT,
  LOGOUT_ENDPOINT,
} from "../../config/variables";
import Info from "../../components/message";
import { put } from "redux-saga/effects";
export default class AuthMiddleware {
  static *SignIn(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(LOGIN_ENDPOINT, payload);

      if (res.status == 200) {
        localStorage.setItem("eventauth", JSON.stringify(res.data.data));
        Info("success", res.data.message);
        if (cb) {
          cb(true);
        }
        yield put(AuthAction.SignInSuccess(res.data.data));
      } else {
        yield put(AuthAction.SignInFailure());
        Info("error", res.data.error);
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(AuthAction.SignInFailure());
    }
  }

  static *ForgetPassword(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(FORGETPASSWORD_ENDPOINT, payload);
      if (res.status == 200) {
        Info("success", res.data.data.message);
        if (cb) {
          cb(true, false);
        }
        yield put(AuthAction.ForgetPasswordSuccess(res.data.data));
      } else {
        yield put(AuthAction.ForgetPasswordFailure());
        Info("error", res.data.error.message);
        if (cb) {
          cb(false, true);
        }
      }
    } catch (err) {
      yield put(AuthAction.ForgetPasswordFailure());
    }
  }

  static *VerifyCode(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(VERIFYCODE_ENDPOINT, payload);
      if (res.status == 200) {
        Info("success", res.data.data.message);
        if (cb) {
          cb(true, false);
        }
        yield put(AuthAction.VerifyCodeSuccess(res.data.data));
      } else {
        yield put(AuthAction.VerifyCodeFailure());
        Info("error", res.data.error.message);
        if (cb) {
          cb(false, true);
        }
      }
    } catch (err) {
      yield put(AuthAction.VerifyCodeFailure());
    }
  }

  static *ResetPassword(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(RESETPASSWORD_ENDPOINT, payload);
      if (res.status == 200) {
        Info("success", res.data.data.message);
        if (cb) {
          cb(true, false);
        }
        yield put(AuthAction.ResetPasswordSuccess(res.data.data));
      } else {
        yield put(AuthAction.ResetPasswordFailure());
        Info("error", res.data.error.message);
        if (cb) {
          cb(false, true);
        }
      }
    } catch (err) {
      yield put(AuthAction.ResetPasswordFailure());
    }
  }

  static *ChangePassword(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(CHANGEPASSWORD_ENDPOINT, payload);
      if (res.status == 200) {
        Info("success", res.data.data.message);
        if (cb) {
          cb(true, false);
        }
        yield put(AuthAction.ChangePasswordSuccess(res.data.data));
      } else {
        yield put(AuthAction.ChangePasswordFailure());
        Info("error", res.data.error.message);
        if (cb) {
          cb(false, true);
        }
      }
    } catch (err) {
      yield put(AuthAction.ChangePasswordFailure());
    }
  }

  // static *Signout() {
  //   try {
  //     let res = yield ApiCaller.Post(LOGOUT_ENDPOINT);
  //     if (res.status == 200) {
  //       yield put(AuthAction.SignoutSuccess(res.data.data));
  //       Info("success", res.data.data);
  //       localStorage.removeItem("eventauth");
  //     } else {
  //       yield put(AuthAction.SignoutFailure());
  //       Info("error", res.data.error);
  //     }
  //   } catch (err) {
  //     yield put(AuthAction.SignoutFailure());
  //   }
  // }
}
