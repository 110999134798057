import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import loginBackground from "../../../src/assets/images/loginBackground.png";
import Image from "../../components/image";
import loginBackgroundArrow from "../../../src/assets/images/loginBackgroundArrow.svg";
import loginMessage from "../../../src/assets/images/loginMessage.svg";
import loginPassword from "../../../src/assets/images/loginPassword.svg";
import { Colors, Schemas } from "../../config";
import { LoginBox, ButtonForm } from "../../components";
import { Row, Col } from "antd";
import InputWrapper from "../../components/input";
import { AuthAction } from "../../store/actions";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";
import { FiEyeOff } from "react-icons/fi";
import { Formik, Form } from "formik";
import moment from "moment";
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
      emailError: "",
      isType: false,
      passwordError: "",
      error: "",
    };
  }

  onHandleSubmit = (obj) => {
    const { email, password, isType, current_time } = this.state;
    const { signin } = this.props;
    if (!email.trim().length || !password.trim().length) {
      if (!email.trim().length) {
        this.setState({ emailError: "Required fields can not be left empty" });
      }

      if (!password.trim().length) {
        this.setState({
          passwordError: "Required fields can not be left empty",
        });
      }
      return;
    } else {
      this.props.signin(
        { email, password, current_time, platform: "ios" },
        () => this.props.history.push("/")
      );
    }
  };
  handleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      emailError: "",
      passwordError: "",
    });
  };

  render() {
    const { signin, signInLoader, history } = this.props;
    const { email, password, current_time, isType } = this.state;
    const obj = { email, password, current_time };
    return (
      <Row
        style={{
          minHeight: "100vh",
          backgroundImage:
            "linear-gradient(#04737B, #023F43,#01767E,#01828B,#02484D,#023A3E)",
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={15}
          xxl={15}
          style={{
            backgroundImage:
              "linear-gradient(#04737B, #023F43,#01767E,#01828B,#02484D,#023A3E)",
          }}
        >
          <Row justify="center">
            <Col span={20}>
              <Image src={loginBackground} />
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={9}
          xxl={9}
          style={{
            borderRadius: "60px 0px 0px 60px",
            backgroundImage: `url(${loginBackgroundArrow})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Col xs={22} sm={22} md={20} lg={20} xl={18} xxl={16}>
              <Row gutter={[0, 50]} justify="center">
                <Col span={24}>
                  <h1 style={{ fontSize: "35px", fontWeight: "600" }}>
                    Login To Your Dashboard
                  </h1>
                </Col>
                <Col span={24}>
                  <InputWrapper
                    type="text"
                    placeholder="Email"
                    name="email"
                    isLogin
                    prefix={<Image src={loginMessage} />}
                    // style={{ backgroundColor: "#009498", opacity: "15%" }}
                    value={this.state.email}
                    onChange={this.handleText}
                  />
                  <label style={{ color: Colors.Red }}>
                    {this.state.emailError}
                  </label>
                </Col>
                <Col span={24}>
                  <InputWrapper
                    type={!isType ? "password" : "text"}
                    placeholder="Password"
                    name="password"
                    isLogin
                    prefix={<Image src={loginPassword} />}
                    value={this.state.password}
                    suffix={
                      !isType ? (
                        <FiEyeOff
                          onClick={() => {
                            this.setState({ isType: !isType });
                          }}
                          color="#E06030"
                        />
                      ) : (
                        <RiEyeLine
                          onClick={() => {
                            this.setState({ isType: !isType });
                          }}
                          color="#E06030"
                        />
                      )
                    }
                    onChange={this.handleText}
                  />
                  <label style={{ color: Colors.Red }}>
                    {this.state.passwordError}
                  </label>
                </Col>
                <Col span={13}>
                  <ButtonForm
                    loading={signInLoader}
                    htmlType="submit"
                    onClick={() => this.onHandleSubmit(obj)}
                  >
                    Sign In
                  </ButtonForm>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    signin: (payload, cb) => {
      dispatch(AuthAction.SignInRequest(payload, cb));
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({ signInLoader: storeState.AuthReducer.signInLoader }),
    (mapStateToProps, mapDispatchToProps)
  )(SignIn)
);
