import {
  GETEVENTS,
  GETEVENTS_SUCCESS,
  GETEVENTS_FAILURE,
  DELETEEVENTS,
  DELETEEVENTS_SUCCESS,
  DELETEEVENTS_FAILURE,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../../../store/constants";

export default class EventAction {
  static GetEvents(like, limit, offset) {
    return {
      type: GETEVENTS,
      like,
      limit,
      offset,
    };
  }
  static GetEventsSuccess(payload) {
    return {
      type: GETEVENTS_SUCCESS,
      payload,
    };
  }
  static GetEventsFailure() {
    return {
      type: GETEVENTS_FAILURE,
    };
  }
  static DeleteEvent(id, like, limit, offset, cb) {
    return {
      type: DELETEEVENTS,
      id,
      like,
      limit,
      offset,
      cb,
    };
  }

  static DeleteEventSuccess(payload) {
    return {
      type: DELETEEVENTS_SUCCESS,
      payload,
    };
  }
  static DeleteEventFailure() {
    return {
      type: DELETEEVENTS_FAILURE,
    };
  }
}
