import { UserAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  GET_USERS_ENDPOINT,
  UPDATE_USER_STATUS_ENDPOINT,
  USER_DETAILS_REQUEST_ENDPOINT,
  GETAPPDOWNLOADCOUNT_ENDPOINT,
  GETUSERTYPECOUNT_ENDPOINT,
  GETSUBSCRIBEDUSERCOUNT_ENDPOINT,
  GETEVENTSTATSCOUNT_ENDPOINT,
} from "../../../config/variables";
import { put } from "redux-saga/effects";
export default class UserMiddleware {
  static *GetUsers(action) {
    const { params } = action;
    const qureyParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(`${GET_USERS_ENDPOINT}?${qureyParams}`);

      if (res.status == 200) {
        yield put(
          UserAction.GetUsersSuccess(res.data.data, res.data.total_count)
        );
      } else {
        yield put(UserAction.GetUsersFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetUsersFailure());
    }
  }

  static *UserDetailsRequest(action) {
    const { params } = action;

    // const qureyParams = new URLSearchParams(params.type);
    const { userId, type } = params;
    try {
      let res = yield ApiCaller.Get(
        `${USER_DETAILS_REQUEST_ENDPOINT}/${userId}?type=${type}`
      );
      if (res.status == 200) {
        yield put(UserAction.UserDetailsSuccess(res.data.data));
      } else {
        yield put(UserAction.UserDetailsFailure());
        Info("error", res.data.data.message);
      }
    } catch (err) {
      yield put(UserAction.UserDetailsFailure());
    }
  }

  static *UpdateUserStatus(action) {
    const { params, cb } = action;

    const { limit, offset, type, user_id, current_time, status, is_cleared } = params;
    // const qureyParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Put(`${UPDATE_USER_STATUS_ENDPOINT}`, {
        type,
        user_id,
        current_time,
        is_cleared,
        status,
      });
      if (res.status == 200) {
        yield put(UserAction.UpdateUserStatusSuccess(res.data));
        Info("success", res.data.message);
        if (cb) {
          cb();
        }
      } else {
        yield put(UserAction.UpdateUserStatusFailure());
        Info("error", res.data.error);
      }
    } catch (err) {
      yield put(UserAction.UpdateUserStatusFailure());
    }
  }

  static *GetAppDownloadCount(payload) {
    const { startMonth, startYear, endMonth, endYear } = payload;
    try {
      let res = yield ApiCaller.Get(
        `${GETAPPDOWNLOADCOUNT_ENDPOINT}?startMonth=${startMonth}&endMonth=${endMonth}&startYear=${startYear}&endYear=${endYear}`
      );
      if (res.status == 200) {
        yield put(UserAction.GetAppDownloadCountSuccess(res.data.data));
      } else {
        yield put(UserAction.GetAppDownloadCountFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetAppDownloadCountFailure());
    }
  }

  static *GetUserTypeCount() {
    try {
      let res = yield ApiCaller.Get(GETUSERTYPECOUNT_ENDPOINT);
      if (res.status == 200) {
        yield put(UserAction.GetUserTypeCountSuccess(res.data.data));
      } else {
        yield put(UserAction.GetUserTypeCountFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetUserTypeCountFailure());
    }
  }

  static *GetSubscribedUserCount(payload) {
    const { startMonth, startYear, endMonth, endYear } = payload;
    try {
      let res = yield ApiCaller.Get(
        `${GETSUBSCRIBEDUSERCOUNT_ENDPOINT}/subscription-count?startMonth=${startMonth}&endMonth=${endMonth}&startYear=${startYear}&endYear=${endYear}`
      );
      if (res.status == 200) {
        yield put(UserAction.GetSubscribedUserCountSuccess(res.data.data));
      } else {
        yield put(UserAction.GetSubscribedUserCountFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetSubscribedUserCountFailure());
    }
  }

  static *GetEventStatsCount(payload) {
    const { startMonth, startYear, endMonth, endYear } = payload;
    try {
      let res = yield ApiCaller.Get(
        `${GETEVENTSTATSCOUNT_ENDPOINT}?startMonth=${startMonth}&endMonth=${endMonth}&startYear=${startYear}&endYear=${endYear}`
      );
      if (res.status == 200) {
        yield put(UserAction.GetEventStatsSuccess(res.data.data));
      } else {
        yield put(UserAction.GetEventStatsFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetEventStatsFailure());
    }
  }
}
