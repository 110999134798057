import { TopicManagementAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  TOPICS_REQUEST_ENDPOINT,
  UPDATE_TOPIC_REQUEST_ENDPOINT,
  ADD_TOPIC_ENDPOINT,
} from "../../../config/variables";
import { put } from "redux-saga/effects";
import moment from "moment";
export default class TopicsRequestMiddleware {
  static *TopicsRequest(action) {
    const { params, cb } = action;
    const qureyParams = new URLSearchParams(params);

    try {
      let res = yield ApiCaller.Get(
        `${TOPICS_REQUEST_ENDPOINT}?${qureyParams}`
      );

      if (res.status == 200) {
        yield put(
          TopicManagementAction.TopicsSuccess(res.data.data, res.data.count)
        );
      } else {
        yield put(TopicManagementAction.TopicsFailure());
      }
    } catch (err) {
      yield put(TopicManagementAction.TopicsFailure());
    }
  }
  static *UpdateTopicRequest(action) {
    let { params, cb } = action;

    const { id, ...rest } = params;
    try {
      let res = yield ApiCaller.Put(`${UPDATE_TOPIC_REQUEST_ENDPOINT}/${id}`, {
        ...rest,
      });

      if (res.status == 200) {
        yield put(TopicManagementAction.UpdateTopicSuccess(res.data.success));
        Info("success", res?.data?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error);
        yield put(TopicManagementAction.UpdateTopicFailure());
      }
    } catch (err) {
      yield put(TopicManagementAction.UpdateTopicFailure());
    }
  }

  static *AddTopicRequest(action) {
    let { body, cb } = action;

    try {
      let res = yield ApiCaller.Post(`${ADD_TOPIC_ENDPOINT}`, { ...body });
      if (res.status == 200) {
        yield put(TopicManagementAction.AddTopicSuccess(res.data.success));
        Info("success", res?.data?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res?.data?.error);
        yield put(TopicManagementAction.AddTopicFailure());
      }
    } catch (err) {
      yield put(TopicManagementAction.AddTopicFailure());
    }
  }
}
