// Dev URL
// export const API_BASE_URL ="https://wpost.5stardesigners.net/qa_api/public/api";

//UAT URL
// export const API_BASE_URL ="https://wpost.5stardesigners.net/uat_api/public/api";

export const API_BASE_URL = "https://api.swike.com/findur_api/public/api";

//AUTH ENDPOINTS
export const LOGIN_ENDPOINT = "/admin/login";
export const FORGETPASSWORD_ENDPOINT = "/auth/forget-password";
export const VERIFYCODE_ENDPOINT = "/auth/verify-code";
export const RESETPASSWORD_ENDPOINT = "/auth/change-password";
export const CHANGEPASSWORD_ENDPOINT = "/auth/update-password";
export const LOGOUT_ENDPOINT = "/admin/logout";

//APP ENDPOINTS
export const GETFORMS_ENDPOINT = "/csv/tests";
export const UPLOADFORM_ENDPOINT = "/csv/upload";
export const DELETEFORM_ENDPOINT = "/csv";
export const GETUSERSTATS_ENDPOINT = "/admin/user-stats";
export const GET_CONFIGURATION_AND_SETTINGS_ENDPOINT = "/";

export const GET_USERS_ENDPOINT = "/admin/user";
export const UPDATE_USER_STATUS_ENDPOINT = "/admin/update-user";

export const USER_DETAILS_REQUEST_ENDPOINT = "/admin/user-detail";

export const GETAPPDOWNLOADCOUNT_ENDPOINT = "/admin/app-download-count";
export const GETUSERTYPECOUNT_ENDPOINT = "/admin/user/user-types";
export const GETSUBSCRIBEDUSERCOUNT_ENDPOINT = "/admin/subscription";
export const GETEVENTSTATSCOUNT_ENDPOINT = "/admin/event/event-created-count";
export const GETEVENTS_ENDPOINT = "/admin/events";
export const DELETEEVENTS_ENDPOINT = "/event";
export const GETTRANSACTIONS_ENDPOINT = "/admin/transactions";
export const CONFIG_AND_SETTINGS_REQUEST_ENDPOINT = "/admin/get-config";
export const UPDATE_ADMIN_SETTINGS_ENDPOINT = "/admin/config";
export const DASHBOARD_REQUEST_ENDPOINT = "/admin/live-report";
export const NON_LIVE_REPORTS_REQUEST_ENDPOINT = "/admin/non-live-report";
export const COUNTRIES_REQUEST_ENDPOINT = "/users/countries";
export const LOCATIONS_REQUEST_ENDPOINT = "/admin/location";
export const UPDATE_LOCATION_REQUEST_ENDPOINT = "/admin/toggle-loc";
export const CONTENTS_REQUEST_ENDPOINT = "/admin/cms";
export const UPDATE_CONTENT_REQUEST_ENDPOINT = "/admin/toggle-post";
export const TOPICS_REQUEST_ENDPOINT = "/admin/topics";
export const UPDATE_TOPIC_REQUEST_ENDPOINT = "/admin/topics";
export const ADD_TOPIC_ENDPOINT = "/admin/topics";

export const PROFILE_REQUEST_ENDPOINT = "/admin/profile";
export const ADD_PROFILE_ENDPOINT = "/admin/profile";
export const CHANGE_PASSWORD_ENDPOINT = "/admin/password";
export const SUSPICIOUS_ACTIVITY_ENDPOINT = "/admin/suspicious-activities";

export const CUSTOM_ADS_REQUEST_ENDPOINT = "/admin/custom-ads";
export const ADD_CUSTOM_AD_ENDPOINT = "/admin/custom-ads";
export const UPDATE_CUSTOM_AD_ENDPOINT = "/admin/update-custom-ads";
export const DELETE_CUSTOM_AD_ENDPOINT = "/admin/custom-ads";
export const TOGGLE_AD_ENDPOINT = "/admin/custom-ads";

export const POST_DETAILS_ENDPOINT = "/admin/post-details";
export const UPDATE_POST_ENDPOINT = "/admin/toggle-post";

export const GET_INTERACTIONS_DATA_ENDPOINT = "/admin/interactions"

const configVariables = {
  // appUrl,
  // appUrlForReview,
  //baseUrl: `${baseUrl}`,
  termsUrl: "",
  privacyUrl: "",
  contactSupportMail: "",
  //instructionsUrl: `${baseUrl}`,
};

const constantVariables = {
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  messageForAppShare: "",
  subjectForAppShare: "",
};

export default {
  ...configVariables,
  ...constantVariables,
};

export const SUSPICIOUS_FAV =
  "Suspicious ‘favourites’ – where a very low scoring post is selected over a very high scoring one";
export const SUSPICIOUS_REP =
  "Suspicious repletion of a favourite – may suggest that it is being like to ‘inflate’ the score /climb the rankings";

export const MAX_REPORTED =
  "Accounts that appear to be posting a lot of content that gets reported";
export const SUSPICIOUS_TOPIC =
  "issue- if any topic is not getting enough new content / posts - if < 500 it could impact the rankings";

export const GENDER_OPTIONS = [
  { value: "", label: "All" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "non-binary", label: "Non-binary" },
  { value: "prefer not to say", label: "Prefer Not Say" },
];

export const AD_GENDER_OPTIONS = [
  { value: "male", label: "male" },
  { value: "female", label: "female" },
  { value: "non-binary", label: "non-binary" },
  { value: "prefer not to say", label: "prefer not to say" },
];

export const AGE_OPTIONS = [
  { value: "", label: "All" },
  { value: "18", label: "<18" },
  { value: "19-23", label: "19-23" },
  { value: "24-30", label: "24-30" },
  { value: "31-40", label: "30-41" },
  { value: "41-50", label: "41-50" },
  { value: "50", label: ">50" },
];

export const POST_IN_TOPIC_COLORS = {
  active: "#F5BA3C",
  reported: "#009498",
  removed: "#E06030",
  suspended: "#3EB495",
};
export const POST_BY_TOPIC_COLORS = [
  "#009498",
  "#F5BA3C",
  "#E06030",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

export const VISITS_COLORS = {
  ["a 0"]: "#8F8F8F",
  "b <10": "#F5BA3C",
  "c 10-49": "#009498",
  "d 50-99": "#E06030",
  "e 100+": "blue",
};

export const POST_SCORE_COLOR = {
  "e 91-100": "blue",
  "d 81-90": "#E06030",
  "c 71-80": "#009498",
  "b 51-70": "#F5BA3C",
  "a <50": "#8F8F8F",
};

export const REPORTED_POSTS_COLOR = {
  suspended: "#F5BA3C",
  removed: "#009498",
  released: "#E06030",
};

export const REPORTS_COLOR = {
  removed: "#F5BA3C",
  reported: "#009498",
  suspended: "#E06030",
};

export const NO_OF_USERS_COLOR = {
  active_user: "#009498",
  inactive_user: "#E06030",
};

export const NO_OF_DOWNLOADS_COLOR = {
  ios: "#E06030",
  android: "#019398",
};
