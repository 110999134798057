import {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  LOADER_FALSE,
  LOADER_TRUE,
  FORGETPASSWORD,
  FORGETPASSWORD_SUCCESS,
  FORGETPASSWORD_FAILURE,
  RESETPASSWORD,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_FAILURE,
  VERIFYCODE,
  VERIFYCODE_SUCCESS,
  VERIFYCODE_FAILURE,
  CHANGEPASSWORD,
  CHANGEPASSWORD_SUCCESS,
  CHANGEPASSWORD_FAILURE,
  SIGNOUT,
  SIGNOUT_FAILURE,
  SIGNOUT_SUCCESS,
} from "../constants";

export default class AuthAction {
  static ForgetPassword(payload, cb) {
    return {
      type: FORGETPASSWORD,
      payload,
      cb,
    };
  }
  static ForgetPasswordSuccess(payload) {
    return {
      type: FORGETPASSWORD_SUCCESS,
      payload,
    };
  }
  static ForgetPasswordFailure() {
    return {
      type: FORGETPASSWORD_FAILURE,
    };
  }

  static ResetPassword(payload, cb) {
    return {
      type: RESETPASSWORD,
      payload,
      cb,
    };
  }
  static ResetPasswordSuccess(payload) {
    return {
      type: RESETPASSWORD_SUCCESS,
      payload,
    };
  }
  static ResetPasswordFailure() {
    return {
      type: RESETPASSWORD_FAILURE,
    };
  }

  static VerifyCode(payload, cb) {
    return {
      type: VERIFYCODE,
      payload,
      cb,
    };
  }
  static VerifyCodeSuccess(payload) {
    return {
      type: VERIFYCODE_SUCCESS,
      payload,
    };
  }
  static VerifyCodeFailure() {
    return {
      type: VERIFYCODE_FAILURE,
    };
  }

  static SignInRequest(payload, cb) {
    return {
      type: SIGNIN_REQUEST,
      payload,
      cb,
    };
  }
  static SignInSuccess(payload) {
    return {
      type: SIGNIN_SUCCESS,
      payload,
    };
  }
  static SignInFailure() {
    return {
      type: SIGNIN_FAILURE,
    };
  }

  static ChangePassword(payload, cb) {
    return {
      type: CHANGEPASSWORD,
      payload,
      cb,
    };
  }
  static ChangePasswordSuccess(payload) {
    return {
      type: CHANGEPASSWORD_SUCCESS,
      payload,
    };
  }
  static ChangePasswordFailure() {
    return {
      type: CHANGEPASSWORD_FAILURE,
    };
  }

  static LoaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }

  static LoaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
}
