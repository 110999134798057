import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  ADD_PROFILE_REQUEST,
  ADD_PROFILE_SUCCESS,
  ADD_PROFILE_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "../../constants";

const initialState = {
  profile: {},
  getProfileLoader: false,
  addProfileLoader: false,
  changePasswordLoader: false,
};

export default function ProfileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_REQUEST:
      state = {
        ...state,
        getProfileLoader: true,
      };
      break;

    case PROFILE_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          profile: action.payload,
          getProfileLoader: false,
        };
      }
    }

    case PROFILE_FAILURE:
      state = {
        ...state,
        getProfileLoader: false,
      };
      break;

    case ADD_PROFILE_REQUEST:
      state = {
        ...state,
        addProfileLoader: true,
      };
      break;

    case ADD_PROFILE_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          addProfileLoader: false,
        };
      }
      break;

    case ADD_PROFILE_FAILURE:
      state = {
        ...state,
        addProfileLoader: false,
      };
      break;

    case CHANGE_PASSWORD_REQUEST:
      state = {
        ...state,
        changePasswordLoader: true,
      };
      break;

    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        changePasswordLoader: false,
      };

      break;

    case CHANGE_PASSWORD_FAILURE:
      state = {
        ...state,
        changePasswordLoader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
