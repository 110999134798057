import React, { useEffect, useState } from "react";
import { Row, Col, Table, Switch, Modal } from "antd";
import { StatusBar } from "../../components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SwitchWrapper from "../../components/switch";
import TopicManagementAction from "../../store/actions/appactions/TopicManagementAction";
import { ButtonForm } from "../../components";
import { useMedia } from "use-media";
import { connect } from "react-redux";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { UserAction } from "../../store/actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const TopicManagementTable = (props) => {
  const { locationData, offset, typee, like, userStatus } = props;
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();
  const updateUserLoader = useSelector(
    (state) => state.UserReducer.updateUserLoader
  );
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "List of Topics",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <p
          style={{
            fontWeight: "bold",
            fontSize: isXxl ? "20px" : "16px",
            color: "#000000D8",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Age Limit",
      dataIndex: ["id", "is_age_limit"],
      key: "is_age_limit",
      render: (e, record) => (
        <Row gutter={[10, 10]} align="middle" justify="center">
          <Col>
            <SwitchWrapper
              loading={updateUserLoader}
              checked={record.is_age_limit == "true" ? true : false}
              onChange={(e) => {
                dispatch(
                  TopicManagementAction.UpdateTopicRequest(
                    {
                      id: record.id,
                      is_age_limit:
                        record.is_age_limit == "true" ? "false" : "true",
                      status:
                        record.status == "Active" ? "active" : "in_active",
                      current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
                    },
                    () => {
                      dispatch(
                        TopicManagementAction.TopicsRequest({
                          limit: 10,
                          offset: offset,
                        })
                      );
                    }
                  )
                );
              }}
            />
          </Col>
          <Col
            style={{
              color: "black",
              fontSize: isXxl ? "18px" : "14px",
              fontWeight: "600",
            }}
            align="left"
          >
            18+
          </Col>
        </Row>
      ),
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (e, record) => (
        <Row gutter={[10, 10]} align="middle" justify="center">
          <Col>
            <SwitchWrapper
              loading={updateUserLoader}
              checked={record.status == "Active" ? true : false}
              onChange={(e) => {
                dispatch(
                  TopicManagementAction.UpdateTopicRequest(
                    {
                      id: record.id,
                      is_age_limit:
                        record.is_age_limit == "true" ? "true" : "false",
                      status:
                        record.status == "Active" ? "in_active" : "active",
                      current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
                    },
                    () => {
                      dispatch(
                        TopicManagementAction.TopicsRequest({
                          limit: 10,
                          offset: offset,
                        })
                      );
                    }
                  )
                );
              }}
            />
          </Col>
          <Col
            style={{
              color: "black",
              fontSize: isXxl ? "18px" : "14px",
              fontWeight: "600",
            }}
            align="left"
          >
            {record.status == "Active" ? "Enable" : "Disable"}
          </Col>
        </Row>
      ),
    },
  ];
  const [columnsNew, setColumnsNew] = useState(columns);
  useEffect(() => {
    setColumnsNew(columns);
  }, [offset]);
  const history = useHistory(props);
  const { userData, userTableLoader } = props;
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
      <Table
        style={{ cursor: "pointer" }}
        columns={columnsNew}
        pagination={false}
        dataSource={locationData}
        // loading={{ spinning: userTableLoader, indicator: antIcon }}
      />
    </Col>
  );
};

export default TopicManagementTable;
