import {
  LOCATIONS_REQUEST,
  LOCATIONS_SUCCESS,
  LOCATIONS_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
} from "../../../store/constants";

export default class LocationManagementAction {
  static LocationsRequest(params) {
    return {
      type: LOCATIONS_REQUEST,
      params,
    };
  }
  static LocationsSuccess(payload, count) {
    return {
      type: LOCATIONS_SUCCESS,
      payload,
      count,
    };
  }
  static LocationsFailure() {
    return {
      type: LOCATIONS_FAILURE,
    };
  }

  static UpdateLocationRequest(params, cb) {
    return {
      type: UPDATE_LOCATION_REQUEST,
      params,
      cb,
    };
  }
  static UpdateLocationSuccess(payload) {
    return {
      type: UPDATE_LOCATION_SUCCESS,
      payload,
    };
  }
  static UpdateLocationFailure() {
    return {
      type: UPDATE_LOCATION_FAILURE,
    };
  }
}
