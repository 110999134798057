import React from "react";
import { connect } from "react-redux";
import { Row, Col, Tabs, Spin } from "antd";
import { withRouter } from "react-router-dom";
import dashboardLogo from "../../../src/assets/images/dashboardLogo.svg";
import RangePicker from "../../components//datepickers/RangePicker";
import { ButtonForm } from "../../components";
import "./styles.css";
import { useMedia } from "use-media";
import { Images } from "../../config";
import Select from "react-select";
import moment from "moment";
import { HeaderComponent, UserCard, ChartBox } from "../../components";
import Image from "../../components/image/index";
import LiveReports from "./LiveReports";
import NonLiveReports from "./NonLiveReports";
import ActivitySpotter from "./ActivitySpotter";
import ActivityTable from "./SuspiciousFavorite";
import {
  UserAction,
  ConfigAndSettingAction,
  DashboardAction,
} from "../../store/actions";

import "../../index.css";
import SuspiciousTopic from "./SuspiciousTopic";
import SuspiciousFavourite from "./SuspiciousFavorite";
import SuspiciousRepletion from "./SuspiciousRepletion";
import MaxReportedContent from "./MaxReportedContent";
import {
  GENDER_OPTIONS,
  AGE_OPTIONS,
  MAX_REPORTED,
  SUSPICIOUS_FAV,
  SUSPICIOUS_REP,
  SUSPICIOUS_TOPIC,
} from "../../config/variables";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        country: [],
        gender: "",
        age: "",
        start_date: "",
        end_date: "",
      },

      liveReports: true,
      nonLiveReports: false,
      activitySpotter: false,
    };
  }

  componentDidUpdate(preProps, preState) {
    const { gender, age, start_date, end_date } = this.state.filter;
    if (
      preState.filter.gender !== gender ||
      preState.filter.age !== age ||
      preState.filter.start_date !== start_date ||
      preState.filter.end_date !== end_date
    ) {
      const {
        nonLiveReportsRequest,
        dashboardstatsRequest,
        getInteractionData,
      } = this.props;
      const { nonLiveReports, filter } = this.state;
      nonLiveReports
        ? nonLiveReportsRequest(filter)
        : dashboardstatsRequest(filter);
      getInteractionData(filter);
    }
  }
  componentDidMount() {
    const {
      dashboardstatsRequest,
      nonLiveReportsRequest,
      suspiciousActivityRequest,
      countriesRequest,
      getInteractionData,
    } = this.props;
    console.log("DidMount_props", this.props);
    dashboardstatsRequest();
    nonLiveReportsRequest();
    suspiciousActivityRequest();
    countriesRequest();
    getInteractionData(this.state.filter);
  }
  componentWillUnmount = () => {
    const { cleardashboard } = this.props;
    cleardashboard();
  };

  handlePanel = (e) => {
    let start_date = e ? moment(e[0])?.format("YYYY-MM-DD") : "";
    let end_date = e ? moment(e[1])?.format("YYYY-MM-DD") : "";
    this.setState({ filter: { ...this.state.filter, start_date, end_date } });
  };

  render() {
    const { TabPane } = Tabs;
    const { countries } = this.props;
    var countriesOption =
      countries &&
      countries.map((item) => {
        return { value: item.id, label: item.name };
      });

    const customStyles = {
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: "red !important",
        };
      },
    };
    const {
      noOfPostByTopic,
      noOfPostInTopic,
      noOfReportedPost,
      reports,
      topics,
      postSelected,
      live_reported_hidden,
      user_monthly_visits,
      country,
      ios_downloads,
      android_downloads,
      active_users,
      no_of_users,
      no_of_downloads,
      inactive_users,
      reportsData,
      post_score,
      interactions,
      isXs,
      isSm,
      isMd,
      isLg,
      isXl,
      isXxl,
      max_reported_content,
      suspicious_favourites,
      suspicious_repletion,
      suspicious_topic,
      countriesRequest,
      dashboardstatsRequest,
      nonLiveReportsRequest,
      NonLiveReportsLoader,
      InteractionData,
      InteractionLoader,
      colorVisiblity
    } = this.props;
    const { liveReports, nonLiveReports, activitySpotter } = this.state;
    console.log("This_props", this.props);

    return (
      <HeaderComponent>
        <Row
          gutter={[0, 20]}
          justify="space-between"
          style={{
            padding: isXxl ? "8rem 4.5rem 0rem 4.5rem" : "5rem 2rem 0rem 2rem",
          }}
        >
          <Col span={1} align="left">
            <Image src={dashboardLogo} />
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col xs={24} sm={24} md={10} lg={7} xl={5} xxl={4} align="left">
                <h1
                  style={{
                    fontSize: isXxl ? "46px" : "35px",
                    color: "#415465",
                    fontWeight: "700",
                  }}
                >
                  Dashboard
                </h1>
              </Col>
              {activitySpotter ? null : (
                <Col xs={24} sm={24} md={24} lg={16} xl={18} xxl={17}>
                  <Row justify="space-between" gutter={[10, 20]}>
                    <Col xs={24} sm={24} md={11} lg={11} xl={6} xxl={6}>
                      <Select
                        options={countriesOption}
                        style={customStyles}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        isLoading={this.props.countriesLoader}
                        placeholder={
                          <div
                            style={{ display: "flex", justifyContent: "left" }}
                          >
                            Country
                          </div>
                        }
                        defaultValue={[]}
                        isMulti
                        onKeyDown={(e) => countriesRequest()}
                        onChange={(e) => {
                          let countryData = [...this.state.filter.country];
                          const countryToShow = e.map((item) => {
                            return item.value;
                          });
                          countryData = countryToShow;

                          // countryData.push(e[e.length - 1]?.label);

                          this.setState({
                            filter: {
                              ...this.state.filter,
                              country: countryData,
                            },
                          });
                          !nonLiveReports
                            ? dashboardstatsRequest({
                                ...this.state.filter,
                                country: countryData,
                              })
                            : nonLiveReportsRequest({
                                ...this.state.filter,
                                country: countryData,
                              });
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={6} xxl={6}>
                      <Select
                        options={GENDER_OPTIONS}
                        style={customStyles}
                        placeholder={
                          <div
                            style={{ display: "flex", justifyContent: "left" }}
                          >
                            Gender
                          </div>
                        }
                        isSearchable={false}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          this.setState({
                            filter: { ...this.state.filter, gender: e.value },
                          });
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={6} xxl={6}>
                      <Select
                        options={AGE_OPTIONS}
                        style={customStyles}
                        isSearchable={false}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        placeholder={
                          <div
                            style={{ display: "flex", justifyContent: "left" }}
                          >
                            Age
                          </div>
                        }
                        onChange={(e) => {
                          this.setState({
                            filter: { ...this.state.filter, age: e.value },
                          });
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={6} xxl={6}>
                      <RangePicker
                        format={"DD-MM-YYYY"}
                        allowClear={true}
                        onChange={(e) => this.handlePanel(e)}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={24} style={{ width: "100%" }}>
            <Row gutter={[10, 0]}>
              <Col xs={24} sm={24} md={18} lg={14} xl={10} xxl={10}>
                <Row gutter={[5, 10]}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <ButtonForm
                      onClick={() =>
                        this.setState({
                          liveReports: true,
                          nonLiveReports: false,
                          activitySpotter: false,
                        })
                      }
                      style={{
                        backgroundColor: this.state.liveReports
                          ? "#E06030"
                          : "#F1F1F1",
                        color: this.state.liveReports ? "#FFFFFF" : "#3A3A3A",
                      }}
                      isTable
                      span={12}
                    >
                      Live Reports
                    </ButtonForm>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <ButtonForm
                      onClick={() =>
                        this.setState({
                          liveReports: false,
                          nonLiveReports: true,
                          activitySpotter: false,
                        })
                      }
                      style={{
                        backgroundColor: this.state.nonLiveReports
                          ? "#E06030"
                          : "#F1F1F1",
                        color: this.state.nonLiveReports
                          ? "#FFFFFF"
                          : "#3A3A3A",
                      }}
                      isTable
                      span={12}
                    >
                      Non-Live Reports
                    </ButtonForm>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <ButtonForm
                      onClick={() =>
                        this.setState({
                          liveReports: false,
                          nonLiveReports: false,
                          activitySpotter: true,
                        })
                      }
                      style={{
                        backgroundColor: this.state.activitySpotter
                          ? "#E06030"
                          : "#F1F1F1",
                        color: this.state.activitySpotter
                          ? "#FFFFFF"
                          : "#3A3A3A",
                      }}
                      isTable
                      span={12}
                    >
                      Activity Spotter
                    </ButtonForm>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginTop: "1.5rem" }}>
            {liveReports ? (
              <LiveReports
                data={live_reported_hidden}
                ios_downloads={ios_downloads}
                android_downloads={android_downloads}
                active_users={active_users}
                inactive_users={inactive_users}
              />
            ) : nonLiveReports ? (
              <NonLiveReports
                data={live_reported_hidden}
                ios_downloads={ios_downloads}
                android_downloads={android_downloads}
                active_users={active_users}
                inactive_users={inactive_users}
              />
            ) : (
              <ActivitySpotter
                data={live_reported_hidden}
                ios_downloads={ios_downloads}
                android_downloads={android_downloads}
                active_users={active_users}
                inactive_users={inactive_users}
              />
            )}
          </Col>
          {liveReports ? (
            <>
              <Col span={24} style={{ marginTop: "2rem" }}>
                <Row
                  gutter={isXxl ? [70, 20] : [50, 20]}
                  justify="space-between"
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <ChartBox
                      heading="No of Users"
                      usersColor1="#E06030"
                      usersData={no_of_users}
                      isTwo
                      usersColor2="#019398"
                      isLine
                      isNoOfUsers
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <ChartBox
                      heading="No. of User Sign-Ups"
                      isNoDate
                      isTwo
                      downloadsColor1="#E06030"
                      downloadsColor2="#019398"
                      isNoOfDownloads
                      barData={no_of_downloads}
                      isBar
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ marginTop: "2rem" }}>
                <Row gutter={isXxl ? [70, 20] : [50, 20]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <ChartBox
                      heading="No of Post Uploaded By Topic"
                      isEvent={true}
                      barData={noOfPostByTopic}
                      isByTopic
                      isNoDate
                      legends={this.props.entity_name}
                      isBar
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <ChartBox
                      heading="No of Post In Topic"
                      isSubscription={true}
                      barData={noOfPostInTopic}
                      isPostInTopic
                      isNoDate
                      isBar
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ marginTop: "2rem" }}>
                <Row gutter={isXxl ? [70, 20] : [50, 20]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <ChartBox
                      heading="Reports"
                      isEvent={true}
                      isReports
                      usersData={reports}
                      isNoDate
                      isLine
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <ChartBox
                      heading="Suspended Posts"
                      isSubscription={true}
                      barData={noOfReportedPost}
                      isNoOfReportedPost
                      isNoDate
                      isBar
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ marginTop: "2rem" }}>
                <Row gutter={isXxl ? [70, 20] : [50, 20]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <ChartBox
                      heading="Topics"
                      isEvent={true}
                      pieData={topics}
                      isTopics
                      noLegend
                      isNoDate
                      isPie
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <ChartBox
                      heading="Country of Posts"
                      isSubscription={true}
                      pieData={country}
                      isCountry
                      isNoDate
                      noLegend
                      isPie
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ marginTop: "2rem" }}>
                <Row gutter={isXxl ? [70, 20] : [50, 20]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <ChartBox
                      heading="Post Selected"
                      isPie
                      islast
                      noLegend
                      isPostSelected
                      isEvent={true}
                      pieData={postSelected}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}></Col>
                </Row>
              </Col>
            </>
          ) : nonLiveReports ? (
            <>
              <Col
                span={24}
                style={{ padding: 8, marginLeft: 10, marginTop: "2rem" }}
              >
                <Row gutter={[20, 20]} justify="space-around">
                  <Col span={24}>
                    {InteractionLoader ? (
                      <Col>
                        <h1>Loading Interactions Chart.</h1>
                        <Spin />
                      </Col>
                    ) : (
                      <ChartBox
                        heading="Interactions"
                        usersData={interactions}
                        isInteractions
                        STATIC_COLORS={[
                          "#8F8F8F",
                          "#F5BA3C",
                          "#009498",
                          "#E06030",
                        ]}
                        isLine
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ padding: 8, marginLeft: 10 }}>
                <Row gutter={[20, 0]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    {NonLiveReportsLoader ? (
                      <Col>
                        <h1>Loading User Visits Chart.</h1>
                        <Spin />
                      </Col>
                    ) : (
                      <ChartBox
                        heading="User Visits"
                        isEvent={true}
                        isUserVisitsMonthly
                        sortIt
                        toolTipReverse
                        barData={user_monthly_visits}
                        isByTopic
                        isNoDate
                        legends={this.props.entity_name}
                        isBar
                      />
                    )
                  }
                  {console.log('this.props.user_monthly_visits', user_monthly_visits)}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    {NonLiveReportsLoader ? (
                      <Col>
                        <h1>Loading Post Score Chart.</h1>
                        <Spin />
                      </Col>
                    ) : (
                      <ChartBox
                        heading="Post Score"
                        isPostScore
                        toolTipReverse
                        barData={post_score}
                        isTwo
                        STATIC_COLORS={[
                          "#8F8F8F",
                          "#F5BA3C",
                          "#009498",
                          "#E06030",
                          "blue",
                        ]}
                        isLine
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Row>
              <Col span={24} align="left">
                <h1>Unusual / Suspicious Activity Spotter</h1>
              </Col>
              <Col span={24} align="left">
                <p>
                  MI to spot trends and activities that require investigation
                </p>
              </Col>
              <Col span={24}>
                <Row gutter={[40, 40]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <SuspiciousFavourite
                      data={suspicious_favourites}
                      color="#009498"
                      paragraph={SUSPICIOUS_FAV}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <SuspiciousRepletion
                      data={suspicious_repletion}
                      color="#E06030"
                      paragraph={SUSPICIOUS_REP}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <MaxReportedContent
                      data={max_reported_content}
                      color="#F56258"
                      paragraph={MAX_REPORTED}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <SuspiciousTopic
                      data={suspicious_topic}
                      color="#8F8F8F"
                      paragraph={SUSPICIOUS_TOPIC}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Row>
      </HeaderComponent>
    );
  }
}

const MediaWrapper = (props) => {
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <Dashboard
      isXs={isXs}
      isSm={isSm}
      isMd={isMd}
      isLg={isLg}
      isXl={isXl}
      isXxl={isXxl}
      {...props}
    />
  );
};
function mapDispatchToProps(dispatch) {
  return {
    dashboardstatsRequest: (params) => {
      dispatch(DashboardAction.DashboardRequest(params));
    },

    nonLiveReportsRequest: (params) => {
      dispatch(DashboardAction.NonLiveReportsRequest(params));
    },

    countriesRequest: (params) => {
      dispatch(DashboardAction.CountriesRequest());
    },

    suspiciousActivityRequest: () => {
      dispatch(DashboardAction.SuspiciousActivityRequest());
    },

    cleardashboard: () => {
      dispatch(UserAction.ClearDashboard());
    },

    getInteractionData: (payload) => {
      dispatch(DashboardAction.GetInteractionData(payload));
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({
      dashboardStats: storeState?.DashboardReducer?.dashboardStats,
      countries: storeState?.DashboardReducer?.countries,
      noOfPostByTopic: storeState?.DashboardReducer?.no_of_post_by_topic,
      noOfPostInTopic: storeState?.DashboardReducer?.no_of_post_in_topic,
      reports: storeState?.DashboardReducer?.reports,
      noOfReportedPost: storeState?.DashboardReducer?.no_of_reported_posts,
      postSelected: storeState?.DashboardReducer?.post_selected,
      country: storeState?.DashboardReducer?.country,
      topics: storeState?.DashboardReducer?.topics,
      live_reported_hidden: storeState?.DashboardReducer?.live_reported_hidden,
      ios_downloads: storeState?.DashboardReducer?.ios_downloads,
      android_downloads: storeState?.DashboardReducer?.android_downloads,
      active_users: storeState?.DashboardReducer?.active_users,
      inactive_users: storeState?.DashboardReducer?.inactive_users,
      no_of_users: storeState?.DashboardReducer?.no_of_users,
      no_of_downloads: storeState?.DashboardReducer?.no_of_downloads,
      countries_loader: storeState?.DashboardReducer?.countriesLoader,
      entity_name: storeState?.DashboardReducer?.entity_name,
      post_score: storeState?.DashboardReducer?.post_score,
      interactions: storeState?.DashboardReducer?.interactions,
      user_monthly_visits: storeState?.DashboardReducer?.user_visits_monthly,
      max_reported_content: storeState?.DashboardReducer?.max_reported_content,
      suspicious_favourites:
        storeState?.DashboardReducer?.suspicious_favourites,
      suspicious_repletion: storeState?.DashboardReducer?.suspicious_repletion,
      suspicious_topic: storeState?.DashboardReducer?.suspicious_topic,
      NonLiveReportsLoader: storeState?.DashboardReducer?.NonLiveReportsLoader,
      InteractionData: storeState?.DashboardReducer?.interactions,
      InteractionLoader: storeState?.DashboardReducer?.interactionsLoader,
      colorVisiblity:storeState?.DashboardReducer?.colorVisiblity
    }),
    mapDispatchToProps
  )(MediaWrapper)
);
