import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { Colors } from "../../config";
import { useSelector } from "react-redux";

const StyledButton = styled(Button)`
  background-color: ${(props) =>
    props.isDetails ? "none" : `${Colors.Primary}`};
  color: ${Colors.White};
  cursor: pointer;
  border: none;
  border-radius: ${(props) =>
    props.isTable || props.isAdModal
      ? "5px"
      : props.isDetails
      ? "0px"
      : "24px"};
  height: ${(props) =>
    props.isTable ? "50px" : props.isRegistration ? "40px" : "56px"};
  width: 100%;
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) =>
      props.isDetails ? "none" : `${Colors.Primary}`};
    color: ${Colors.White};
  }
`;

const ButtonForm = (props) => {
  const updateUserLoader = useSelector(
    (state) => state.UserReducer.updateUserLoader
  );

  return <StyledButton {...props} loading={props.loading}></StyledButton>;
};

export default ButtonForm;
