import {
  TOPICS_REQUEST,
  TOPICS_SUCCESS,
  TOPICS_FAILURE,
  UPDATE_TOPIC_REQUEST,
  UPDATE_TOPIC_SUCCESS,
  UPDATE_TOPIC_FAILURE,
  ADD_TOPIC_REQUEST,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_FAILURE,
} from "../../constants";

const initialState = {
  topics: [],
  getTopicsLoader: false,
  topicsCount: 0,
  updateTopicLoader: false,
  addTopicLoader: false,
};

export default function TopicManagementReducer(state = initialState, action) {
  switch (action.type) {
    case TOPICS_REQUEST:
      state = {
        ...state,
        getTopicsLoader: true,
      };
      break;

    case TOPICS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          topics: action ? action?.payload : [],
          topicsCount: action.count,
          getTopicsLoader: false,
        };
      }
    }

    case TOPICS_FAILURE:
      state = {
        ...state,
        getTopicsLoader: false,
      };
      break;

    case UPDATE_TOPIC_REQUEST:
      state = {
        ...state,
        updateTopicLoader: true,
      };
      break;

    case UPDATE_TOPIC_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          updateTopicLoader: false,
        };
      }
      break;

    case UPDATE_TOPIC_FAILURE:
      state = {
        ...state,
        updateTopicLoader: false,
      };
      break;

    case ADD_TOPIC_REQUEST:
      state = {
        ...state,
        addTopicLoader: true,
      };
      break;

    case ADD_TOPIC_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          addTopicLoader: false,
        };
      }
      break;

    case ADD_TOPIC_FAILURE:
      state = {
        ...state,
        addTopicLoader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
