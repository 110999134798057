import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useMedia } from "use-media";
import Info from "../../components/message";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Image from "../../components/image/index";
import uploadPhoto from "../../assets/images/uploadPhoto.svg";
import changePasswordLogo from "../../assets/images/changePasswordLogo.svg";
import editProfileEmpty from "../../assets/images/editProfileEmpty.svg";
import profileCorner from "../../../src/assets/images/profileCorner.svg";
import {
  Blip,
  SearchBar,
  HeaderComponent,
  TransactionTable,
  ButtonForm,
} from "../../components";
import adminRoleLogo from "../../assets/images/adminRoleLogo.svg";
import profileBackgroundLogo from "../../assets/images/profileBackgroundLogo.svg";
import { ProfileAction } from "../../store/actions";
import profileLogo from "../../../src/assets/images/profileLogo.svg";
import InputWrapper from "../../components/input";
import moment from "moment";

const EditProfile = (props) => {
  const { profile_image, username } = props?.location?.state;

  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  const dispatch = useDispatch();
  const addProfileLoader = useSelector(
    (state) => state.ProfileReducer.addProfileLoader
  );
  const history = useHistory();
  const [values, setValues] = useState({
    username: username,
    profile_image: "",
  });
  const [errors, setErrors] = useState({
    userNameError: "",
    profileImageError: "",
  });
  const [isUpdateImg, setUpdateImg] = useState(false);
  const [img, setImg] = useState("");
  function handleText(val) {
    setValues({ ...values, username: val.target.value });
    setErrors("");
  }
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setUpdateImg(true);
      setImg(reader.result);
    };
    reader.onerror = function (error) {};
  }

  function handleImage(val) {
    const fileSize = val.target.files[0].size / 1024 / 1024;
    const fileType = val.target.files[0].type;
    if (fileSize > 15) {
      Info("error", "Image should be less than 15mb!");
      return;
    }
    if (fileType == "image/gif") {
      Info("error", "Please upload image only!");
      return;
    }

    if (
      fileType == "video/mp4" ||
      fileType == "video/quicktime" ||
      fileType == "video/x-matroska"
    ) {
      Info("error", "Please upload image only");
    } else {
      setValues({ ...values, profile_image: val.target.files[0] });
      getBase64(val.target.files[0]);
    }
  }

  function handleSubmit(obj) {
    const { username } = obj;
    if (!username.length) {
      if (!username.length) {
        setErrors({ ...errors, userNameError: "Please Enter User Name" });
      }
    } else {
      dispatch(
        ProfileAction.AddProfileRequest(
          {
            username,
            profile_image: values.profile_image,
            current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
          },
          () => {
            history.push("/profile");
          }
        )
      );
    }
  }
  var refImage;
  return (
    <HeaderComponent>
      <Row
        gutter={[0, 10]}
        justify="space-between"
        style={{
          padding: isXxl ? "8rem 4.5rem 0rem 4.5rem" : "5rem 2rem 0rem 2rem",
        }}
      >
        <Col span={1} align="left">
          <Image src={profileCorner} />
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={14} lg={10} xl={10} xxl={8} align="left">
              <h1
                style={{
                  fontSize: isXxl ? "46px" : "35px",
                  color: "#415465",
                  fontWeight: "700",
                }}
              >
                Profile
              </h1>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            backgroundImage: `url(${profileBackgroundLogo})`,
            backgroundRepeat: "no-repeat",
            height: isXxl ? "70vh" : "80vh",
            backgroundPositionY: isXxl ? "50%" : "60%",
          }}
        >
          <Row justify="center">
            <Col xs={24} sm={24} md={14} lg={12} xl={10} xxl={8}>
              <Row justify="center" gutter={[0, 30]} align="middle">
                <Col
                  span={24}
                  style={{
                    fontSize: isXxl ? "44px" : "24px",
                    fontWeight: "700",
                    color: "#009498",
                  }}
                >
                  Edit Profile
                </Col>
                <Col xs={20} sm={20} md={16} lg={14} xl={14} xxl={12}>
                  <Row justify="center" align="middle">
                    <Col>
                      <Image
                        src={isUpdateImg ? img : profile_image}
                        style={{
                          position: "relative",
                          border: "2px #009498 solid",
                          borderRadius: "50%",
                          width: isXxl ? "200px" : "140px",
                          height: isXxl ? "200px" : "140px",
                        }}
                      />
                      <Col>
                        <Image
                          src={uploadPhoto}
                          style={{
                            cursor: "pointer",
                            bottom: "4px",
                            right: "7px",
                            position: "absolute",
                            objectFit: "none",
                            border: "2px #009498 solid",
                            borderRadius: "50%",
                            width: isXxl ? "50px" : "40px",
                            height: isXxl ? "50px" : "40px",
                          }}
                          onClick={() => refImage.click()}
                        />
                      </Col>
                    </Col>

                    <input
                      type="file"
                      accept="image/x-png,image/gif,image/jpeg"
                      onChange={(e) => handleImage(e)}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      style={{ display: "none" }}
                      ref={(ref) => (refImage = ref)}
                    />
                  </Row>
                </Col>
                <span style={{ color: "red" }}>
                  {errors && errors.profileImageError}
                </span>

                <Col span={20}>
                  <InputWrapper
                    defaultValue={username}
                    prefix={<Image />}
                    style={{ height: isXxl ? "89px" : "45px" }}
                    name="username"
                    onChange={(e) => handleText(e)}
                  />
                  <span style={{ color: "red" }}>
                    {errors && errors.userNameError}
                  </span>
                </Col>

                <Col xs={20} sm={20} md={14} lg={12} xl={8} xxl={10}>
                  <ButtonForm
                    onClick={() => handleSubmit({ ...values })}
                    loading={addProfileLoader}
                    style={{ height: isXxl ? "56px" : "40px" }}
                  >
                    Save
                  </ButtonForm>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </HeaderComponent>
  );
};

export default EditProfile;
