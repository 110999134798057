import React, { useEffect, useState } from "react";
import { Row, Col, Table, Switch } from "antd";
import { StatusBar } from "../../components";
import { useHistory } from "react-router-dom";
import { useMedia } from "use-media";
import { useDispatch, useSelector } from "react-redux";
import SwitchWrapper from "../../components/switch";
import { connect } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { UserAction } from "../../store/actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const BlockedUsers = (props) => {
  const { userType, offset, typee, like, userStatus, clearLike } = props;
  const updateUserLoader = useSelector(
    (state) => state.UserReducer.updateUserLoader
  );
  const dispatch = useDispatch();
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const [offsett, setOffsett] = useState(offset);
  const changeStatus = (id, status) => {
    dispatch(
      UserAction.UpdateUserStatus(
        {
          limit: 10,
          offset,
          type: "blocked",
          user_id: id,
          status,
          current_time: moment().format("MM-DD-YYYY hh:mm:ss"),
        },

        () => {
          clearLike();
          dispatch(
            UserAction.GetUsersRequest({ limit: 10, offset, type: "blocked" })
          );
        }
      )
    );
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "",
      dataIndex: "profile_image",
      key: "profile_image",
      render: (text) => (
        <img
          src={text}
          style={{
            borderRadius: "50%",
            width: isXxl ? "90px" : "60px",
            height: isXxl ? "90px" : "60px",
            objectFit: "cover",
          }}
        ></img>
      ),
    },

    {
      title: "User Info",
      dataIndex: ["username", "email"],
      key: "userInfo",
      render: (text, r) => (
        <Row justify="center">
          <Col span={24}>
            <Row>
              <Col
                span={24}
                style={{
                  fontSize: isXxl ? "20px" : "16px",
                  fontWeight: "bold",
                }}
                align="left"
              >
                {r.username}
              </Col>
              <Col
                span={24}
                style={{ color: "black", fontSize: isXxl ? "18px" : "14px" }}
                align="left"
              >
                {r.email}
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: "No of Posts",
      dataIndex: "post_count",
      key: "post_count",
      sorter: (a, b) => a.post_count - b.post_count,
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "# Of Top 100",
      dataIndex: "top_hundred_count",
      key: "top_hundred_count",
      sorter: (a, b) => a.top_hundred_count - b.top_hundred_count,
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Total Reports",
      dataIndex: "total_report_count",
      key: "total_report_count",
      sorter: (a, b) => a.total_report_count - b.total_report_count,
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (e, record) => (
        <Row justify="center">
          <Col span={24}>
            <Row gutter={[10, 10]} align="middle">
              <Col
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <SwitchWrapper
                  loading={updateUserLoader}
                  checked={record.status == "banned" ? false : true}
                  onChange={(e) => {
                    changeStatus(
                      record.id,
                      record.status == "banned" ? "true" : "false"
                    );
                  }}
                />
              </Col>
              <Col
                style={{
                  color: "black",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                align="left"
              >
                User is blocked
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
  ];
  const [columnsNew, setColumnsNew] = useState(columns);

  useEffect(() => {
    setColumnsNew(columns);
  }, [userType, offset, like, userStatus]);
  useEffect(() => {
    setOffsett(offset);
  }, [offset]);
  const history = useHistory();
  const { userData, userTableLoader } = props;
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
      <Table
        style={{
          cursor: "pointer",
          borderRadius: "30px",
        }}
        columns={columnsNew}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              history.push({
                pathname: `/users/user-details/${record.id}`,
                state: {
                  id: record.id,
                  post_count: record.post_count,
                  top_hundred_count: record.top_hundred_count,
                },
              });
            },
          };
        }}
        pagination={false}
        dataSource={props.userData}
        // loading={{ spinning: userTableLoader, indicator: antIcon }}
      />
    </Col>
  );
};

export default BlockedUsers;
