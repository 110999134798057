import React from "react";
import { Modal, Row, Col } from "antd";
import { useMedia } from "use-media";
import { Image } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { ButtonForm } from "../../components";
import { CustomAdsManagementAction } from "../../store/actions";
import deleteModalIcon from "../../assets/images/deleteModalIcon.svg";

const DeleteAdModal = (props) => {
  const { modal, onCancel, data, offset, isLive, isAll, isDraft, isExpired } =
    props;
  const dispatch = useDispatch();
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });

  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  const deleteAdLoader = useSelector(
    (state) => state?.CustomAdsManagementReducer?.deleteAdLoader
  );
  return (
    <Modal
      visible={modal}
      closable={false}
      onCancel={onCancel}
      maskClosable={true}
      centered
      footer={null}
      bodyStyle={{
        height: "20px",
        alignItems: "center",
        height: isXxl ? "350px" : "250px",
      }}
    >
      <Row justify="center">
        <Col span={24} style={{ background: "white" }}>
          <Row
            justify="center"
            align="middle"
            style={{ height: isXxl ? "100px" : "80px" }}
            gutter={[20, 0]}
          >
            <Col
              style={{ color: "#009498", fontSize: isXxl ? "28px" : "20px" }}
            >
              <Image src={deleteModalIcon} />
            </Col>
            <Col style={{ color: "#009498" }}>|</Col>
            <Col
              style={{
                color: "#009498",
                fontSize: isXxl ? "28px" : "20px",
                fontWeight: "600",
              }}
            >
              Delete Ad
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ background: "#009498" }}>
          <Row
            justify="center"
            align="middle"
            style={{ height: isXxl ? "280px" : "170px" }}
          >
            <Col xxl={22} xl={18}>
              <Row justify="center" gutter={[0, isXxl ? 40 : 20]}>
                <Col
                  span={24}
                  style={{
                    color: "white",
                    fontSize: isXxl ? "28px" : "22px",
                  }}
                  align="middle"
                >
                  Are you sure you want to delete ad?
                </Col>
                <Col span={18}>
                  <Row justify="center" gutter={[20, 0]}>
                    <Col span={12}>
                      <ButtonForm
                        style={{
                          background: "white",
                          color: "#009498",
                          height: isXxl ? "56px" : "40px",
                        }}
                        loading={deleteAdLoader}
                        onClick={() =>
                          dispatch(
                            CustomAdsManagementAction.DeleteCustomAdRequest(
                              {
                                id: data.mapper_id,
                              },
                              () => {
                                onCancel();
                                dispatch(
                                  CustomAdsManagementAction.CustomAdsRequest({
                                    filter: isLive
                                      ? "live"
                                      : isAll
                                      ? "all"
                                      : isDraft
                                      ? "draft"
                                      : "expired",
                                    limit: 10,
                                    offset: offset,
                                  })
                                );
                              }
                            )
                          )
                        }
                      >
                        Yes
                      </ButtonForm>
                    </Col>
                    <Col span={12}>
                      <ButtonForm
                        style={{ height: isXxl ? "56px" : "40px" }}
                        onClick={() => onCancel()}
                      >
                        No
                      </ButtonForm>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default DeleteAdModal;
