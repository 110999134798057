import React from "react";
import { Row, Col } from "antd";
import { PieChartComponent, LineChartComponent } from "..";
import LegendBox from "./LegendBox";
import { useMedia } from "use-media";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { Colors } from "../../config";
import {
  NO_OF_USERS_COLOR,
  POST_BY_TOPIC_COLORS,
  POST_IN_TOPIC_COLORS,
  POST_SCORE_COLOR,
  REPORTED_POSTS_COLOR,
  REPORTS_COLOR,
  VISITS_COLORS,
  NO_OF_DOWNLOADS_COLOR,
} from "../../config/variables";
const CustomTooltip = ({ payload }) => {
  return (
    <Row>
      <Col span={24}>
        <p>{payload?.[0]?.payload?.name}</p>
      </Col>
      <Col span={24}>
        {payload.map((item, i) => {
          return (
            <p
              style={{
                color:
                  payload?.[i]?.dataKey == "suspended"
                    ? "#F5BA3C"
                    : payload?.[i]?.dataKey == "removed"
                    ? "#009498"
                    : "#E06030",
              }}
            >
              {payload?.[i]?.dataKey == "suspended"
                ? "Auto-Suspended" + " : " + payload?.[i]?.value
                : payload?.[i]?.dataKey == "removed"
                ? "Admin-Suspended" + " : " + payload?.[i]?.value
                : payload?.[i]?.dataKey == "released"
                ? "Released" + " : " + payload?.[i]?.value
                : payload?.[i]?.dataKey + " : " + payload?.[i]?.value}
            </p>
          );
        })}
      </Col>
    </Row>
  );
};

const ReverseTooltip = ({ payload }) => {
  const tooltipData = payload;
  console.log("tooltipData: ", tooltipData);
  const res = [...tooltipData].reverse();

  console.log("ReverseTooltip_after: ", res);

  const data = res?.map((item) => {
    return { name: item.name, value: item.payload[item.name] };
  });
  console.log("Map_data: ", data);
  return (
    <Row>
      <Col style={{ background: "#FFFFFF", padding: "5px" }}>
        {
        // (
        //   data.sort((a, b) =>
        //     a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        //   ) || []
        // )?
        data.map((item) => {
          return <p>{`${item?.name} : ${item.value}`}</p>;
        })
        }
      </Col>
    </Row>
  );
};
const ChartBox = (props) => {
  const {
    heading,
    isTwo,
    isNoOfReportedPost,
    isReports,
    STATIC_COLORS,
    isInteractions,
    isUserVisitsMonthly,
    isNoOfUsers,
    usersColor1,
    usersColor2,
    noLegend,
    pieData,
    isPostInTopic,
    downloadsColor1,
    downloadsColor2,
    isPostScore,
    isNoOfDownloads,
    isByTopic,
    usersData,
    isPie,
    barData,
    legends,
    toolTipReverse,
    sortIt,
  } = props;
  console.log("barData==>", props);

  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const COLORS = [
    Colors.Primary,
    Colors.SeaGreen,
    Colors.Yellow,
    Colors.Purple,
    Colors.LightBlue,
  ];

  const renderBar = () => {
    let arr = [];
    barData.forEach((item) => {
      const keys = Object.keys(item)
        .filter((i) => i !== "name")
        .forEach((v) => {
          if (v !== "name") {
            arr.push(v);
          }
        });
    });
    console.log("barDataArr", arr);
    console.log("bar_DataArr", barData);
    const newArr = sortIt
      ? [...new Set(arr)].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
      : [...new Set(arr)];
      // const newArr = [...new Set(arr)]
    console.log("newArr",newArr
    // .sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
    );
    return newArr.map((item, index) => {
      return (
        <Bar dataKey={item} stackId={"a"} barSize={30}>
          {barData.map((entry, i) => (
            <Cell
              key={`cell-${i}`}
              fill={
                isPostInTopic
                  ? POST_IN_TOPIC_COLORS[item]
                  : isUserVisitsMonthly
                  ? VISITS_COLORS[item]
                  : isPostScore
                  ? POST_SCORE_COLOR[item]
                  : isByTopic
                  ? POST_BY_TOPIC_COLORS[index % POST_BY_TOPIC_COLORS.length]
                  : isNoOfReportedPost
                  ? REPORTED_POSTS_COLOR[item]
                  : isReports
                  ? REPORTS_COLOR[item]
                  : isNoOfDownloads
                  ? NO_OF_DOWNLOADS_COLOR[item]
                  : COLORS[index % COLORS.length]
              }
            />
          ))}
        </Bar>
      );
    });
  };

  return (
    <Row
      gutter={[
        0,
        isPostInTopic && isXxl ? 20 : isPostInTopic && isXl ? 37 : 20,
      ]}
    >
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Row justify="space-between" align="middle">
          <Col
            xs={10}
            sm={isReports ? 6 : isNoOfReportedPost ? 8 : 10}
            md={
              isUserVisitsMonthly
                ? 8
                : isReports || isNoOfReportedPost
                ? 6
                : isPostScore
                ? 8
                : 12
            }
            lg={isUserVisitsMonthly ? 10 : isPostScore ? 8 : 12}
            xl={
              isUserVisitsMonthly || isPostScore
                ? 8
                : isInteractions
                ? 14
                : isReports
                ? 6
                : isNoOfReportedPost
                ? 8
                : isPostInTopic
                ? 8
                : 10
            }
            xxl={isInteractions ? 16 : isReports ? 6 : 8}
            align="left"
            style={{ fontSize: isXxl ? "22px" : "18px", fontWeight: "700" }}
          >
            {heading}
          </Col>
          {noLegend ? null : isInteractions ? (
            <Col xs={24} sm={24} md={12} lg={12} xl={10} xxl={8}>
              <Row justify="space-between">
                <Col span={6}>
                  <LegendBox title="Fav Selected" color="#8F8F8F" isEnd />
                </Col>
                <Col span={6}>
                  <LegendBox title="Post Seen" color="#F5BA3C" isEnd />
                </Col>
                <Col span={6}>
                  <LegendBox title="Post Clicked" color="#009498" isEnd />
                </Col>
                <Col span={6}>
                  <LegendBox title="Ads Seen" color="#E06030" isEnd />
                </Col>
              </Row>
            </Col>
          ) : isUserVisitsMonthly ? (
            <Col xs={24} sm={24} md={16} lg={14} xl={14} xxl={14}>
              <Row gutter={[20, 0]}>
                {/* {(legends || []).map((item) => {
                  return (
                    <Col style={{ width: "20%" }}>
                      <LegendBox title={item?.range} color={item?.color} />
                    </Col>
                  );
                })} */}
                <Col style={{ width: "20%" }}>
                  <LegendBox title="0" color="#8F8F8F" />
                </Col>
                <Col style={{ width: "20%" }}>
                  <LegendBox title="<10" color="#F5BA3C" />
                </Col>
                <Col style={{ width: "20%" }}>
                  <LegendBox title="10-49" color="#009498" />
                </Col>
                <Col style={{ width: "20%" }}>
                  <LegendBox title="50-99" color="#E06030" />
                </Col>
                <Col style={{ width: "20%" }}>
                  <LegendBox title="100+" color="blue" />
                </Col>
              </Row>
            </Col>
          ) : isPostScore ? (
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={14}>
              <Row justify="space-between">
                <Col span={4}>
                  <LegendBox title="<50" color="#8F8F8F" />
                </Col>
                <Col span={4}>
                  <LegendBox title="51-70" color="#F5BA3C" />
                </Col>
                <Col span={4}>
                  <LegendBox title="71-80" color="#009498" />
                </Col>
                <Col span={4}>
                  <LegendBox title="81-90" color="#E06030" />
                </Col>
                <Col span={4}>
                  <LegendBox title="91-100" color="blue" />
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              xs={24}
              sm={isReports || isNoOfReportedPost ? 16 : 12}
              md={isReports || isNoOfReportedPost ? 14 : 12}
              lg={12}
              xl={
                isNoOfReportedPost
                  ? 16
                  : isReports
                  ? 18
                  : isPostInTopic
                  ? 15
                  : 12
              }
              xxl={
                isNoOfReportedPost
                  ? 16
                  : isReports
                  ? 16
                  : isPostInTopic
                  ? 12
                  : 12
              }
            >
              {isTwo ? (
                <Row justify="end">
                  <Col span={8}>
                    <LegendBox
                      title={isNoOfUsers ? "Inactive" : "Apple"}
                      color={
                        isNoOfUsers
                          ? usersColor1
                          : isNoOfDownloads
                          ? downloadsColor1
                          : "#F5BA3C"
                      }
                    />
                  </Col>
                  <Col span={isReports ? 12 : 8}>
                    <LegendBox
                      title={isNoOfUsers ? "Active" : "Android"}
                      color={
                        isNoOfUsers
                          ? usersColor2
                          : isNoOfDownloads
                          ? downloadsColor2
                          : "#009498"
                      }
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  {isPostInTopic ? (
                    <>
                      <Col span={12}>
                        <LegendBox title="Live" color="#F5BA3C" />
                      </Col>
                      <Col span={12}>
                        <LegendBox title="Reported" color="#009498" />
                      </Col>
                      <Col span={12}>
                        <LegendBox title="Admin-Suspended" color="#E06030" />
                      </Col>
                      <Col span={12}>
                        <LegendBox title="Auto-Suspended" color="#3EB495" />
                      </Col>
                    </>
                  ) : isReports ? (
                    <>
                      <Col span={12}>
                        <LegendBox title="Reported" color="#F5BA3C" />
                      </Col>
                      <Col span={12}>
                        <LegendBox title=" Admin-Suspended" color="#009498" />
                      </Col>
                      <Col span={12}>
                        <LegendBox title="Auto-Suspended" color="#E06030" />
                      </Col>
                      <Col span={12}>
                        <LegendBox title="Cleared" color="#808080" />
                      </Col>
                    </>
                  ) : isNoOfReportedPost ? (
                    <>
                      <Col span={9}>
                        <LegendBox title="Auto-Suspended" color="#F5BA3C" />
                      </Col>
                      <Col span={10}>
                        <LegendBox title=" Admin-Suspended" color="#009498" />
                      </Col>
                      <Col span={5}>
                        <LegendBox title="Released" color="#E06030" />
                      </Col>
                    </>
                  ) : (
                    <Row
                      style={{
                        width: "100%",
                        overflowX: "auto",
                        flexWrap: "nowrap",
                      }}
                    >
                      {legends?.map((item, index) => {
                        console.log("Lengends_item: ", item);
                        return (
                          <Col
                            span={8}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              // marginLeft: "30px",
                            }}
                          >
                            <LegendBox
                              title={isByTopic ? item : item}
                              color={
                                POST_BY_TOPIC_COLORS[
                                  index % POST_BY_TOPIC_COLORS.length
                                ]
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </Row>
              )}
            </Col>
          )}
        </Row>
      </Col>
      <Col
        span={24}
        style={{ border: "1px #DFDFDF solid", borderRadius: "10px" }}
      >
        <Row
          style={{
            boxShadow: "0px 5px 20px #1E1E2214",
            borderRadius: "10px",
            height: "25rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          align="middle"
        >
          {console.log("isNoOfReportedPost", isNoOfReportedPost)}
          <Col xs={24} sm={24} md={22} lg={22} xl={24} xxl={22} align="left">
            {isPie ? (
              <PieChartComponent newData={pieData} isPostSelected />
            ) : isNoOfReportedPost ||
              isByTopic ||
              isPostInTopic ||
              isPostScore ||
              toolTipReverse ||
              isUserVisitsMonthly ||
              isNoOfDownloads ? (
              !!barData?.length ? (
                <ResponsiveContainer width="100%" height={390}>
                  <BarChart
                    data={barData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                      content={
                        isNoOfReportedPost ? (
                          <CustomTooltip />
                        ) : isUserVisitsMonthly || toolTipReverse ? (
                          <ReverseTooltip />
                        ) : null
                      }
                    />
                    {renderBar()}
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ height: "100%" }}
                >
                  <Col span={24} align="center">
                    <h4>No Data exists with these filters</h4>
                  </Col>
                </Row>
              )
            ) : (
              <LineChartComponent
                STATIC_COLORS={STATIC_COLORS}
                isReports={isReports}
                isNoOfReportedPost={isNoOfReportedPost}
                isNoOfUsers={isNoOfUsers}
                noOfUsersColor={NO_OF_USERS_COLOR}
                data={usersData}
                isInteractions={isInteractions}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
// ChartBox.defaultProps = {
//   COLORS: [Colors.SeaGreen, Colors.Yellow, Colors.Primary],
// };
export default React.memo(ChartBox);
