import React from "react";
import { Row, Col } from "antd";
import "./styles.css";
import { useMedia } from "use-media";
const StatsCard = (props) => {
  const { count, icon, backgroundColor, title, isBlack, fontColor } = props;
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        backgroundColor: backgroundColor,
        borderRadius: "16px",
        height: isXxl ? "107px" : isXl || isLg || isMd ? "80px" : "100px",
        padding: isXxl ? "0rem 2rem" : "0rem 1rem",
      }}
    >
      <Col span={16} align="left">
        <Row>
          <Col
            span={24}
            style={{
              fontSize: "30px",
              color: fontColor,
              fontWeight: "bolder",
            }}
          >
            {count}
          </Col>
          <Col span={24} style={{ fontSize: "12px", color: fontColor }}>
            {title}
          </Col>
        </Row>
      </Col>
      <Col span={8}>{icon}</Col>
    </Row>
  );
};

export default StatsCard;
