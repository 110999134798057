import React, { useState } from "react";
import { Row, Col } from "antd";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import ReactPlayer from "react-player";
import TextAndToggle from "../ConfigurationAndSettings/TextAndToggle";
import TitleAndValue from "./TitleAndValue";
import TextAndTag from "./TextAndTag";
import adModalClose from "../../assets/images/adModalClose.png";
import { ContentManagementAction } from "../../store/actions";
import SwitchWrapper from "../../components/switch";
import moment from "moment";

const UserDetailsModal = (props) => {
  const { modal, onCancel, postDetails } = props;
  const dispatch = useDispatch();
  const {
    id,
    reports_count,
    user,
    post_topic,
    thumb_url,
    file_url,
    reports,
    status,
    media_type,
    post_ranking,
    score,
    post_like_reactions_count,
    post_dislike_reactions_count,
    created_at,
  } = postDetails;
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });

  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  const updatePostLoader = useSelector(
    (state) => state?.ContentManagementReducer?.updatePostLoader
  );

  let offensive_count = 0;
  let not_relevant_topic_count = 0;
  reports?.forEach((item) => {
    offensive_count =
      item?.type == "breach_of_Guidelines"
        ? (offensive_count = item.report_type_count)
        : offensive_count;
  });

  reports?.forEach((item) => {
    not_relevant_topic_count =
      item?.type == "Not_relevant_to_topic"
        ? (not_relevant_topic_count = item.report_type_count)
        : not_relevant_topic_count;
  });

  return (
    <Modal
      visible={modal}
      onCancel={onCancel}
      closable={false}
      centered={true}
      footer={null}
      bodyStyle={{
        height: isXxl ? "690px" : isXl ? "520px" : "600px",
        background: "#009498",
        width: isXxl ? "780px" : "590px",
      }}
    >
      <Row style={{ height: "100%", position: "relative" }} justify="center">
        <Col
          span={24}
          style={{
            height: "25%",
            backgroundImage: "linear-gradient(#009498, #014247)",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <Row justify="space-between" align="middle">
            <Col span={6}>
              <TitleAndValue title="User" value={user?.username} />
            </Col>
            <Col span={8}>
              <TitleAndValue
                title="Date"
                value={moment(created_at).format("DD/MM/YYYY")}
              />
            </Col>
          </Row>
        </Col>
        <Col span={10} style={{ position: "absolute", top: -20, right: -20 }}>
          <Row justify="end">
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: isXxl ? "64px" : "50px",
                height: isXxl ? "64px" : "50px",
                borderRadius: "50%",
                backgroundColor: "#009498",
                border: "1px solid white",
                cursor: "pointer",
              }}
              onClick={() => {
                onCancel();
              }}
            >
              <img src={adModalClose} />
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          {media_type == "image" ? (
            <Row
              justify="center"
              style={{
                height: isXxl ? "223px" : "170px",
                backgroundImage: `url(${thumb_url})`,
                backgroundColor: "#F1F1F1",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                backgroundPosition: "center",
                position: "relative",
                bottom: "60px",
                borderTop: "9px solid #009498",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col></Col>
            </Row>
          ) : (
            <Row
              justify="center"
              style={{
                height: isXxl ? "223px" : "180px",
                backgroundColor: "#F1F1F1",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                backgroundPosition: "center",
                position: "relative",
                bottom: "60px",
                borderTop: "9px solid #009498",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Col>
                <ReactPlayer
                  width={isXxl ? 330 : 246}
                  height={isXxl ? 212 : 170}
                  className="react-player-styles"
                  style={{ borderRadius: "10px" }}
                  controls
                  url={file_url}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col span={24}>
          <Row justify="center" gutter={[0, 20]}>
            <Col span={23} style={{ padding: "6px 0px" }}>
              <Row>
                <Col span={8}>
                  <TextAndTag text="Topic" tag={post_topic?.name} />
                </Col>
                <Col span={9}>
                  <TextAndTag text="Status" tag={status} />
                </Col>
                <Col span={7}>
                  <Row gutter={[20, 0]} align="middle">
                    <Col
                      style={{
                        color: "white",
                        fontSize: isXxl ? "22px" : "15px",
                      }}
                    >
                      Post Disable
                    </Col>
                    <Col>
                      <SwitchWrapper
                        loading={updatePostLoader}
                        checked={
                          status == "suspended" || status == "removed"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          dispatch(
                            ContentManagementAction.UpdatePostRequest(
                              {
                                post_id: id,
                                reason: "Offensive Content",
                                current_time: moment().format(
                                  "YYYY-MM-DD hh:mm:ss"
                                ),
                              },
                              () => {
                                onCancel();
                                dispatch(
                                  ContentManagementAction.ContentsRequest({
                                    filter: "all",
                                    limit: 10,
                                    offset: 0,
                                  })
                                );
                              }
                            )
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={23} style={{ padding: "6px 0px" }}>
              <Row>
                <Col span={8}>
                  <TitleAndValue title="No of Reports" value={reports_count} />
                </Col>
                <Col span={9}>
                  <TitleAndValue
                    title="Not Relevant Reports"
                    value={not_relevant_topic_count}
                  />
                </Col>
                <Col span={7}>
                  <TitleAndValue
                    title="Offensive Reports"
                    value={offensive_count}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={23} style={{ padding: "6px 0px" }}>
              <Row>
                <Col span={8}>
                  <TitleAndValue title="Topic Rank" value="12" />
                </Col>
                <Col span={9}>
                  <TitleAndValue
                    title="Current Score"
                    value={score ? score : 0}
                  />
                </Col>
                <Col span={7}>
                  <TitleAndValue
                    title="Highest Rank"
                    value={post_ranking ? post_ranking?.highest_rank : 0}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-around">
                <Col span={8} align="middle">
                  <Row justify="center">
                    <TitleAndValue
                      title="No of Unfavorite"
                      value={post_dislike_reactions_count}
                    />
                  </Row>
                </Col>
                <Col span={8} align="middle">
                  <Row justify="center">
                    <TitleAndValue
                      title="No of Favorite"
                      value={post_like_reactions_count}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default UserDetailsModal;
