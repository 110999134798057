import React from "react";
import { connect } from "react-redux";
import { Row, Col, Pagination, Select, Spin } from "antd";
import { ButtonForm } from "../../components";
import "./styles.css";
import { Blip, SearchBar, HeaderComponent, UserTable } from "../../components";
import usersLogo from "../../../src/assets/images/usersLogo.svg";
import { withRouter } from "react-router-dom";
import { useMedia } from "use-media";
import { Colors } from "../../config";
import { Tabs } from "antd";
import Image from "../../components/image/index";
import ConfigAndSettingAction from "../../store/actions/appactions/ConfigAndSettingAction";
import { UserAction } from "../../store/actions";
import { RiSearchLine } from "react-icons/ri";
import "../../index.css";
import ProfileUsers from "./ProfileUsers";
import RegistrationUsers from "./RegistrationUsers";
import BlockedUsers from "./BlockedUsers";
import ReportedUsers from "./ReportedUsers";
import ClearedUsers from "./ClearedUsers";
const { Option } = Select;

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: "eventmanager",
      userSelectType: "event manager",
      profileSort: undefined,
      sorted: false,
      offset: 0,
      like: "",
      isUserTypeClicked: false,
      isStatusClicked: false,
      isUserTypeSecondClicked: false,
      userStatus: "",
      current: 1,
      profile: true,
      registration: false,
      reported: false,
      blocked: false,
      cleared: false
    };
  }
  handleSearch = (val) => {
    this.setState({ like: val, current: 1, offset: 0 });
    const { getusers } = this.props;
    // const { userSelectType, userStatus } = this.state;
    getusers({
      limit: 10,
      offset: 0,
      search: val,
      type: this.state.profile
        ? "profile"
        : this.state.registration
          ? "registration"
          : this.state.blocked
            ? "blocked"
            : this.state.reported
              ? "reported"
              : "cleared",
      post_sort_by: this.state.profile
        ? this.state.profileSort
        : null
    });
  };
  handleStatus = (userStatus) => {
    const { getusers } = this.props;
    const { like } = this.state;
    getusers(10, 0, "event manager", userStatus, like);
    this.setState({ userStatus: userStatus, current: 1, offset: 0 });
  };
  onUserTypeChange = (userType) => {
    const { getusers } = this.props;
    const { userStatus, like } = this.state;
    getusers(10, 0, userType, userStatus, like);
    this.setState({ userSelectType: userType, current: 1, offset: 0 });
  };
  handleSort = (type) => {
    this.setState({
      ...this.state,
      profileSort: type === "ascend"
        ? "ASC"
        : type === "descend"
          ? "DESC"
          : null
    })
  }
  componentDidMount() {
    const { getusers, getUserStatus } = this.props;
    getusers({
      limit: 10,
      offset: 0,
      type: this.state.profile
        ? "profile"
        : this.state.registration
          ? "registration"
          : this.state.blocked
            ? "blocked"
            : this.state.reported
              ? "reported"
              : "cleared",
      post_sort_by: this.state.profile
        ? this.state.profileSort
        : null
    });
    getUserStatus();
  }
  componentDidUpdate(props, preState) {
    const { getusers } = this.props;
    if (preState.profileSort !== this.state.profileSort) {
      getusers({
        limit: 10,
        offset: 0,
        type: this.state.profile
          ? "profile"
          : this.state.registration
            ? "registration"
            : this.state.blocked
              ? "blocked"
              : this.state.reported
                ? "reported"
                : "cleared",
        post_sort_by: this.state.profile
          ? this.state.profileSort
          : null
      });
    }
  }
  handlePages = (pageNumber) => {
    const limit = 10;
    let offset = (pageNumber - 1) * limit;
    const { getusers } = this.props;
    const { userSelectType, userStatus, like } = this.state;
    getusers({
      limit,
      offset,
      type: this.state.profile
        ? "profile"
        : this.state.registration
          ? "registration"
          : this.state.blocked
            ? "blocked"
            : this.state.reported
              ? "reported"
              : "cleared",
      post_sort_by: this.state.profile
        ? this.state.profileSort
        : null
    });
    this.setState({ offset: offset, current: pageNumber });
  };


  render() {
    const { userSettings } = this.props;

    const { TabPane } = Tabs;
    const { userCount, userData, isXxl } = this.props;
    const {
      offset,
      userSelectType,
      like,
      current,
      userStatus,
      userType,
      isStatusClicked,
      isUserTypeClicked,
    } = this.state;
    return (
      <HeaderComponent>
        {this.state.updateUserLoader ? (
          <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Col span={24}>
              <Spin size="large" />
            </Col>
          </Row>
        ) : (
          <Row
            gutter={[0, 20]}
            justify="space-between"
            style={{
              padding: isXxl
                ? "8rem 4.5rem 0rem 4.5rem"
                : "5rem 2rem 0rem 2rem",
            }}
          >
            <Col span={1} align="left">
              <Image src={usersLogo} />
            </Col>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={10}
                  xl={8}
                  xxl={6}
                  align="left"
                >
                  <h1 style={{ fontSize: isXxl ? "46px" : "35px" }}>
                    User Management
                  </h1>
                </Col>
                <Col xs={24} sm={24} md={10} lg={7} xl={6} xxl={6}>
                  <Row>
                    <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={21}>
                      <SearchBar
                        placeholder="Search"
                        isTable
                        onChange={(e) => {
                          this.handleSearch(e.target.value);
                        }}
                        value={like}
                      />
                    </Col>
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      xxl={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#E06030",
                        borderTopRightRadius: "33px",
                        borderBottomRightRadius: "33px",
                      }}
                    >
                      {<RiSearchLine style={{ color: "#FFFFFF" }} size={20} />}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={14}
              xl={24}
              xxl={24}
              style={{ width: "100%" }}
            >
              <Row gutter={[5, 0]}>
                <Col span={4}>
                  <ButtonForm
                    onClick={() => {
                      this.setState({
                        profile: true,
                        registration: false,
                        reported: false,
                        blocked: false,
                        cleared: false
                      });
                      this.props.getusers({
                        limit: 10,
                        offset: 0,
                        type: "profile",
                        post_sort_by: this.state.profile
                          ? this.state.profileSort
                          : null
                      });
                    }}
                    style={{
                      backgroundColor: this.state.profile
                        ? "#E06030"
                        : "#F1F1F1",
                      color: this.state.profile ? "#FFFFFF" : "#3A3A3A",
                      fontSize: isXxl ? "18px" : "14px",
                    }}
                    isTable
                    span={12}
                  >
                    Profile
                  </ButtonForm>
                </Col>
                {userSettings[0]?.status == "true" ? null : (
                  <Col span={4}>
                    <ButtonForm
                      onClick={() => {
                        this.setState({
                          profile: false,
                          reported: false,
                          registration: true,
                          cleared: false
                        });
                        this.props.getusers({
                          limit: 10,
                          offset: 0,
                          type: "registration",
                        });
                      }}
                      style={{
                        backgroundColor: this.state.registration
                          ? "#E06030"
                          : "#F1F1F1",
                        color: this.state.registration ? "#FFFFFF" : "#3A3A3A",
                        fontSize: isXxl ? "18px" : "14px",
                      }}
                      isTable
                      span={12}
                    >
                      Registration
                    </ButtonForm>
                  </Col>
                )}
                <Col span={4}>
                  <ButtonForm
                    onClick={() => {
                      this.setState({
                        profile: false,
                        registration: false,
                        reported: true,
                        blocked: false,
                        cleared: false
                      });
                      this.props.getusers({
                        limit: 10,
                        offset: 0,
                        type: "reported",
                      });
                    }}
                    style={{
                      backgroundColor: this.state.reported
                        ? "#E06030"
                        : "#F1F1F1",
                      color: this.state.reported ? "#FFFFFF" : "#3A3A3A",
                      fontSize: isXxl ? "18px" : "14px",
                    }}
                    isTable
                    span={12}
                  >
                    Reported Profiles
                  </ButtonForm>
                </Col>
                <Col span={4}>
                  <ButtonForm
                    onClick={() => {
                      this.setState({
                        profile: false,
                        registration: false,
                        reported: false,
                        blocked: true,
                        cleared: false
                      });
                      this.props.getusers({
                        limit: 10,
                        offset: 0,
                        type: "blocked",
                      });
                    }}
                    style={{
                      backgroundColor: this.state.blocked
                        ? "#E06030"
                        : "#F1F1F1",
                      color: this.state.blocked ? "#FFFFFF" : "#3A3A3A",
                      fontSize: isXxl ? "18px" : "14px",
                    }}
                    isTable
                    span={12}
                  >
                    Blocked Profiles
                  </ButtonForm>
                </Col>
                <Col span={4}>
                  <ButtonForm
                    onClick={() => {
                      this.setState({
                        profile: false,
                        registration: false,
                        reported: false,
                        blocked: false,
                        cleared: true
                      });
                      this.props.getusers({
                        limit: 10,
                        offset: 0,
                        type: "cleared",
                      });
                    }}
                    style={{
                      backgroundColor: this.state.cleared
                        ? "#E06030"
                        : "#F1F1F1",
                      color: this.state.cleared ? "#FFFFFF" : "#3A3A3A",
                      fontSize: isXxl ? "18px" : "14px",
                    }}
                    isTable
                    span={12}
                  >
                    Cleared
                  </ButtonForm>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {this.state.profile ? (
                <Row>
                  <Col span={24} style={{ padding: "30px 0px" }}>
                    <Row
                      style={{
                        boxShadow: "0px 5px 20px ##D4D4D4",
                      }}
                      className="manageCategoryTable"
                    >
                      <ProfileUsers
                        limit={10}
                        offset={offset}
                        userData={this.props.userData}
                        typee={userSelectType}
                        like={like}
                        userStatus={userStatus}
                        clearLike={() =>
                          this.setState({
                            like: "",
                          })
                        }
                        handleSort={this.handleSort}
                      />
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="end">
                      <Pagination
                        responsive="true"
                        showLessItems="true"
                        current={current}
                        pageSize={10}
                        total={this.props.userCount ? this.props.userCount : 0}
                        onChange={(e) => {
                          this.handlePages(e);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              ) : this.state.registration ? (
                <Row>
                  <Col span={24} style={{ padding: "30px 0px" }}>
                    <Row
                      style={{
                        boxShadow: "0px 5px 20px #1E1E2214",
                        borderRadius: "10px",
                      }}
                    >
                      <RegistrationUsers
                        userType={userType}
                        userData={this.props.userData}
                        limit={10}
                        offset={offset}
                        typee={userSelectType}
                        like={like}
                        userStatus={userStatus}
                        clearLike={() =>
                          this.setState({
                            like: "",
                          })
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="end">
                      <Pagination
                        responsive="true"
                        showLessItems="true"
                        current={current}
                        pageSize={10}
                        total={this.props.userCount ? this.props.userCount : 0}
                        onChange={(e) => {
                          this.handlePages(e);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              ) : this.state.blocked ? (
                <Row>
                  <Col span={24} style={{ padding: "30px 0px" }}>
                    <Row
                      style={{
                        boxShadow: "0px 5px 20px #1E1E2214",
                        borderRadius: "10px",
                      }}
                    >
                      <BlockedUsers
                        limit={10}
                        offset={offset}
                        userData={this.props.userData}
                        typee={userSelectType}
                        like={like}
                        userStatus={userStatus}
                        clearLike={() =>
                          this.setState({
                            like: "",
                          })
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="end">
                      <Pagination
                        responsive="true"
                        showLessItems="true"
                        current={current}
                        pageSize={10}
                        total={this.props.userCount ? this.props.userCount : 0}
                        onChange={(e) => {
                          this.handlePages(e);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              ) : this.state.reported ? (
                <Row>
                  <Col span={24} style={{ padding: "30px 0px" }}>
                    <Row
                      style={{
                        boxShadow: "0px 5px 20px #1E1E2214",
                        borderRadius: "10px",
                      }}
                    >
                      <ReportedUsers
                        limit={10}
                        offset={offset}
                        userData={this.props.userData}
                        typee={userSelectType}
                        like={like}
                        userStatus={userStatus}
                        clearLike={() =>
                          this.setState({
                            like: "",
                          })
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="end">
                      <Pagination
                        responsive="true"
                        showLessItems="true"
                        current={current}
                        pageSize={10}
                        total={this.props.userCount ? this.props.userCount : 0}
                        onChange={(e) => {
                          this.handlePages(e);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col span={24} style={{ padding: "30px 0px" }}>
                    <Row
                      style={{
                        boxShadow: "0px 5px 20px #1E1E2214",
                        borderRadius: "10px",
                      }}
                    >
                      <ClearedUsers
                        limit={10}
                        offset={offset}
                        userData={this.props.userData}
                        typee={userSelectType}
                        like={like}
                        userStatus={userStatus}
                        clearLike={() =>
                          this.setState({
                            like: "",
                          })
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="end">
                      <Pagination
                        responsive="true"
                        showLessItems="true"
                        current={current}
                        pageSize={10}
                        total={this.props.userCount ? this.props.userCount : 0}
                        onChange={(e) => {
                          this.handlePages(e);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        )}
      </HeaderComponent>
    );
  }
}

const MediaWrapper = (props) => {
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <Users
      isXs={isXs}
      isSm={isSm}
      isMd={isMd}
      isLg={isLg}
      isXl={isXl}
      isXxl={isXxl}
      {...props}
    />
  );
};

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    getusers: (params) => {
      dispatch(UserAction.GetUsersRequest(params));
    },

    getUserStatus: () => {
      dispatch(ConfigAndSettingAction.ConfigAndSettingsRequest());
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({
      userData: storeState.UserReducer.userdata,
      userCount: storeState.UserReducer.userCount,
      userLoader: storeState.UserReducer.userTableLoader,
      userSettings: storeState.ConfigAndSettingReducer.users,
      updateUserLoader: storeState.UserReducer.updateUserLoader,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(Users)
);
