import {
  AuthMiddleware,
  FormMiddleware,
  UserMiddleware,
  EventMiddleware,
  TransactionMiddleware,
  ConfigAndSettingMiddleware,
  DashboardMiddleware,
  LocationManagementMiddleware,
  ContentManagementMiddleware,
  TopicManagementMiddleware,
  ProfileMiddleware,
  CustomAdsManagementMiddleware,
} from "../middlewares";
import { takeLatest, all } from "redux-saga/effects";
import {
  SIGNIN_REQUEST,
  SIGNOUT,
  FORGETPASSWORD,
  VERIFYCODE,
  RESETPASSWORD,
  GETFORMS,
  UPLOADFORMS,
  DELETEFORMS,
  GETUSERSTATS,
  USER_DETAIL_REQUEST,
  GETAPPDOWNLOADCOUNT,
  GETUSERTYPECOUNT,
  GETEVENTSTATSCOUNT,
  GETSUBSCRIBEDUSERCOUNT,
  UPDATE_USER_STATUS_REQUEST,
  GETEVENTS,
  DELETEEVENTS,
  CHANGEPASSWORD,
  GETTRANSACTIONS,
  CONFIG_AND_SETTINGS_REQUEST,
  UPDATE_CONFIG_AND_SETTINGS_REQUEST,
  DASHBOARD_REQUEST,
  COUNTRIES_REQUEST,
  GET_USERS_REQUEST,
  NON_LIVE_REPORTS_REQUEST,
  LOCATIONS_REQUEST,
  UPDATE_LOCATION_REQUEST,
  UPDATE_CONTENT_REQUEST,
  CONTENTS_REQUEST,
  TOPICS_REQUEST,
  UPDATE_TOPIC_REQUEST,
  ADD_TOPIC_REQUEST,
  PROFILE_REQUEST,
  ADD_PROFILE_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  SUSPICIOUS_ACTIVITY_REQUEST,
  CUSTOM_ADS_REQUEST,
  ADD_CUSTOM_AD_REQUEST,
  UPDATE_CUSTOM_AD_REQUEST,
  DELETE_CUSTOM_AD_REQUEST,
  TOGGLE_AD_REQUEST,
  UPDATE_POST_REQUEST,
  POST_DETAILS_REQUEST,
  GET_INTERACTION_REQUEST
} from "../constants";

export function* Sagas() {
  yield all([
    yield takeLatest(
      CONFIG_AND_SETTINGS_REQUEST,
      ConfigAndSettingMiddleware.GetConfigAndSetting
    ),
    yield takeLatest(
      UPDATE_CONFIG_AND_SETTINGS_REQUEST,
      ConfigAndSettingMiddleware.UpdateConfigAndSetting
    ),

    yield takeLatest(DASHBOARD_REQUEST, DashboardMiddleware.GetDashboardStats),
    yield takeLatest(
      NON_LIVE_REPORTS_REQUEST,
      DashboardMiddleware.NonLiveReportsStats
    ),
    yield takeLatest(
      LOCATIONS_REQUEST,
      LocationManagementMiddleware.LocationsRequest
    ),
    yield takeLatest(
      UPDATE_LOCATION_REQUEST,
      LocationManagementMiddleware.UpdateLocationRequest
    ),
    yield takeLatest(
      CONTENTS_REQUEST,
      ContentManagementMiddleware.ContentsRequest
    ),
    yield takeLatest(
      UPDATE_CONTENT_REQUEST,
      ContentManagementMiddleware.UpdateContent
    ),
    yield takeLatest(
      UPDATE_POST_REQUEST,
      ContentManagementMiddleware.UpdatePost
    ),
    yield takeLatest(
      POST_DETAILS_REQUEST,
      ContentManagementMiddleware.PostDetailsRequest
    ),
    yield takeLatest(TOPICS_REQUEST, TopicManagementMiddleware.TopicsRequest),
    yield takeLatest(
      UPDATE_TOPIC_REQUEST,
      TopicManagementMiddleware.UpdateTopicRequest
    ),
    yield takeLatest(
      ADD_TOPIC_REQUEST,
      TopicManagementMiddleware.AddTopicRequest
    ),
    yield takeLatest(PROFILE_REQUEST, ProfileMiddleware.ProfileRequest),

    yield takeLatest(ADD_PROFILE_REQUEST, ProfileMiddleware.AddProfileRequest),
    yield takeLatest(
      CHANGE_PASSWORD_REQUEST,
      ProfileMiddleware.ChangePasswordRequest
    ),

    yield takeLatest(COUNTRIES_REQUEST, DashboardMiddleware.GetCountries),
    yield takeLatest(SIGNIN_REQUEST, AuthMiddleware.SignIn),
    // yield takeLatest(SIGNOUT, AuthMiddleware.Signout),
    yield takeLatest(FORGETPASSWORD, AuthMiddleware.ForgetPassword),
    yield takeLatest(VERIFYCODE, AuthMiddleware.VerifyCode),
    yield takeLatest(CHANGEPASSWORD, AuthMiddleware.ChangePassword),
    yield takeLatest(RESETPASSWORD, AuthMiddleware.ResetPassword),
    yield takeLatest(GETFORMS, FormMiddleware.GetForms),
    yield takeLatest(UPLOADFORMS, FormMiddleware.UploadForms),
    yield takeLatest(DELETEFORMS, FormMiddleware.DeleteForms),
    yield takeLatest(GET_USERS_REQUEST, UserMiddleware.GetUsers),
    yield takeLatest(USER_DETAIL_REQUEST, UserMiddleware.UserDetailsRequest),
    yield takeLatest(
      UPDATE_USER_STATUS_REQUEST,
      UserMiddleware.UpdateUserStatus
    ),
    yield takeLatest(GETAPPDOWNLOADCOUNT, UserMiddleware.GetAppDownloadCount),
    yield takeLatest(GETUSERTYPECOUNT, UserMiddleware.GetUserTypeCount),
    yield takeLatest(GETEVENTSTATSCOUNT, UserMiddleware.GetEventStatsCount),
    yield takeLatest(
      GETSUBSCRIBEDUSERCOUNT,
      UserMiddleware.GetSubscribedUserCount
    ),

    yield takeLatest(
      SUSPICIOUS_ACTIVITY_REQUEST,
      DashboardMiddleware.SuspiciousAcitivityRequest
    ),

    yield takeLatest(
      CUSTOM_ADS_REQUEST,
      CustomAdsManagementMiddleware.CustomAdsRequest
    ),
    yield takeLatest(
      ADD_CUSTOM_AD_REQUEST,
      CustomAdsManagementMiddleware.AddCustomAdRequest
    ),
    yield takeLatest(
      UPDATE_CUSTOM_AD_REQUEST,
      CustomAdsManagementMiddleware.UpdateCustomAdRequest
    ),
    yield takeLatest(
      DELETE_CUSTOM_AD_REQUEST,
      CustomAdsManagementMiddleware.DeleteCustomAdRequest
    ),
    yield takeLatest(
      TOGGLE_AD_REQUEST,
      CustomAdsManagementMiddleware.ToggleAdRequest
    ),
    yield takeLatest(
      GET_INTERACTION_REQUEST,
      DashboardMiddleware.GetInteractionsData
    ),
  ]);
}
