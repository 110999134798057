import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "antd";
import Info from "../../components/message";
import adModalClose from "../../assets/images/adModalClose.png";
import "./styles.css";
import { useMedia } from "use-media";
import DashboardAction from "../../store/actions/appactions/ContentManagementAction";
import InputWrapper from "../../components/input";
import Image from "../../components/image/index";
import { ButtonForm } from "../../components";
import BasicForm from "./BasicForm";
import PremiumForm from "./PremiumForm";

const CreateAdModal = (props) => {
  const {
    topicsOptions,
    modal,
    offset,
    onCancel,
    bodyStyle,
    countriesOptions,
    customStyles,
    data,
    type,
  } = props;

  const [state, setState] = useState({
    basic: true,
    premium: false,
    clearStates: false,
  });

  const {
    gender,
    topic_ids,
    countries,
    file,
    save_as,
    basic,
    premium,
    clearStates,
  } = state;
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });

  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  function handleUpload(val) {
    const fileSize = val.target.files[0].size / 1024 / 1024;
    const fileType = val.target.files[0].type;
    if (fileSize > 15) {
      Info("error", "Image should be less than 15mb!");
    } else {
      setState({ ...state, profile_image: val.target.files[0] });
      //   getBase64(val.target.files[0]);
    }
  }

  return (
    <Modal
      visible={modal}
      className="ad-modal"
      closable={false}
      onCancel={onCancel}
      centered={true}
      footer={null}
      bodyStyle={{
        height:
          isXxl && basic
            ? "590px"
            : isXxl && premium
            ? "687px"
            : isXl && basic
            ? "430px"
            : isXl && premium
            ? "520px"
            : "600px",
        background: "#009498",
        alignItems: "center",
        width: isXxl ? "900px" : "700px",
      }}
    >
      <Row justify="center" align="middle" gutter={[0, 30]}>
        <Col
          span={24}
          style={{
            height: isXxl ? "80px" : "30px",
          }}
        >
          <Row
            justify="center"
            align="middle"
            gutter={[0, 20]}
            style={{ height: "100%", position: "relative" }}
          >
            <Col
              span={14}
              align="middle"
              style={{
                color: "#FFFFFF",
                fontSize: isXxl ? "28px" : "20px",
                padding: "4px",
                fontWeight: "600",
              }}
            >
              <Row justify="end">
                <Col span={24}>Create AD</Col>
              </Row>
            </Col>
            <Col
              span={10}
              style={{ position: "absolute", top: -20, right: -20 }}
            >
              <Row justify="end">
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: isXxl ? "64px" : "50px",
                    height: isXxl ? "64px" : "50px",
                    borderRadius: "50%",
                    backgroundColor: "#009498",
                    border: "1px solid white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onCancel();
                  }}
                >
                  <img src={adModalClose} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={22}>
          <Row>
            <Col span={10}>
              <Row gutter={[10, 0]} justify="start">
                <Col span={12}>
                  <ButtonForm
                    isAdModal
                    onClick={() =>
                      setState({ ...state, basic: true, premium: false })
                    }
                    style={{
                      backgroundColor: basic ? "#014247" : "#5BBBBD",
                      fontSize: isXxl ? "18px" : "14px",
                      height: isXxl ? "44px" : "35px",
                    }}
                  >
                    <p
                      style={{
                        color: "#FFFFFF",
                        opacity: basic ? "100%" : "49%",
                        margin: 0,
                      }}
                    >
                      Basic Ad
                    </p>
                  </ButtonForm>
                </Col>
                <Col span={12}>
                  <ButtonForm
                    onClick={() =>
                      setState({ ...state, premium: true, basic: false })
                    }
                    isAdModal
                    style={{
                      backgroundColor: premium ? "#014247" : "#5BBBBD",

                      fontSize: isXxl ? "18px" : "14px",
                      height: isXxl ? "44px" : "35px",
                    }}
                  >
                    <p
                      style={{
                        color: "#FFFFFF",
                        opacity: premium ? "100%" : "49%",
                        margin: 0,
                      }}
                    >
                      Premium Ad
                    </p>
                  </ButtonForm>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ border: "1px solid #5BBBBD" }}></Col>
          </Row>
        </Col>

        <Col span={24}>
          {basic ? (
            <BasicForm
              isUpdate
              data={data}
              offset={offset}
              onCancel={onCancel}
              modal={modal}
              countriesOptions={countriesOptions}
              topicsOptions={topicsOptions}
              customStyles={customStyles}
              basic={basic}
              type={type}
            />
          ) : (
            <PremiumForm
              offset={offset}
              data={data}
              countriesOptions={countriesOptions}
              onCancel={onCancel}
              modal={modal}
              topicsOptions={topicsOptions}
              customStyles={customStyles}
              premium={premium}
              type={type}
            />
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateAdModal;
