import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import FormReducer from "../reducers/appreducers/FormReducer";
import UserReducer from "../reducers/appreducers/UserReducer";
import EventReducer from "../reducers/appreducers/EventReducer";
import TransactionReducer from "../reducers/appreducers/TransactionReducer";
import ConfigAndSettingReducer from "../reducers/appreducers/ConfigAndSettingReducer";
import DashboardReducer from "../reducers/appreducers/DashboardReducer";
import LocationManagementReducer from "../reducers/appreducers/LocationManagementReducer";
import ContentManagementReducer from "../reducers/appreducers/ContentManagementReducer";
import TopicManagementReducer from "../reducers/appreducers/TopicManagementReducer";
import ProfileReducer from "../reducers/appreducers/ProfileReducer";
import CustomAdsManagementReducer from "./appreducers/CustomAdsManagementReducer";
const RootReducer = combineReducers({
  AuthReducer,
  FormReducer,
  UserReducer,
  EventReducer,
  TransactionReducer,
  ConfigAndSettingReducer,
  DashboardReducer,
  LocationManagementReducer,
  ProfileReducer,
  ContentManagementReducer,
  TopicManagementReducer,
  CustomAdsManagementReducer,
});

export default RootReducer;
