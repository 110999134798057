import {
  CUSTOM_ADS_REQUEST,
  CUSTOM_ADS_SUCCESS,
  CUSTOM_ADS_FAILURE,
  ADD_CUSTOM_AD_REQUEST,
  ADD_CUSTOM_AD_SUCCESS,
  ADD_CUSTOM_AD_FAILURE,
  UPDATE_CUSTOM_AD_REQUEST,
  UPDATE_CUSTOM_AD_SUCCESS,
  UPDATE_CUSTOM_AD_FAILURE,
  DELETE_CUSTOM_AD_SUCCESS,
  DELETE_CUSTOM_AD_FAILURE,
  DELETE_CUSTOM_AD_REQUEST,
  TOGGLE_AD_REQUEST,
  TOGGLE_AD_SUCCESS,
  TOGGLE_AD_FAILURE,
} from "../../constants";

export default class CustomAdsManagementAction {
  static CustomAdsRequest(params) {
    return {
      type: CUSTOM_ADS_REQUEST,
      params,
    };
  }
  static CustomAdsSuccess(payload, count) {
    return {
      type: CUSTOM_ADS_SUCCESS,
      count,
      payload,
    };
  }
  static CustomAdsFailure() {
    return {
      type: CUSTOM_ADS_FAILURE,
    };
  }

  static AddCustomAdRequest(body, cb) {
    return {
      type: ADD_CUSTOM_AD_REQUEST,
      body,
      cb,
    };
  }
  static AddCustomAdSuccess(payload, count) {
    return {
      type: ADD_CUSTOM_AD_SUCCESS,
      payload,
      count,
    };
  }
  static AddCustomAdFailure() {
    return {
      type: ADD_CUSTOM_AD_FAILURE,
    };
  }

  static UpdateCustomAdRequest(body, cb) {
    return {
      type: UPDATE_CUSTOM_AD_REQUEST,
      body,
      cb,
    };
  }
  static UpdateCustomAdSuccess(payload) {
    return {
      type: UPDATE_CUSTOM_AD_SUCCESS,
      payload,
    };
  }
  static UpdateCustomAdFailure() {
    return {
      type: UPDATE_CUSTOM_AD_FAILURE,
    };
  }

  static DeleteCustomAdRequest(params, cb) {
    return {
      type: DELETE_CUSTOM_AD_REQUEST,
      params,
      cb,
    };
  }
  static DeleteCustomAdSuccess() {
    return {
      type: DELETE_CUSTOM_AD_SUCCESS,
    };
  }
  static DeleteCustomAdFailure() {
    return {
      type: DELETE_CUSTOM_AD_FAILURE,
    };
  }

  static ToggleAdRequest(params, cb) {
    return {
      type: TOGGLE_AD_REQUEST,
      params,
      cb,
    };
  }
  static ToggleAdSuccess(payload) {
    return {
      type: TOGGLE_AD_SUCCESS,
      payload,
    };
  }
  static ToggleAdFailure() {
    return {
      type: TOGGLE_AD_FAILURE,
    };
  }
}
