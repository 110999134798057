import Avatar from "../../assets/images/User.svg";
import DashboardIcon from "../../assets/images/dashboard-line.svg";
import UserIcon from "../../assets/images/Userlist.svg";
import EventsIcon from "../../assets/images/Events.svg";
import EventIconClicked from "../../assets/images/EventIconClicked.svg";
import TransactionsIcon from "../../assets/images/transaction.svg";
import DashboardIconClicked from "../../assets/images/dashboardiconclicked.svg";
import UserIconClicked from "../../assets/images/userafterclick.svg";
import FormIconClicked from "../../assets/images/formafterclick.svg";
import UserOnClick from "../../assets/images/useronclick.svg";
import FormClicked from "../../assets/images/formsonclick.svg";
import FormsIcon from "../../assets/images/forms.svg";
import topicNewIcon from "../../assets/images/topicNewIcon.svg";
import DashboardIconAfterClicked from "../../assets/images/dashboardafterclick.svg";
import Logo from "../../assets/images/Logo.png";
import BasicUser from "../../assets/images/basicuser.svg";
import SubbedUser from "../../assets/images/subbeduser.svg";
import DeletedUser from "../../assets/images/deleteduser.svg";
import LeftArrow from "../../assets/images/left-arrow.png";
import EventIconSiderClicked from "../../assets/images/EventIconSiderClicked.svg";
import TransactionIconSiderClicked from "../../assets/images/TransactionIconSiderClicked.svg";
import TransactionIconFooter from "../../assets/images/TransactionIconFooter.svg";
import dashboardLogout from "../../assets/images/dashboardLogout.svg";
import dashboardUser from "../../assets/images/dashboardUser.svg";
import invoiceIcon from "../../assets/images/invoiceIcon.svg";
import InvoiceIconNew from "../../assets/images/invoiceiconnew.svg";
import PdfIcon from "../../assets/images/pdfIcon.png";
import profileLogo from "../../assets/images/profileLogo.svg";
import profileLogoClicked from "../../assets/images/profileLogoClicked.svg";
import dashboardSettings from "../../assets/images/dashboardSettings.svg";
import dashboardSettingsClicked from "../../assets/images/dashboardSettingsClicked.svg";
import dashboardAdClicked from "../../assets/images/dashboardAdClicked.svg";
import dashboardContent from "../../assets/images/dashboardContent.svg";
import dashboardClicked from "../../assets/images/dashboardClicked.svg";
import adManagement from "../../assets/images/adManagement.svg";
import dashboardLocation from "../../assets/images/dashboardLocation.svg";
import dashboardLocationClicked from "../../assets/images/dashboardLocationClicked.svg";
import dashboardTopic from "../../assets/images/dashboardTopic.svg";
import dashboardTopicClicked from "../../assets/images/dashboardTopicClicked.svg";
import dashboardContentClicked from "../../assets/images/dashboardContentClicked.svg";
import dashboardDashboard from "../../assets/images/dashboardDashboard.svg";
import dashboardUsers from "../../assets/images/dashboardUsers.svg";
import dashboardUsersClicked from "../../assets/images/dashboardUsersClicked.svg";
import toggledLogo from "../../assets/images/toggledLogo.svg";
import toggledArrow from "../../assets/images/toggledArrow.svg";
import topicNewIconClicked from "../../assets/images/topicNewIconClicked.svg";
const Images = {
  Avatar,
  DashboardIcon,
  UserIcon,
  EventsIcon,
  TransactionsIcon,
  DashboardIconClicked,
  dashboardUsers,
  dashboardUsersClicked,
  dashboardDashboard,
  UserIconClicked,
  dashboardLocationClicked,
  dashboardContentClicked,
  FormIconClicked,
  dashboardTopic,
  UserOnClick,
  toggledArrow,
  FormClicked,
  dashboardTopicClicked,
  topicNewIconClicked,
  FormsIcon,
  DashboardIconAfterClicked,
  dashboardSettingsClicked,
  toggledLogo,
  dashboardAdClicked,
  dashboardLogout,
  dashboardLocation,
  Logo,
  BasicUser,
  SubbedUser,
  DeletedUser,
  dashboardUser,
  LeftArrow,
  EventIconClicked,
  EventIconSiderClicked,
  dashboardClicked,
  TransactionIconSiderClicked,
  TransactionIconFooter,
  topicNewIcon,
  invoiceIcon,
  adManagement,
  InvoiceIconNew,
  profileLogoClicked,
  dashboardSettings,
  dashboardContent,
  profileLogo,
  PdfIcon,
};

export default Images;
