import React from "react";
import { Modal, Row, Col } from "antd";
import { ButtonForm } from "../../components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { EventAction } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useMedia } from "use-media";
import Image from "../../components/image";
import logoutModal from "../../../src/assets/images/logoutModal.svg";
const ModalComponent = (props) => {
  const {
    id,
    like,
    limit,
    offset,
    isModalVisible,
    onCancel,
    handleOk,
    content,
    deleteButtonLoader,
  } = props;
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const dispatch = useDispatch();
  const deleteEvent = () => {
    localStorage.removeItem("eventauth");
    window.location.reload();
    // dispatch(
    //   EventAction.DeleteEvent(id, like, limit, offset, () => {
    //     handleOk();
    //   })
    // );
  };
  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={onCancel}
        {...props}
        wrapClassName="modalTheme"
        style={{
          padding: "0px !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row justify="center" align="middle">
          <Col
            span={24}
            style={{
              backgroundColor: "white",
              height: isXxl ? "123px" : "100px",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <Row
              align="middle"
              style={{ marginTop: isXxl ? "2rem" : "1.5rem" }}
            >
              <Col
                span={11}
                align="right"
                style={{ padding: "0px 10px 0px 0px" }}
              >
                <Image src={logoutModal} />
              </Col>
              <Col
                span={13}
                style={{
                  padding: "0px 0px 0px 10px",
                  borderLeft: "#009498 1px solid",
                  fontSize: "38px",
                  fontWeight: "700",
                  color: "#009498",
                }}
              >
                Log Out
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            style={{
              backgroundColor: "#009498",
              height: isXxl ? "322px" : "200px",
              borderRadius: "0px 0px 20px 20px",
              padding: "0rem 2rem",
            }}
          >
            <Row
              justify="center"
              align="middle"
              style={{ marginTop: isXxl ? "5rem" : "2rem" }}
            >
              <Col span={24} align="middle">
                <Row align="middle" justify="center" gutter={[0, 50]}>
                  <Col
                    span={24}
                    style={{
                      fontSize: isXxl ? "28px" : "20px",
                      color: "white",
                    }}
                  >
                    Are you sure you want to Log Out?
                  </Col>
                  <Col span={24}>
                    <Row justify="space-around">
                      <Col xl={8} xxl={10}>
                        <ButtonForm
                          style={{
                            color: "#009498",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            deleteEvent();
                          }}
                          loading={deleteButtonLoader}
                        >
                          Yes
                        </ButtonForm>
                      </Col>
                      <Col xl={8} xxl={10}>
                        <ButtonForm
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </ButtonForm>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default React.memo(
  withRouter(
    connect((storeState) => ({
      deleteButtonLoader: storeState.EventReducer.deleteButtonLoader,
    }))(ModalComponent)
  )
);
