import React from "react";
import { Row, Col } from "antd";
import InputWrapper from "../../components/input";
import { useMedia } from "use-media";
const TextAndInput = (props) => {
  const {
    title,
    placeholder,
    value,
    name,
    handleNumber,
    errors,
    isReported,
    isPosts,
    isPostRanking,
    isAlternativePostWeight,
    isUsers,
  } = props;
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <Row justify="space-between" align="middle" gutter={[0, 10]}>
      <Col
        xs={24}
        sm={isPosts ? 16 : isReported ? 16 : 8}
        md={isReported ? 16 : 8}
        lg={isPosts ? 10 : isReported ? 18 : 15}
        xl={
          isPosts
            ? 16
            : isPostRanking
            ? 12
            : isAlternativePostWeight
            ? 13
            : isReported
            ? 15
            : 14
        }
        xxl={
          isPostRanking
            ? 12
            : isPosts
            ? 16
            : isReported
            ? 17
            : isAlternativePostWeight
            ? 11
            : 15
        }
        style={{
          fontSize: isXxl ? "16px" : isXl ? "12px" : isLg ? "15px" : "17px",
        }}
        align="left"
      >
        {title}
      </Col>
      <Col
        xs={24}
        sm={isPosts ? 8 : isReported ? 6 : 8}
        md={isReported ? 7 : 8}
        lg={isPosts ? 7 : isReported ? 6 : 8}
        xl={
          isPosts
            ? 8
            : isPostRanking
            ? 12
            : isAlternativePostWeight
            ? 11
            : isReported
            ? 5
            : 10
        }
        xxl={
          isPostRanking
            ? 12
            : isPosts
            ? 8
            : isReported
            ? 5
            : isAlternativePostWeight
            ? 11
            : isUsers
            ? 7
            : 4
        }
        align="right"
      >
        <InputWrapper
          placeholder={placeholder}
          isSetting
          maxLength={4}
          name={name ? name : ""}
          value={value ? value : ""}
          onChange={(e) => handleNumber(e)}
          errors={errors}
        />
      </Col>
    </Row>
  );
};

export default TextAndInput;
