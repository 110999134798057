import React from "react";
import { connect } from "react-redux";
import { Row, Col, Input, Spin } from "antd";
import { Image, HeaderComponent } from "../../components";
import { useMedia } from "use-media";
import { withRouter } from "react-router-dom";
import { Images } from "../../config";
import { ButtonForm } from "../../components";
import { ContentManagementAction, UserAction } from "../../store/actions";
import UsersPost from "./UsersPost";
import userDetailsLogo from "../../assets/images/userDetailsLogo.svg";
import "../../index.css";
import UserDetailsModal from "./UserDetailsModal";
class UsersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCondition: false,
      userId: "",
      allPost: true,
      live: false,
      top100: false,
      removed: false,
      modal: false,
      id: "",
    };
  }
  componentDidMount() {
    const {
      userdetailsRequest,
      postDetailsRequest,
      updatePostRequest,
      history,
    } = this.props;
    if (this?.props?.location?.state?.id) {
      let userId = this?.props?.location?.state?.id;
      userdetailsRequest({ userId, type: "all" });
    } else {
      history.push("/");
    }
  }
  render() {
    const {
      userDetails,
      postDetails,
      history,
      isXs,
      isSm,
      isLg,
      isMd,
      isXl,
      isXxl,
    } = this.props;
    const { modal } = this.state;
    const colStyles = {
      flexBasis: isXxl || isXl || isLg ? "20%" : isMd ? "50%" : "100%",
      width: isXxl || isXl || isLg ? "20%" : isMd ? "50%" : "100%",
    };
    const divStyles = {
      backgroundColor: "yellow",
      padding: ".5em",
      textAlign: "center",
    };
    return (
      <HeaderComponent>
        {this.props.loader ? (
          <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Col span={24}>
              <Spin size="large" />
            </Col>
          </Row>
        ) : (
          <>
            <Row
              gutter={[0, 10]}
              justify="space-between"
              style={{
                padding: isXxl
                  ? "8rem 4.5rem 0rem 4.5rem"
                  : "5rem 2rem 0rem 2rem",
              }}
            >
              <Col span={1} align="left">
                <Image src={userDetailsLogo} />
              </Col>
              <Col span={24}>
                <Row justify="space-between" align="middle">
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={10}
                    xl={8}
                    xxl={6}
                    align="left"
                  >
                    <h1
                      style={{
                        fontSize: isXxl ? "46px" : "35px",
                        color: "#415465",
                        fontWeight: "700",
                      }}
                    >
                      User Details
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="start">
                  <Col>
                    <Image
                      src={
                        userDetails?.user
                          ? userDetails?.user[0].profile_image
                          : this.state.top100
                          ? userDetails[0]?.profile_image
                          : null
                      }
                      style={{
                        borderRadius: "50%",
                        width: isXxl ? "120.42px" : "100px",
                        height: isXxl ? "120.42px" : "100px",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={18}
                lg={18}
                xl={12}
                xxl={12}
                style={{
                  height: isXxl ? "133px" : "120px",
                }}
              >
                <Row align="middle">
                  <Col
                    lg={22}
                    xl={20}
                    xxl={20}
                    // style={{ border: "1px solid black" }}
                  >
                    <Row align="middle">
                      <Col xs={12} sm={12} md={12} lg={10} xl={12} xxl={12}>
                        <Row align="middle">
                          <Col
                            align="left"
                            span={24}
                            style={{
                              color: "#E06030",
                              fontWeight: "700",
                              fontSize: isXxl ? "54px" : "40px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {userDetails?.user
                              ? userDetails?.user[0].username
                              : this.state.top100
                              ? userDetails[0]?.username
                              : null}
                          </Col>
                          <Col
                            align="left"
                            span={24}
                            style={{
                              color: "#017D86",
                              fontWeight: "600",
                              fontSize: isXxl ? "22px" : "18px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {userDetails?.user
                              ? userDetails?.user[0].email
                              : this.state.top100
                              ? userDetails[0]?.email
                              : null}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          borderRadius: "9px",
                          backgroundColor: "#E06030",
                          width: isXxl ? "8px" : "10px",
                          height: isXxl ? "133px" : "130px",
                        }}
                      ></Col>
                      <Col xs={11} sm={11} md={11} lg={11} xl={10} xxl={10}>
                        <Row align="middle" justify="center">
                          <Col span={16} align="left">
                            <Row>
                              <Col
                                span={24}
                                style={{
                                  color: "#8F8F8F",
                                  fontSize: isXxl ? "20px" : "18px",
                                }}
                              >
                                {this.state.top100
                                  ? userDetails[0]?.post_count
                                  : userDetails?.user
                                  ? userDetails?.user[0].post_count
                                  : 0}{" "}
                                Posts
                              </Col>
                              <Col
                                span={24}
                                style={{
                                  color: "#8F8F8F",
                                  fontSize: isXxl ? "20px" : "18px",
                                }}
                              >
                                {this.state.top100
                                  ? userDetails[0]?.top_hundred_count
                                  : userDetails?.user
                                  ? userDetails?.user[0].top_hundred_count
                                  : 0}{" "}
                                Top 100
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col xs={24} sm={24} md={16} lg={14} xl={14} xxl={12}>
                    <Row
                      justify="space-between"
                      style={{ borderBottom: "1px solid #8F8F8F" }}
                    >
                      <Col span={4}>
                        <ButtonForm
                          isDetails
                          onClick={() =>
                            this.setState(
                              {
                                allPost: true,
                                live: false,
                                top100: false,
                                removed: false,
                              },
                              () => {
                                this.props.userdetailsRequest({
                                  userId: this?.props?.location?.state?.id,
                                  type: "all",
                                });
                              }
                            )
                          }
                          style={{
                            borderBottom: this.state.allPost
                              ? "2px solid #009498"
                              : "none",
                            color: this.state.allPost ? "#009498" : "#8F8F8F",
                            fontSize: isXxl ? "18px" : "16px",
                          }}
                          span={12}
                        >
                          All posts
                        </ButtonForm>
                      </Col>
                      <Col span={4}>
                        <ButtonForm
                          isDetails
                          onClick={() =>
                            this.setState(
                              {
                                allPost: false,
                                live: true,
                                top100: false,
                                removed: false,
                              },
                              () => {
                                this.props.userdetailsRequest({
                                  userId: this?.props?.location?.state?.id,
                                  type: "live",
                                });
                              }
                            )
                          }
                          style={{
                            borderBottom: this.state.live
                              ? "2px solid #009498"
                              : "none",
                            color: this.state.live ? "#009498" : "#8F8F8F",
                            fontSize: isXxl ? "18px" : "16px",
                          }}
                          span={12}
                        >
                          Live posts
                        </ButtonForm>
                      </Col>
                      <Col span={4}>
                        <ButtonForm
                          isDetails
                          onClick={() =>
                            this.setState(
                              {
                                allPost: false,
                                live: false,
                                top100: true,
                                removed: false,
                              },
                              () => {
                                this.props.userdetailsRequest({
                                  userId: this?.props?.location?.state?.id,
                                  type: "top",
                                });
                              }
                            )
                          }
                          style={{
                            borderBottom: this.state.top100
                              ? "2px solid #009498"
                              : "none",
                            color: this.state.top100 ? "#009498" : "#8F8F8F",
                            fontSize: isXxl ? "18px" : "16px",
                          }}
                          span={12}
                        >
                          Top 100`s
                        </ButtonForm>
                      </Col>
                      <Col span={4}>
                        <ButtonForm
                          onClick={() =>
                            this.setState(
                              {
                                allPost: false,
                                live: false,
                                top100: false,
                                removed: true,
                              },
                              () => {
                                this.props.userdetailsRequest({
                                  userId: this?.props?.location?.state?.id,
                                  type: "removed",
                                });
                              }
                            )
                          }
                          style={{
                            borderBottom: this.state.removed
                              ? "2px solid #009498"
                              : "none",
                            color: this.state.removed ? "#009498" : "#8F8F8F",
                            fontSize: isXxl ? "18px" : "16px",
                          }}
                          span={12}
                          isDetails
                        >
                          Removed
                        </ButtonForm>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row gutter={[20, 20]}>
                  {!this.state.top100
                    ? userDetails?.post &&
                      userDetails?.post?.map((item) => {
                        return (
                          <Col
                            onClick={() => {
                              this.setState({ modal: true, id: item.id });
                              this.props?.postDetailsRequest(item.id);
                            }}
                            style={{
                              width:
                                isXxl || isXl
                                  ? "20%"
                                  : isLg
                                  ? "25%"
                                  : isMd
                                  ? "33.33%"
                                  : "100%",
                              cursor: "pointer",
                            }}
                          >
                            <UsersPost
                              file_url={item.file_url}
                              status={item.status}
                              media_type={item.media_type}
                              thumb_url={item.thumb_url}
                            />
                          </Col>
                        );
                      })
                    : userDetails[0] &&
                      userDetails[0]?.post?.map((item) => {
                        return (
                          <Col
                            onClick={() => {
                              this.setState({ modal: true, id: item.id });
                              this.props?.postDetailsRequest(item.id);
                            }}
                            style={{
                              width:
                                isXxl || isXl
                                  ? "20%"
                                  : isLg
                                  ? "25%"
                                  : isMd
                                  ? "33.33%"
                                  : "100%",
                            }}
                          >
                            <UsersPost
                              fire_url={item.fire_url}
                              status={item.status}
                              media_type={item.media_type}
                              thumb_url={item.thumb_url}
                            />
                          </Col>
                        );
                      })}
                </Row>
              </Col>
            </Row>
            {modal && (
              <UserDetailsModal
                modal={modal}
                onCancel={() => this.setState({ modal: false })}
                postDetails={postDetails}
              />
            )}
          </>
        )}
      </HeaderComponent>
    );
  }
}
const MediaWrapper = (props) => {
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <UsersDetails
      isXs={isXs}
      isSm={isSm}
      isMd={isMd}
      isLg={isLg}
      isXl={isXl}
      isXxl={isXxl}
      {...props}
    />
  );
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    userdetailsRequest: (params) => {
      dispatch(UserAction.UserDetailsRequest(params));
    },

    postDetailsRequest: (params) => {
      dispatch(ContentManagementAction.PostDetailsRequest(params));
    },

    updatePostRequest: (params) => {
      dispatch(ContentManagementAction.UpdatePostRequest(params));
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({
      userDetails: storeState.UserReducer.userdetails,
      postDetails: storeState.ContentManagementReducer.postDetails,
      loader: storeState.UserReducer.loader,
      postDetailsLoader: storeState.ContentManagementReducer.postDetailsLoader,
      updatePostLoader: storeState.ContentManagementReducer.updatePostLoader,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(MediaWrapper)
);
