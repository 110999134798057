import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
  NON_LIVE_REPORTS_REQUEST,
  NON_LIVE_REPORTS_SUCCESS,
  NON_LIVE_REPORTS_FAILURE,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAILURE,
  SUSPICIOUS_ACTIVITY_REQUEST,
  SUSPICIOUS_ACTIVITY_SUCCESS,
  SUSPICIOUS_ACTIVITY_FAILURE,
  GET_INTERACTION_REQUEST,
  GET_INTERACTION_SUCCESS,
  GET_INTERACTION_FAILURE,
} from "../../constants";
import moment from "moment";

const initialState = {
  countries: [],
  dashboardStats: [],
  no_of_post_in_topic: [],
  no_of_post_by_topic: [],
  no_of_reported_posts: [],
  reports: [],
  no_of_users: [],
  no_of_downloads: [],
  topics: [],
  country: [],
  post_selected: [],
  live_reported_hidden: {},
  ios_downloads: 0,
  android_downloads: 0,
  active_users: 0,
  inactive_users: 0,
  dashboardLoader: false,
  countriesLoader: false,
  entity_name: [],

  interactions: [],
  interactionsLoader: false,
  user_visits_monthly: [],
  post_score: [],
  nonLiveReportsLoader: false,
  suspiciousActivityLoader: false,
  max_reported_content: [],
  suspicious_favourites: [],
  suspicious_repletion: [],
  suspicious_topic: [],
  colorVisiblity: []
};

export default function ConfigAndSettingReducer(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      state = {
        ...state,
        dashboardLoader: true,
      };
      break;

    case DASHBOARD_SUCCESS: {
      if (action.payload) {
        const countryData = [];
        const postSelectedData = [];
        const topicsData = [];
        const reportsData = [];
        const downloadsData = [];
        const reportedPostData = [];

        const postInTopicData = [];
        const userVisitsData = [];
        const numberOfUsersData = [];

        Object.keys(action.payload.post_selected)?.forEach((item) => {
          postSelectedData.push({
            name: item,
            value: action.payload.post_selected[item],
          });
        });

        (action.payload?.country || []).forEach((item) => {
          countryData.push({
            name: item.country_name,
            value: item.post_count,
          });
        });

        (action.payload?.topic || []).forEach((item) => {
          topicsData.push({
            name: item.topic_name,
            value: item.post_count,
          });
        });
        const abd = [];

        const res = Object.keys(action.payload?.reports).map((el) => {
          const statusCount = action.payload?.reports[el].reduce(
            (prev, abc) => ({ ...prev, [abc.status]: abc.post_count }),
            {}
          );
          return { name: moment(el).format("DD-MM-YYYY"), ...statusCount };
        });

        const SuspendedPosts = Object.keys(action.payload?.reported_posts).map(
          (el) => {
            const statusCount = action.payload?.reported_posts[el].reduce(
              (prev, abc) => ({ ...prev, [abc.status]: abc.post_count }),
              {}
            );
            return { name: moment(el).format("DD-MM-YYYY"), ...statusCount };
          }
        );

        const postInNewArray = [];
        const downloadsInNewArray = [];
        const tryPostInArry = [];
        const trydownloadArray = [];
        const postByTopicData = [];
        const tryArr = [];

        Object.keys(action.payload?.no_of_post_by_topic).forEach((item) => {
          const obj = {};

          action.payload?.no_of_post_by_topic[item].forEach((v) => {
            obj["name"] = moment(item).format("DD-MM-YYYY");
            obj[v.topic_name] = v.post_count;
            tryArr.push(v.topic_name);
          });
          postByTopicData.push(obj);
        });

        const newArray = [...new Set(tryArr)];
        Object.keys(action.payload?.no_of_post_in_topic).forEach((item) => {
          const postInTopicObj = {};
          action.payload?.no_of_post_in_topic[item].forEach((v) => {
            postInTopicObj[v.status] = v["post_count"];
            postInTopicObj["name"] = v.topic_name;
            tryPostInArry.push(v.status);
          });
          postInNewArray.push(postInTopicObj);
        });

        Object.keys(action.payload?.no_of_downloads).forEach((item) => {
          const downloadsObj = {};

          action.payload?.no_of_downloads[item].forEach((v) => {
            downloadsObj[v.platform] = v["download_count"];
            downloadsObj["name"] = moment(v.created_at).format("DD-MM-YYYY");
            trydownloadArray.push(v.platform);
          });
          downloadsInNewArray.push(downloadsObj);
        });

        let active = 0;
        let in_active = 0;
        const abc = [];
        Object.keys(action.payload?.no_of_users).forEach((item) => {
          action.payload?.no_of_users[item].forEach((v) => {
            if (v.status == "active") {
              active = active + 1;
            } else {
              in_active = in_active + 1;
            }
          });
          abc.push({
            name: moment(item).format("DD-MM-YYYY"),
            active,
            in_active,
          });
        });

        state = {
          ...state,
          // dashboardStats: action.payload,
          no_of_post_in_topic: postInNewArray,

          // reports: reports,
          topics: topicsData,
          country: countryData,
          post_selected: postSelectedData,
          live_reported_hidden: action.payload.live_reported_hidden,
          ios_downloads: action.payload ? action.payload.ios : 0,
          android_downloads: action.payload ? action.payload.android : 0,
          active_users: action.payload.active_user,
          no_of_reported_posts: SuspendedPosts,
          no_of_post_by_topic: postByTopicData,
          no_of_downloads: downloadsInNewArray,
          reports: res,
          no_of_users: abc,
          entity_name: newArray,

          inactive_users: action.payload ? action.payload.inactive_user : 0,
          dashboardLoader: false,
        };
      }
    }

    case DASHBOARD_FAILURE:
      state = {
        ...state,
        dashboardLoader: false,
      };
      break;

    case COUNTRIES_REQUEST:
      state = {
        ...state,
        countriesLoader: true,
      };
      break;

    case COUNTRIES_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          countries: action.payload,
          countriesLoader: false,
        };
      }
    }

    case COUNTRIES_FAILURE:
      state = {
        ...state,
        countriesLoader: false,
      };
      break;

    case NON_LIVE_REPORTS_REQUEST:
      state = {
        ...state,
        NonLiveReportsLoader: true,
      };
      break;

    case NON_LIVE_REPORTS_SUCCESS: {
      if (action.payload) {
        // const interactionsArray = [];
        const userVisitsMonthlyArray = [];
        const postScoreArray = [];

        function sortByMonth(arr) {
          var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          arr.sort(function (a, b) {
            return months.indexOf(a.name) - months.indexOf(b.name);
          });
        }
        // Object.keys(action.payload.interactions).forEach((item) => {
        //   action.payload.interactions[item].forEach((v) => {
        //     interactionsArray.push({
        //       name: moment(item).format("MMM"),
        //       post_favourite_count: v.post_favourite_count,
        //       post_seen_count: v.post_seen_count,
        //       post_click_count: v.post_click_count,
        //       ads_seen_count: v.ads_seen_count,
        //     });
        //   });
        // });

        // Object.keys(action.payload.post_score).forEach((item) => {
        //   Object.keys(action.payload.post_score[item]).forEach((v) => {
        //     action.payload.post_score[item][v].forEach((c) => {
        //       postScoreArray.push({
        //         name: moment(c.updated_at.split(" ")[0]).format("MM-YYYY"),
        //         [v]: c.post_count,
        //       });
        //     });
        //   });
        // });
        Object.keys(action.payload.post_score).forEach((item) => {
          const sortedKeys = Object.keys(action.payload.post_score[item]).sort();
          for (const key of sortedKeys) {
            action.payload.post_score[item][key].forEach((c) => {
              postScoreArray.push({
                name: moment(c.updated_at.split(" ")[0]).format("MM-YYYY"),
                [key]: c.post_count,
              });
            });
          }
        });

        postScoreArray.reverse();

        let objARR = {};
        let monthArr = [
          "01-2022",
          "02-2022",
          "03-2022",
          "04-2022",
          "05-2022",
          "06-2022",
          "07-2022",
          "08-2022",
          "09-2022",
          "10-2022",
          "11-2022",
          "12-2022",
          "01-2023",
          "02-2023",
          "03-2023",
          "04-2023",
          "05-2023",
          "06-2023",
          "07-2023",
          "08-2023",
          "09-2023",
          "10-2023",
          "11-2023",
          "12-2023",
          "01-2024",
          "02-2024",
          "03-2024",
          "04-2024",
          "05-2024",
          "06-2024",
          "07-2024",
          "08-2024",
          "09-2024",
          "10-2024",
          "11-2024",
          "12-2024",
        ];
        postScoreArray.forEach((item) => {
          objARR[item.name] = { ...item, ...objARR[item.name] };
        });
        let combineArr = [];
        let testArr = monthArr.forEach((item) => {
          if (objARR[item]) {
            combineArr.push(objARR[item]);
          }
        });
        // const color = {
        //   "<10": '#f5ba3c',
        //   "10-49": "#209498",
        //   "50-99": "#e06030",
        //   "100+": "blue",
        // };
        // console.log("pakisan==>", action.payload.user_visits_monthly);
        // var arr=[]
        // const abc=Object.keys(action.payload.user_visits_monthly).forEach((curr,index)=>{
        //   if(index==0){
        //     const a=    Object.keys( action.payload.user_visits_monthly[curr]).reduce((acc,item)=>{
        //       acc.push( {color:color[item],range:item.toString()})
        //       return acc
        //     },[])
        //     arr=a

        //   }
        //   // console.log('eme==>',)

        //   // return {}

        // })
        // console.log('dadad==>',arr)

        console.log("ACTION PAYLOAD: ", action.payload.user_visits_monthly)

        const formattedData = Object.keys(
          action.payload.user_visits_monthly
        ).map((el) => {
          const reqObject = Object.keys(
            action.payload.user_visits_monthly[el]
          ).reduce((prev, al) => {
            if (al === "") {
              return prev;
            }else if (al == ["0"]) {
              return {
                ...prev,
                [al]:
                  action.payload.user_visits_monthly[el][al].users +
                  action.payload.user_visits_monthly[el][al].in_active_users,

                name: action.payload.user_visits_monthly[el][al].months
                  ? moment(
                    action.payload.user_visits_monthly[el][al].months
                  ).format("MM-YYYY")
                  : action.payload.user_visits_monthly[el][al].days
                    ? moment(
                      action.payload.user_visits_monthly[el][al].days
                    ).format("DD-MM-YYYY")
                    : null,
              };
            } else if (al !== ["0"]) {

              return {
                ...prev,
                [al]: action.payload.user_visits_monthly[el][al].users,
                ["a 0"]:
                  action.payload.user_visits_monthly[el][al].in_active_users,
                name: action.payload.user_visits_monthly[el][al].months
                  ? moment(
                    action.payload.user_visits_monthly[el][al].months
                  ).format("MM-YYYY")
                  : action.payload.user_visits_monthly[el][al].days
                    ? moment(
                      action.payload.user_visits_monthly[el][al].days
                    ).format("DD-MM-YYYY")
                    : null,
              };
            }
            return {
              ...prev,
              [al]: action.payload.user_visits_monthly[el][al].users,
              name: action.payload.user_visits_monthly[el][al].months
                ? moment(
                  action.payload.user_visits_monthly[el][al].months
                ).format("MM-YYYY")
                : action.payload.user_visits_monthly[el][al].days
                  ? moment(
                    action.payload.user_visits_monthly[el][al].days
                  ).format("DD-MM-YYYY")
                  : null,
            };
          }, {});

          return {
            name: el,
            ...reqObject,
          };
        });
        console.log("FORMATTED DATA: ", formattedData)
        state = {
          ...state,
          post_score: combineArr,
          // interactions: interactionsArray,
          user_visits_monthly: formattedData,
          NonLiveReportsLoader: false,
          // colorVisiblity:[{color:"#8f8f8f",range:'0'} ,...arr]
        };
      }
    }

    case NON_LIVE_REPORTS_FAILURE:
      state = {
        ...state,
        NonLiveReportsLoader: false,
      };
      break;

    case SUSPICIOUS_ACTIVITY_REQUEST:
      state = {
        ...state,
        suspiciousActivityLoader: true,
      };
      break;
    case SUSPICIOUS_ACTIVITY_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          suspiciousActivityLoader: false,
          max_reported_content: action.payload
            ? action.payload.max_reported_content
            : [],
          suspicious_favourites: action.payload
            ? action.payload.suspicious_favourites
            : [],
          suspicious_repletion: action.payload
            ? action.payload.suspicious_repletion
            : [],
          suspicious_topic: action.payload
            ? action.payload.suspicious_topic
            : [],
        };
      }
    }

    case SUSPICIOUS_ACTIVITY_FAILURE:
      state = {
        ...state,
        suspiciousActivityLoader: false,
      };
      break;
    // ==================================================
    case GET_INTERACTION_REQUEST:
      state = {
        ...state,
        interactionsLoader: true,
      };
      break;

    case GET_INTERACTION_SUCCESS: {
      if (action.payload) {
        console.log('InterectionReducer', action.payload)
        const interactionsArray = [];
        // const userVisitsMonthlyArray = [];
        // const postScoreArray = [];

        // function sortByMonth(arr) {
        //   var months = [
        //     "Jan",
        //     "Feb",
        //     "Mar",
        //     "Apr",
        //     "May",
        //     "Jun",
        //     "Jul",
        //     "Aug",
        //     "Sep",
        //     "Oct",
        //     "Nov",
        //     "Dec",
        //   ];
        //   arr.sort(function (a, b) {
        //     return months.indexOf(a.name) - months.indexOf(b.name);
        //   });
        // }
        Object.keys(action.payload.interactions).forEach((item) => {
          action.payload.interactions[item].forEach((v) => {
            interactionsArray.push({
              name: moment(item).format("MMM"),
              post_favourite_count: v.post_favourite_count,
              post_seen_count: v.post_seen_count,
              post_click_count: v.post_click_count,
              ads_seen_count: v.ads_seen_count,
            });
          });
        });

        // Object.keys(action.payload.post_score).forEach((item) => {
        //   Object.keys(action.payload.post_score[item]).forEach((v) => {
        //     action.payload.post_score[item][v].forEach((c) => {
        //       postScoreArray.push({
        //         name: moment(c.updated_at.split(" ")[0]).format("MMM"),
        //         [v]: c.post_count,
        //       });
        //     });
        //   });
        // });

        // let objARR = {};
        // let monthArr = [
        //   "Jan",
        //   "Feb",
        //   "Mar",
        //   "Apr",
        //   "May",
        //   "Jun",
        //   "Jul",
        //   "Aug",
        //   "Sep",
        //   "Oct",
        //   "Nov",
        //   "Dec",
        // ];
        // postScoreArray.forEach((item) => {
        //   objARR[item.name] = { ...item, ...objARR[item.name] };
        // });
        // let combineArr = [];
        // let testArr = monthArr.forEach((item) => {
        //   if (objARR[item]) {
        //     combineArr.push(objARR[item]);
        //   }
        // });

        // const formattedData = Object.keys(
        //   action.payload.user_visits_monthly
        // ).map((el) => {
        //   const reqObject = Object.keys(
        //     action.payload.user_visits_monthly[el]
        //   ).reduce((prev, al) => {
        //     if (al == ["0"]) {
        //       return {
        //         ...prev,
        //         [al]:
        //           action.payload.user_visits_monthly[el][al].users +
        //           action.payload.user_visits_monthly[el][al].in_active_users,

        //         name: action.payload.user_visits_monthly[el][al].months
        //           ? moment(
        //               action.payload.user_visits_monthly[el][al].months
        //             ).format("MM-YYYY")
        //           : action.payload.user_visits_monthly[el][al].days
        //           ? moment(
        //               action.payload.user_visits_monthly[el][al].days
        //             ).format("DD-MM-YYYY")
        //           : null,
        //       };
        //     } else if (al !== ["0"]) {
        //       return {
        //         ...prev,
        //         [al]: action.payload.user_visits_monthly[el][al].users,
        //         ["0"]:
        //           action.payload.user_visits_monthly[el][al].in_active_users,
        //         name: action.payload.user_visits_monthly[el][al].months
        //           ? moment(
        //               action.payload.user_visits_monthly[el][al].months
        //             ).format("MM-YYYY")
        //           : action.payload.user_visits_monthly[el][al].days
        //           ? moment(
        //               action.payload.user_visits_monthly[el][al].days
        //             ).format("DD-MM-YYYY")
        //           : null,
        //       };
        //     }
        //     return {
        //       ...prev,
        //       [al]: action.payload.user_visits_monthly[el][al].users,
        //       name: action.payload.user_visits_monthly[el][al].months
        //         ? moment(
        //             action.payload.user_visits_monthly[el][al].months
        //           ).format("MM-YYYY")
        //         : action.payload.user_visits_monthly[el][al].days
        //         ? moment(
        //             action.payload.user_visits_monthly[el][al].days
        //           ).format("DD-MM-YYYY")
        //         : null,
        //     };
        //   }, {});

        //   return {
        //     name: el,
        //     ...reqObject,
        //   };
        // });

        state = {
          ...state,
          // post_score: combineArr,
          interactions: interactionsArray,
          // user_visits_monthly: formattedData,
          interactionsLoader: false,
        };
      }
    }

    case GET_INTERACTION_FAILURE:
      state = {
        ...state,
        interactionsLoader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
