import React from "react";
import { Row, Col } from "antd";
import InputWrapper from "../../components/input";
import TextAndInput from "./TextAndInput";
import { useMedia } from "use-media";
const PostRanking = (props) => {
  const { data, handleNumber, errors } = props;
  const {
    followers,
    repeat_limit,
    repeat_weight,
    new_age,
    age_gt_7,
    age_gt_29,
    age_gt_100,
    post_retention_period,
    new_post_days,
  } = data;
  const {
    followers_Error,
    repeat_limitError,
    repeat_weightError,
    new_ageError,
    age_gt_7Error,
    age_gt_29Error,
    age_gt_100Error,
  } = errors;
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <Row
      style={{
        borderLeft: "8px solid #E06030",
        borderRadius: "9px",
        padding: "0rem 1rem",
      }}
    >
      <Col
        xl={8}
        xxl={6}
        style={{ fontSize: isXxl ? "28px" : isXl ? "20px" : "24px" }}
        align="left"
      >
        <h1>Post Ranking</h1>
      </Col>
      <Col span={24}>
        <Row justify="left" gutter={[0, 10]}>
          <Col span={24}>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col xs={24} sm={24} lg={24} xl={7} xxl={7}>
                <TextAndInput
                  isPostRanking
                  title="Followers"
                  placeholder={followers}
                  name="followers"
                  value={followers ? followers : 0}
                  handleNumber={handleNumber}
                  errors={followers_Error}
                />
              </Col>

              <Col xs={24} sm={24} lg={24} xl={7} xxl={7}>
                <TextAndInput
                  isPostRanking
                  title="Repeat limit"
                  placeholder={repeat_limit}
                  name="repeat_limit"
                  value={repeat_limit ? repeat_limit : 0}
                  handleNumber={handleNumber}
                  errors={repeat_limitError}
                />
              </Col>

              <Col xs={24} sm={24} lg={24} xl={7} xxl={7}>
                <TextAndInput
                  isPostRanking
                  title="Repeat Weight"
                  placeholder={repeat_weight}
                  name="repeat_weight"
                  value={repeat_weight ? repeat_weight : 0}
                  handleNumber={handleNumber}
                  errors={repeat_weightError}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col xs={24} sm={24} lg={24} xl={7} xxl={7}>
                <TextAndInput
                  isPostRanking
                  title={`New <= Y Days`}
                  placeholder={new_age}
                  name="new_age"
                  value={new_age ? new_age : 0}
                  handleNumber={handleNumber}
                  errors={new_ageError}
                />
              </Col>
              <Col xs={24} sm={24} lg={24} xl={7} xxl={7}>
                <TextAndInput
                  isPostRanking
                  title="Age Y-(X-1) Days"
                  placeholder={age_gt_7}
                  name="age_gt_7"
                  value={age_gt_7 ? age_gt_7 : 0}
                  handleNumber={handleNumber}
                  errors={age_gt_7Error}
                />
              </Col>
              <Col xs={24} sm={24} lg={24} xl={7} xxl={7}>
                <TextAndInput
                  isPostRanking
                  title="Age X-100 Days"
                  placeholder={age_gt_29}
                  name="age_gt_29"
                  value={age_gt_29 ? age_gt_29 : 0}
                  handleNumber={handleNumber}
                  errors={age_gt_29Error}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={24} lg={24} xl={7} xxl={7}>
                <TextAndInput
                  isPostRanking
                  title="Age > 100 Days"
                  placeholder={age_gt_100}
                  name="age_gt_100"
                  value={age_gt_100 ? age_gt_100 : 0}
                  handleNumber={handleNumber}
                  errors={age_gt_100Error}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PostRanking;
