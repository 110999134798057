import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "antd";
import Info from "../../components/message";
import addAdIcon from "../../assets/images/addAdIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import adTopic from "../../assets/images/adTopic.svg";
import adUrl from "../../assets/images/adUrl.svg";
import { useMedia } from "use-media";
import adGender from "../../assets/images/adGender.svg";
import adUpdateImage from "../../assets/images/adUpdateImage.svg";
import { AD_GENDER_OPTIONS } from "../../config/variables";
import adCountry from "../../assets/images/adCountry.svg";
import CustomeAdsManagementAction from "../../store/actions/appactions/CustomAdsManagementAction";
import DashboardAction from "../../store/actions/appactions/DashboardAction";
import InputWrapper from "../../components/input";
import Image from "../../components/image/index";
import adModalClose from "../../assets/images/adModalClose.png";
import { ButtonForm } from "../../components";
import Select from "react-select";
import moment from "moment";

const EditPremiumModal = (props) => {
  const { customStyles, offset, onCancel, data, modal, isPremium, type } =
    props;
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    gender: [],
    topic_ids: [],
    countries: [],
    primary_file: "",
    secondary_file: "",
    primary_url: "",
    secondary_url: "",
    isPublish: false,
    isDraft: false,
    advertisement_category: "",
    save_as: "",
    isUpdateImg1: false,
    img1: "",
    isUpdateImg: false,
    img: "",
    isPremiumUpdate: false,
    isBasicUpdate: false,
  });
  const {
    gender,
    topic_ids,
    countries,
    primary_file,
    secondary_file,
    primary_url,
    secondary_url,
    advertisement_category,
    save_as,
    isPublish,
    isDraft,
    isUpdateImg1,
    img1,
    isUpdateImg,
    img,
    isPremiumUpdate,
    isBasicUpdate,
  } = values;

  useEffect(() => {
    setValues({
      ...values,
      primary_url: data?.custom_add_primary?.url,
      secondary_url: data?.custom_add_secondary?.url,
      countries: data?.custom_add_primary?.custom_ads_countries.map((item) => ({
        label: item.name,
        value: item.id,
      })),

      gender: data?.custom_add_primary?.custom_ads_gender.map((item) => ({
        label: item.gender,
        value: item.gender == "All" || item.gender == "all" ? 0 : item.id,
      })),
      topic_ids: data?.custom_add_primary?.custom_ads_topics.map((item) => ({
        label: item.name,
        value: item.id,
      })),
      primary_file: data?.custom_add_primary?.thumb_url,
      secondary_file: data?.custom_add_secondary?.thumb_url,
      save_as: data?.custom_add_primary?.status,

      isUpdateImg: true,
      isUpdateImg1: true,
      img: data?.custom_add_primary?.thumb_url,
      img1: data?.custom_add_secondary?.thumb_url,
    });
  }, [data]);

  const [errors, setErrors] = useState({
    genderError: "",
    topicIdsError: "",
    countriesError: "",
    primaryFileError: "",
    secondaryFileError: "",
    primaryUrlError: "",
    secondaryUrlError: "",
  });

  const {
    genderError,
    topicIdsError,
    countriesError,
    primaryFileError,
    secondaryFileError,
    primaryUrlError,
    secondaryUrlError,
  } = errors;

  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });

  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setValues({
        ...values,
        isUpdateImg: true,
        img: reader.result,
        primary_file: file,
        isBasicUpdate: true,
      });
    };
    reader.onerror = function (error) {};
  }
  function getBase64Premium(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setValues({
        ...values,
        isUpdateImg1: true,
        img1: reader.result,
        secondary_file: file,
        isPremiumUpdate: true,
      });
    };
    reader.onerror = function (error) {};
  }

  function handleUpload(val) {
    const fileSize = val.target.files[0].size / 1024 / 1024;
    const fileType = val.target.files[0].type;
    if (fileSize > 15) {
      Info("error", "Image should be less than 15mb!");
      return;
    }
    if (fileType == "image/gif") {
      Info("error", "Please upload image only!");
      return;
    }
    if (
      fileType == "video/mp4" ||
      fileType == "video/quicktime" ||
      fileType == "video/x-matroska"
    ) {
      Info("error", "Please upload image only");
    } else {
      getBase64(val.target.files[0]);
      setErrors({ ...errors, primaryFileError: "" });
    }
  }
  function handlePremiumUpload(val) {
    const fileSize = val.target.files[0].size / 1024 / 1024;
    const fileType = val.target.files[0].type;
    if (fileSize > 15) {
      Info("error", "Image should be less than 15mb!");
      return;
    }
    if (fileType == "image/gif") {
      Info("error", "Please upload image only!");
      return;
    }
    if (
      fileType == "video/mp4" ||
      fileType == "video/quicktime" ||
      fileType == "video/x-matroska"
    ) {
      Info("error", "Please upload image only");
    } else {
      getBase64Premium(val.target.files[0]);
      setErrors({ ...errors, secondaryFileError: "" });
    }
  }
  function onHandlePublish(obj) {
    const {
      gender,
      countries,
      topic_ids,
      primary_url,
      primary_file,
      secondary_url,
      secondary_file,
    } = obj;
    let objError = { ...errors };
    if (
      !gender.length ||
      !topic_ids.length ||
      !countries.length ||
      primary_url == "" ||
      secondary_url == "" ||
      primary_file == "" ||
      secondary_file == ""
    ) {
      if (!gender?.length) {
        objError = {
          ...objError,
          genderError: "Please Select the gender!",
        };
      }
      if (!countries?.length) {
        objError = {
          ...objError,
          countriesError: "Please Select the country!",
        };
      }
      if (!topic_ids?.length) {
        objError = {
          ...objError,
          topicIdsError: "Please Select the topic!",
        };
      }
      if (primary_url == "") {
        objError = {
          ...objError,
          primaryUrlError: "Please Enter primary the URL!",
        };
      }

      if (secondary_url == "") {
        objError = {
          ...objError,
          secondaryUrlError: "Please Enter the Secondary URL!",
        };
      }

      if (primary_file == "") {
        objError = {
          ...objError,
          primaryFileError: "Please attach the Primary file!",
        };
      }

      if (secondary_file == "") {
        objError = {
          ...objError,
          secondaryFileError: "Please attach the Secondary file!",
        };
      }

      setErrors({
        ...objError,
      });
    } else {
      dispatch(
        CustomeAdsManagementAction.UpdateCustomAdRequest(
          isBasicUpdate && isPremiumUpdate
            ? {
                id: data?.mapper_id,
                countries,
                gender,
                topic_ids,
                primary_file,
                secondary_file,
                primary_url,
                secondary_url,
                type: "image",
                action: "clickable",
                save_as,
                current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
                advertisement_category: "premium",
              }
            : isBasicUpdate
            ? {
                id: data?.mapper_id,
                countries,
                gender,
                topic_ids,
                primary_file,
                primary_url,
                secondary_url,
                type: "image",
                action: "clickable",
                save_as,
                current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
                advertisement_category: "premium",
              }
            : isPremiumUpdate
            ? {
                id: data?.mapper_id,
                countries,
                gender,
                topic_ids,
                secondary_file,
                primary_url,
                secondary_url,
                type: "image",
                action: "clickable",
                save_as,
                current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
                advertisement_category: "premium",
              }
            : {
                id: data?.mapper_id,
                countries,
                gender,
                topic_ids,

                primary_url,
                secondary_url,
                type: "image",
                action: "clickable",
                save_as,
                current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
                advertisement_category: "premium",
              },
          () => {
            setValues({
              ...values,
              isPremiumUpdate: false,
              isBasicUpdate: false,
            });
            onCancel();
            dispatch(
              CustomeAdsManagementAction.CustomAdsRequest({
                filter: type,
                limit: 10,
                offset: offset,
              })
            );
          }
        )
      );
    }
  }
  var refImage;
  var refImage1;
  const updateAdLoader = useSelector(
    (state) => state.CustomAdsManagementReducer.updateAdLoader
  );

  const countriesFromReducer = useSelector(
    (state) => state?.DashboardReducer?.countries
  );
  var countriesOptions =
    countriesFromReducer &&
    countriesFromReducer
      ?.filter((item) => countries?.every((v) => v.value !== item.value))
      ?.map((item) => ({ value: item.id, label: item.name }));

  const topics = useSelector((state) => state?.TopicManagementReducer?.topics);
  var topicOptions =
    topics &&
    topics
      ?.filter((item) => topic_ids?.every((v) => v.value !== item.value))
      ?.map((item) => ({ value: item.id, label: item.name }));
  var genderOptions =
    AD_GENDER_OPTIONS &&
    AD_GENDER_OPTIONS.filter((item) => {
      return gender?.every((v) => v.label !== item.value);
    }).map((item) => ({ value: item.value, label: item.label }));
  return (
    <Modal
      visible={modal}
      closable={false}
      onCancel={onCancel}
      centered={true}
      className="ad-modal"
      footer={null}
      bodyStyle={{
        height: isXxl ? "580px" : "490px",
        background: "#009498",
        alignItems: "center",
        width: isXxl ? "900px" : "700px",
      }}
    >
      <Row justify="center">
        <Col
          span={24}
          style={{
            height: isXxl ? "100px" : "70px",
          }}
        >
          <Row
            justify="center"
            align="middle"
            gutter={[0, 20]}
            style={{ height: "100%", position: "relative" }}
          >
            <Col
              span={14}
              align="middle"
              style={{
                color: "#FFFFFF",
                fontSize: isXxl ? "28px" : "20px",
                padding: "4px",
                fontWeight: "600",
              }}
            >
              <Row justify="end">
                <Col span={24}>Edit Ad</Col>
              </Row>
            </Col>
            <Col style={{ position: "absolute", top: -20, right: -20 }}>
              <Row justify="end">
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: isXxl ? "64px" : "50px",
                    height: isXxl ? "64px" : "50px",
                    borderRadius: "50%",
                    backgroundColor: "#009498",
                    border: "1px solid white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onCancel();
                  }}
                >
                  <img src={adModalClose} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={22} style={{ background: "white" }}></Col>
        <Col
          span={22}
          style={{
            height: isXxl ? "450px" : "400px",
          }}
        >
          <Row gutter={[0, 30]} align="middle" style={{ height: "100%" }}>
            <Col
              span={24}
              style={{
                height: "270px",
                height: "100%",
              }}
            >
              <Row
                justify="space-between"
                style={{ height: "100%" }}
                align="middle"
              >
                <Col span={8}>
                  <Row gutter={[0, 20]} align="middle">
                    <Col
                      span={24}
                      style={{
                        height: isXxl ? "180px" : "120px",
                        cursor: "pointer",
                        border: "2px #00767A solid ",
                      }}
                      onClick={() => refImage.click()}
                    >
                      <Row
                        justify="center"
                        align="middle"
                        style={{ height: "100%" }}
                      >
                        {isBasicUpdate ? (
                          <Col
                            style={{ height: isUpdateImg ? "100%" : "" }}
                            span={isUpdateImg ? 24 : 8}
                          >
                            <Image
                              src={img}
                              style={{
                                height: isUpdateImg1 && "100%",
                                width: isUpdateImg1 && "100%",
                              }}
                            />
                          </Col>
                        ) : (
                          <>
                            <Col
                              style={{ height: isUpdateImg && "100%" }}
                              span={isUpdateImg ? 24 : 8}
                            >
                              <Image
                                src={img}
                                style={{
                                  height: isUpdateImg1 && "100%",
                                  width: isUpdateImg1 && "100%",
                                  filter: "blur(3px)",
                                }}
                              />
                            </Col>
                            <Col style={{ position: "absolute" }}>
                              <Image src={adUpdateImage} />
                            </Col>
                          </>
                        )}
                      </Row>
                      <div
                        style={{
                          color: "black",
                          alignItems: "center",
                        }}
                      >
                        {primaryFileError}
                      </div>
                    </Col>
                    <input
                      type="file"
                      accept="image/x-png,image/gif,image/jpeg"
                      onChange={(e) => handleUpload(e)}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      style={{ display: "none" }}
                      ref={(ref) => (refImage = ref)}
                    />

                    <Col
                      span={24}
                      style={{
                        height: isXxl ? "180px" : "120px",
                        cursor: "pointer",
                        opacity: isUpdateImg1 ? "100%" : "20%",
                        border: "2px #00767A solid ",
                      }}
                      onClick={() => refImage1.click()}
                    >
                      <Row
                        justify="center"
                        align="middle"
                        style={{ height: "100%" }}
                      >
                        {isPremiumUpdate ? (
                          <Col
                            style={{ height: isUpdateImg1 ? "100%" : "" }}
                            span={isUpdateImg1 ? 24 : 8}
                          >
                            <Image
                              src={isUpdateImg1 ? img1 : addAdIcon}
                              style={{
                                height: isUpdateImg1 ? "100%" : "",
                                width: isUpdateImg1 ? "100%" : "",
                              }}
                            />
                          </Col>
                        ) : (
                          <>
                            <Col
                              style={{
                                height: isUpdateImg1 && "100%",
                                position: "relative",
                              }}
                              span={isUpdateImg1 ? 24 : 8}
                            >
                              <Image
                                src={isUpdateImg1 ? img1 : addAdIcon}
                                style={{
                                  height: isUpdateImg1 && "100%",
                                  width: isUpdateImg1 && "100%",
                                  filter: "blur(3px)",
                                }}
                              />
                            </Col>
                            <Col style={{ position: "absolute" }}>
                              <Image src={adUpdateImage} />
                            </Col>
                          </>
                        )}
                      </Row>
                      <div
                        style={{
                          color: "black",
                          alignItems: "center",
                        }}
                      >
                        {secondaryFileError}
                      </div>
                    </Col>
                    <input
                      type="file"
                      accept="image/x-png,image/gif,image/jpeg"
                      onChange={(e) => handlePremiumUpload(e)}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      style={{ display: "none" }}
                      ref={(ref) => (refImage1 = ref)}
                    />
                  </Row>
                </Col>
                <Col span={15}>
                  <Row gutter={[10, 20]}>
                    <Col span={12}>
                      <Select
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 40,
                            minHeight: 20,
                            overflow: "auto",
                            background: "#5bbbbd",
                            borderRadius: "15px",
                            border: "none",
                          }),
                        }}
                        options={
                          gender?.length
                            ? gender.find(
                                (value) =>
                                  value.label == "All" || value.label == "all"
                              )
                              ? []
                              : genderOptions
                            : [{ label: "All", value: "0" }, ...genderOptions]
                        }
                        value={gender}
                        style={customStyles}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        placeholder={
                          <Row gutter={[5, 0]}>
                            <Col>
                              <Image src={adGender} />
                            </Col>
                            <Col
                              style={{
                                color: "white",
                                fontSize: isXxl ? "18px" : "16px",
                              }}
                            >
                              Gender
                            </Col>
                          </Row>
                        }
                        defaultValue={[]}
                        onChange={(e) => {
                          const gendersToShow = e.map((item) => {
                            return { label: item.label, value: item.value };
                          });
                          setValues({ ...values, gender: gendersToShow });
                          setErrors({ ...errors, genderError: "" });
                        }}
                        isMulti
                      />
                      <span
                        style={{
                          color: "black",
                          alignItems: "center",
                        }}
                      >
                        {genderError}
                      </span>
                    </Col>
                    <Col span={12}>
                      <Select
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 40,
                            minHeight: 20,
                            overflow: "auto",
                            background: "#5bbbbd",
                            borderRadius: "15px",
                            border: "none",
                          }),
                        }}
                        options={
                          countries?.length
                            ? countries.find(
                                (value) =>
                                  value.label == "All" || value.label == "all"
                              )
                              ? []
                              : countriesOptions
                            : [
                                { label: "All", value: "0" },
                                ...countriesOptions,
                              ]
                        }
                        value={countries}
                        style={customStyles}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        // isLoading={countriesLoader}
                        placeholder={
                          <Row gutter={[5, 0]}>
                            <Col>
                              <Image src={adCountry} />
                            </Col>
                            <Col
                              style={{
                                color: "white",
                                fontSize: isXxl ? "18px" : "16px",
                              }}
                            >
                              Country
                            </Col>
                          </Row>
                        }
                        defaultValue={[]}
                        isMulti
                        onKeyDown={(e) => DashboardAction.CountriesRequest()}
                        onChange={(e) => {
                          const countryToShow = e.map((item) => {
                            return { label: item.label, value: item.value };
                          });

                          setValues({ ...values, countries: countryToShow });
                          setErrors({ ...errors, countriesError: "" });
                        }}
                      />
                      <span
                        style={{
                          color: "black",
                          alignItems: "center",
                        }}
                      >
                        {countriesError}
                      </span>
                    </Col>
                    <Col span={24}>
                      <Select
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 40,
                            minHeight: 20,
                            overflow: "auto",
                            background: "#5bbbbd",
                            borderRadius: "15px",
                            border: "none",
                          }),
                        }}
                        options={
                          topic_ids?.length
                            ? topic_ids.find(
                                (value) =>
                                  value.label == "All" || value.label == "all"
                              )
                              ? []
                              : topicOptions
                            : [{ label: "All", value: "0" }, ...topicOptions]
                        }
                        value={topic_ids}
                        style={customStyles}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        // isLoading={countriesLoader}
                        placeholder={
                          <Row gutter={[5, 0]}>
                            <Col>
                              <Image src={adTopic} />
                            </Col>
                            <Col
                              style={{
                                color: "white",
                                fontSize: isXxl ? "18px" : "16px",
                              }}
                            >
                              Topics
                            </Col>
                          </Row>
                        }
                        defaultValue={[]}
                        isMulti
                        onChange={(e) => {
                          const topicsToShow = e.map((item) => {
                            return { label: item.label, value: item.value };
                          });
                          setValues({ ...values, topic_ids: topicsToShow });
                          setErrors({ ...errors, topicIdsError: "" });
                        }}
                      />
                      <span
                        style={{
                          color: "black",
                          alignItems: "center",
                        }}
                      >
                        {topicIdsError}
                      </span>
                    </Col>
                    <Col span={24}>
                      <InputWrapper
                        prefix={<Image src={adUrl} />}
                        className="url-input"
                        AdInput
                        onChange={(e) => {
                          setValues({
                            ...values,
                            primary_url: e.target.value.trim(),
                          });

                          setErrors({
                            ...errors,
                            primaryUrlError: "",
                          });
                        }}
                        placeholder="Url"
                        style={{
                          height: isXxl ? "65px" : "45px",
                          borderRadius: "10px",
                        }}
                        value={primary_url}
                      />
                      <span
                        style={{
                          color: "black",
                          alignItems: "center",
                        }}
                      >
                        {primaryUrlError}
                      </span>
                    </Col>

                    <Col span={24}>
                      <InputWrapper
                        prefix={<Image src={adUrl} />}
                        className="url-input"
                        AdInput
                        onChange={(e) => {
                          setValues({
                            ...values,
                            secondary_url: e.target.value.trim(),
                          });

                          setErrors({
                            ...errors,
                            secondaryUrlError: "",
                          });
                        }}
                        placeholder="Url2"
                        value={secondary_url}
                        style={{
                          height: isXxl ? "65px" : "45px",
                          borderRadius: "10px",
                        }}
                      />
                      <span
                        style={{
                          color: "black",
                          alignItems: "center",
                        }}
                      >
                        {secondaryUrlError}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="center" gutter={[10, 0]}>
                    <Col span={8}>
                      <ButtonForm
                        isModal
                        loading={isPublish && updateAdLoader}
                        onClick={() => {
                          onHandlePublish({ ...values });
                          setValues({
                            ...values,
                            isDraft: false,
                            isPublish: true,
                          });
                        }}
                        style={{
                          backgroundColor: "#E06030",
                          color: "white",
                          fontSize: isXxl ? "18px" : "14px",
                          height: isXxl ? "56px" : "40px",
                        }}
                      >
                        Save
                      </ButtonForm>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditPremiumModal;
