import { ContentManagementAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  CONTENTS_REQUEST_ENDPOINT,
  UPDATE_CONTENT_REQUEST_ENDPOINT,
  POST_DETAILS_ENDPOINT,
  UPDATE_POST_ENDPOINT,
} from "../../../config/variables";
import { put } from "redux-saga/effects";
import moment from "moment";
export default class ConfigAndSettingMiddleware {
  static *ContentsRequest(action) {
    const { params } = action;

    const qureyParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(
        `${CONTENTS_REQUEST_ENDPOINT}?${qureyParams}`
      );

      if (res.status == 200) {
        yield put(
          ContentManagementAction.ContentsSuccess(res.data.data, res.data.count)
        );
      } else {
        yield put(ContentManagementAction.ContentsFailure());
      }
    } catch (err) {
      yield put(ContentManagementAction.ContentsFailure());
    }
  }

  static *PostDetailsRequest(action) {
    const { params } = action;
    const qureyParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(`${POST_DETAILS_ENDPOINT}/${qureyParams}`);

      if (res.status == 200) {
        yield put(ContentManagementAction.PostDetailsSuccess(res.data.data));
      } else {
        yield put(ContentManagementAction.PostDetailsFailure());
      }
    } catch (err) {
      yield put(ContentManagementAction.PostDetailsFailure());
    }
  }

  static *UpdateContent(action) {
    let { params, cb } = action;

    const qureyParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Put(`${UPDATE_CONTENT_REQUEST_ENDPOINT}`, {
        ...params,
      });

      if (res.status == 200) {
        yield put(
          ContentManagementAction.UpdateContentSuccess(res.data.success)
        );
        Info("success", res?.data?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error.message);
        yield put(ContentManagementAction.UpdateContentFailure());
      }
    } catch (err) {
      yield put(ContentManagementAction.UpdateContentFailure());
    }
  }

  static *UpdatePost(action) {
    let { params, cb } = action;
    try {
      let res = yield ApiCaller.Put(`${UPDATE_POST_ENDPOINT}`, {
        ...params,
      });

      if (res.status == 200) {
        yield put(ContentManagementAction.UpdatePostSuccess(res.data.success));
        Info("success", res?.data?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error.message);
        yield put(ContentManagementAction.UpdatePostFailure());
      }
    } catch (err) {
      yield put(ContentManagementAction.UpdatePostFailure());
    }
  }
}
