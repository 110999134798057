import React from "react";
import { Row, Col } from "antd";
import InputWrapper from "../../components/input";
import { useMedia } from "use-media";
import TextAndInput from "./TextAndInput";
const ReportedPostBlockParameters = (props) => {
  const { data, handleNumber, errors } = props;
  const { percentage_of_views, min_number_of_views } = data;
  const { percentage_of_viewError, min_number_of_viewsError } = errors;
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <Row
      style={{
        borderLeft: "8px solid #E06030",
        borderRadius: "9px",
        padding: "0rem 1rem",
      }}
    >
      <Col
        xl={22}
        xxl={21}
        style={{ fontSize: isXxl ? "28px" : isXl ? "20px" : "24px" }}
        align="left"
      >
        <h1>Reported Post Block Parameters</h1>
      </Col>
      <Col span={24}>
        <Row justify="left" gutter={[0, 10]}>
          <Col md={24} xl={24} xxl={24}>
            <TextAndInput
              isReported
              title="Percentage of Views That Results In Post Being Reported"
              placeholder={percentage_of_views || 0}
              name="percentage_of_views"
              value={percentage_of_views || 0}
              handleNumber={handleNumber}
              errors={percentage_of_viewError}
            />
          </Col>
          <Col md={24} xl={24} xxl={24}>
            <TextAndInput
              isReported
              title="Min. Number of Views Before A Post is Blocked"
              placeholder={min_number_of_views || 0}
              name="min_number_of_views"
              value={min_number_of_views || 0}
              handleNumber={handleNumber}
              errors={min_number_of_viewsError}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ReportedPostBlockParameters;
