import React, { Component } from "react";
import { LoginBox, ButtonForm } from "../../components";
import { connect } from "react-redux";
import { Row, Col, Input } from "antd";
import { Formik, Form } from "formik";
import { AuthAction } from "../../store/actions";
import { withRouter } from "react-router-dom";
import { Colors, Schemas } from "../../config";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirm_password: "",
      isType: false,
      isConfirmType: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (history.location.state != undefined) {
      if (history.location.state.email != undefined) {
        this.setState({
          email: history.location.state.email,
        });
      } else {
        history.push({
          pathname: "/login",
        });
      }
    } else {
      history.push({
        pathname: "/login",
      });
    }
  }

  render() {
    //console.log("RESET PASSWORD PROPS: ", this.props.resetPasswordLoader)
    const { resetpassword, resetPasswordLoader, history } = this.props;
    const { email, isType, isConfirmType } = this.state;
    return (
      <LoginBox isDontShow={true}>
        <Col span={24} style={{ marginTop: "20px" }}>
          <h3>
            <b>Reset your Password</b>
          </h3>
          <p>
            Choose a strong new password.
            <br /> Make sure it's unique.
          </p>
        </Col>
        <Formik
          initialValues={{
            password: "",
            confirm_password: "",
          }}
          validationSchema={Schemas.resetPasswordValidations}
          onSubmit={(values, { resetForm }) => {
            //console.log("these are values forget password: ", values);
            resetpassword(
              {
                email: email,
                password: values.password,
              },
              (isAccepted) => {
                //console.log("IS SIGNED IN ? ", isAccepted);
                isAccepted
                  ? history.push({
                      pathname: "/login",
                    })
                  : console.log("IS FALSE", isAccepted);
              }
            );
            resetForm({ values: "" });
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form>
              <Row
                style={{
                  justifyContent: "center",
                }}
                gutter={[0, 20]}
              >
                <Col span={16}>
                  <Input
                    type={!isType ? "password" : "text"}
                    placeholder="New Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    suffix={
                      !isType ? (
                        <RiEyeCloseLine
                          onClick={() => {
                            this.setState({ isType: !isType });
                          }}
                        />
                      ) : (
                        <RiEyeLine
                          onClick={() => {
                            this.setState({ isType: !isType });
                          }}
                        />
                      )
                    }
                    // iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                  <label style={{ color: Colors.Red }}>
                    {errors.password && touched.password && errors.password}
                  </label>
                </Col>
                <Col span={16}>
                  <Input
                    type={!isConfirmType ? "password" : "text"}
                    placeholder="Confirm Password"
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                    suffix={
                      !isConfirmType ? (
                        <RiEyeCloseLine
                          onClick={() => {
                            this.setState({
                              isConfirmType: !isConfirmType,
                            });
                          }}
                        />
                      ) : (
                        <RiEyeLine
                          onClick={() => {
                            this.setState({
                              isConfirmType: !isConfirmType,
                            });
                          }}
                        />
                      )
                    }
                    // iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                  <label style={{ color: Colors.Red }}>
                    {errors.confirm_password &&
                      touched.confirm_password &&
                      errors.confirm_password}
                  </label>
                </Col>
                <Col span={24}>
                  {/* <Link to="">
                      <label style={{ cursor: "pointer", color: "#08ac9c" }}>
                        Forgot Password?
                      </label>
                    </Link> */}
                </Col>
                <Col span={24}>
                  <ButtonForm loading={resetPasswordLoader} htmlType="submit">
                    Reset
                  </ButtonForm>
                </Col>
              </Row>
            </Form>
          )}
        />
      </LoginBox>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    resetpassword: (payload, cb) => {
      dispatch(AuthAction.ResetPassword(payload, cb));
    },
  };
}
export default withRouter(
  connect(
    (storeState) => ({
      resetPasswordLoader: storeState.AuthReducer.resetPasswordLoader,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(ResetPassword)
);
