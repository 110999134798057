import React, { useEffect, useState } from "react";
import { Row, Col, Table, Switch } from "antd";
import { StatusBar } from "../../components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SwitchWrapper from "../../components/switch";
import LocationManagementAction from "../../store/actions/appactions/LocationManagementAction";
import { ButtonForm } from "../../components";
import { useMedia } from "use-media";
import { connect } from "react-redux";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { UserAction } from "../../store/actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const LocationManagementTable = (props) => {
  const { locationData, offset, typee, like, userStatus, clearState } = props;
  const dispatch = useDispatch();
  const [likee, setLikee] = useState(like);
  const updateUserLoader = useSelector(
    (state) => state.UserReducer.updateUserLoader
  );
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const columns = [
    {
      title: "List of Locations",
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Country",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <p style={{ fontWeight: "bold", fontSize: isXxl ? "20px" : "16px" }}>
          {text}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (e, record) => (
        <Row justify="center" gutter={[20, 10]} align="middle">
          <Col>
            <SwitchWrapper
              loading={updateUserLoader}
              checked={record.status == "active" ? true : false}
              onChange={(e) => {
                dispatch(
                  LocationManagementAction.UpdateLocationRequest(
                    {
                      loc_id: record.id,
                      current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
                    },
                    () => {
                      clearState();
                      dispatch(
                        LocationManagementAction.LocationsRequest({
                          limit: 10,
                          offset: offset,
                        })
                      );
                    }
                  )
                );
              }}
            />
          </Col>
          <Col
            style={{
              color: "black",
              fontSize: isXxl ? "18px" : "14px",
            }}
            align="left"
          >
            {record.status == "active" ? "Enable" : "Disable"}
          </Col>
        </Row>
      ),
    },
  ];
  const [columnsNew, setColumnsNew] = useState(columns);
  useEffect(() => {
    setColumnsNew(columns);
  }, [offset]);
  const history = useHistory(props);
  const { userData, userTableLoader } = props;
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
      <Table
        style={{ cursor: "pointer" }}
        columns={columnsNew}
        pagination={false}
        dataSource={locationData}
        // loading={{ spinning: userTableLoader, indicator: antIcon }}
      />
    </Col>
  );
};

export default LocationManagementTable;
