import React, { useEffect, useState } from "react";
import { Row, Col, Table, Switch, Modal, Radio } from "antd";
import { useHistory } from "react-router-dom";
import { StatusBar, ButtonForm } from "../../components";
import { useMedia } from "use-media";
import { useDispatch, useSelector } from "react-redux";
import SwitchWrapper from "../../components/switch";
import { connect } from "react-redux";
import ContentManagementAction from "../../store/actions/appactions/ContentManagementAction";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { UserAction } from "../../store/actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const ReportedUsers = (props) => {
  const { userType, offset, typee, like, userStatus, clearLike } = props;
  const updateUserLoader = useSelector(
    (state) => state.UserReducer.updateUserLoader
  );
  const updateContentLoader = useSelector(
    (state) => state?.ContentManagementReducer?.updateContentLoader
  );
  const getContentsLoader = useSelector(
    (state) => state.ContentManagementReducer.getContentsLoader
  );
  const dispatch = useDispatch();
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const [offsett, setOffsett] = useState(offset);
  const [modal, setModal] = useState(false);
  const [cleared, setCleared] = useState(false)
  const [radio, setRadio] = useState(1);
  const [userId, setUserId] = useState(226);
  const onCancel = () => {
    setModal(false);
    setCleared(false);
  };
  const columns = [
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      width: "12%",
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Post",
      dataIndex: "thumb_url",
      key: "thumb_url",
      render: (text) => (
        <img
          src={text}
          style={{
            borderRadius: "10px",
            width: isXxl ? "100px" : "70px",
            height: isXxl ? "100px" : "70px",
            objectFit: "cover",
          }}
        ></img>
      ),
    },

    {
      title: "Post Info",
      dataIndex: "",
      key: "",
      render: (text) => {
        let offensive_count = 0;
        let not_relevant_topic_count = 0;
        text?.reports?.forEach((item) => {
          offensive_count =
            item?.type == "breach_of_Guidelines"
              ? (offensive_count = item.report_type_count)
              : offensive_count;
        });

        text?.reports?.forEach((item) => {
          not_relevant_topic_count =
            item?.type == "Not_relevant_to_topic"
              ? (not_relevant_topic_count = item.report_type_count)
              : not_relevant_topic_count;
        });

        return (
          <Row justify="center">
            <Col span={24}>
              <Row>
                <Col
                  span={20}
                  style={{
                    fontSize: isXxl ? "20px" : "16px",
                    fontWeight: "bold",
                  }}
                  align="left"
                >
                  <span style={{ color: "#999999" }}>Post Owner</span>:
                  {text.user.username}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[5, 0]} align="middle">
                <Col
                  style={{
                    color: "#000000",
                    fontSize: isXxl ? "18px" : "15px",
                    fontWeight: "500",
                  }}
                >
                  Offensive:
                </Col>
                <Col
                  style={{
                    color: "#000000",
                    fontSize: isXxl ? "18px" : "15px",
                  }}
                >
                  {offensive_count}
                </Col>
              </Row>
              <Row gutter={[5, 0]} align="middle">
                <Col
                  style={{
                    color: "#000000",
                    fontSize: isXxl ? "18px" : "15px",
                    fontWeight: "500",
                  }}
                >
                  Not Relevant:
                </Col>
                <Col
                  style={{
                    color: "#000000",
                    fontSize: isXxl ? "18px" : "15px",
                  }}
                >
                  {not_relevant_topic_count}
                </Col>
              </Row>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Views",
      dataIndex: "seen_count",
      key: "seen_count",
      sorter: (a, b) => a.seen_count - b.seen_count,
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Report",
      dataIndex: "reports_count",
      key: "reports_count",
      sorter: (a, b) => a.reports_count - b.reports_count,
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Reported %",
      dataIndex: "",
      key: "",
      width: "15%",
      sorter: (a, b) => a.reports_count - b.reports_count,
      render: (text) => {
        return (
          <p
            style={{
              color: "black",
              fontSize: isXxl ? "22px" : "14px",
              fontWeight: "bold",
            }}
          >
            {(!text?.reports_count && !text?.seen_count
              ? 0.0
              : (text?.reports_count / text?.seen_count) * 100 !== Infinity
                ? (text?.reports_count / text?.seen_count) * 100
                : 0
            ).toFixed(2)}
            %
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: isXxl ? "12%" : "16%",
      render: (text) => (
        <Row>
          <Col
            style={{
              borderRadius: "20px",
              backgroundColor: text == "reported" ? "#009498" : "#E06030",
              color: "#FFFFFF",
              height: isXxl ? "40px" : "42px",
              fontSize: isXxl ? "16px" : "14px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            span={24}
          >
            {text == "reported"
              ? "Reported"
              : text == "removed"
                ? "Admin-Suspended"
                : text == "suspended"
                  ? "Auto-Suspended"
                  : "Released"}
          </Col>
        </Row>
      ),
    },
    {
      title: "Action",
      dataIndex: "user",
      key: "user",
      width: "20%",
      render: (e, record) => (
        <Row gutter={[10, 10]} align="middle">
          <Col
            span={6}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <SwitchWrapper
              loading={updateUserLoader}
              checked={
                record.status == "reported" ||
                  record.status == "released" ||
                  record.status == "active "
                  ? true
                  : false
              }
              onChange={(e) => {
                if (!e) {
                  setModal(true);
                  setCleared(false);
                  setUserId(record.id);
                } else {
                  dispatch(
                    ContentManagementAction.UpdateContentRequest(
                      {
                        post_id: record.id,
                        current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
                      },
                      () => {
                        clearLike();
                        dispatch(
                          ContentManagementAction.ContentsRequest({
                            filter: "reported",
                            limit: 10,
                            offset: offset,
                          })
                        );
                      }
                    )
                  );
                }
              }}
            />
          </Col>
          <Col
            span={18}
            style={{
              color: "black",
              fontSize: isXxl ? "18px" : "14px",
            }}
          >
            <Row align="start">
              <Col 
                style={{
                  display:"flex",
                  justifyContent:"start"
                }}
              span={24}>
                {record?.status == "reported" || record?.status == "released"
                  ? "Post is Enabled"
                  : "Post is Disable"}
              </Col>
              {record.reason ? (
                <Col
                  style={{
                    color: "black",
                    fontSize: isXxl ? "12px" : "11px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "middle"
                  }}
                  span={24}
                >
                  Reason : {record.reason}
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            span={24}
          >
            <ButtonForm
              loading={updateContentLoader}
              isModal
              onClick={() => {
                setModal(true)
                setCleared(true)
                setUserId(record.id)
              }}
              style={{
                backgroundColor: "#E06030",
                color: "#FFFFFF",
                fontSize: isXxl ? "18px" : "14px",
                height: "40px"
              }}
            >
              {/* {<AiOutlineClose size={20} />} */}
              Clear
            </ButtonForm>
          </Col>
        </Row>
      ),
    },
  ];
  const [columnsNew, setColumnsNew] = useState(columns);
  useEffect(() => {
    setColumnsNew(columns);
  }, [userType, offset, like, userStatus]);
  useEffect(() => {
    setOffsett(offset);
  }, [offset]);
  const history = useHistory();
  const { userData, userTableLoader } = props;
  const onRadioChange = (e) => {
    setRadio(e.target.value);
  };
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
      <Table
        style={{
          cursor: "pointer",
          borderRadius: "30px",
        }}
        columns={columnsNew}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              history.push({
                pathname: `/users/user-details/${record.user_id}`,
                state: {
                  id: record.user_id,
                },
              });
            },
          };
        }}
        pagination={false}
        dataSource={props.userData}
        loading={{ spinning: getContentsLoader, indicator: antIcon }}
      />
      <Modal
        visible={modal}
        closable={false}
        onCancel={onCancel}
        maskClosable={true}
        centered
        footer={null}
        bodyStyle={{ height: isXxl ? "230px" : "200px" }}
      >
        <Row justify="center" style={{ height: "100%", borderRadius: "10px" }}>
          <Col span={24} style={{ height: isXxl ? "120px" : "80px" }}>
            <Row justify="center" align="middle" style={{ height: "100%" }}>
              <Col
                style={{ color: "#009498", fontSize: isXxl ? "26px" : "20px" }}
                align="middle"
              >
                {cleared ? "Clear Report" : "Reason For Disabling"}
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            style={{
              backgroundColor: "#009498",
              height: isXxl ? "200px" : "150px",
            }}
          >
            <Row
              gutter={[5, 5]}
              justify="space-around"
              align="middle"
              style={{ height: "100%" }}
            >
              {!cleared ? (
                <Col span={24} align="middle">
                  <Radio.Group onChange={onRadioChange} value={radio}>
                    <Radio value={0} style={{ color: "#FFFFFF" }}>
                      Not Relevant
                    </Radio>
                    <Radio
                      value={1}
                      style={{ color: "#FFFFFF", marginLeft: "30px" }}
                    >
                      Offensive
                    </Radio>
                  </Radio.Group>
                </Col>
              ) : (
                <Col
                  style={{
                    color: "#FFFFFF",
                    fontSize: isXxl ? "26px" : "16px",
                    display: "flex",
                    justifyContent: "center"
                  }}
                  span={24}
                >
                  Are you sure you want to clear this post report?
                </Col>
              )}
              {!cleared ? (
                <Col span={8}>
                  <ButtonForm
                    loading={updateContentLoader}
                    isModal
                    onClick={() => {
                      dispatch(
                        ContentManagementAction.UpdateContentRequest(
                          {
                            post_id: userId,
                            reason: radio == 1 ? "Offensive " : "Not Relevant",
                            current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
                          },
                          () => {
                            dispatch(
                              ContentManagementAction.ContentsRequest({
                                filter: "reported",
                                limit: 10,
                                offset: offset,
                              })
                            );
                            setModal(false);
                          }
                        )
                      );
                    }}
                    style={{
                      backgroundColor: "#E06030",
                      color: "#FFFFFF",
                      fontSize: isXxl ? "18px" : "14px",
                      height: isXxl ? "50px" : "35px",
                    }}
                    span={12}
                  >
                    Disable
                  </ButtonForm>
                </Col>
              )
                : (
                  <>
                    <Col span={10}>
                      <ButtonForm
                        loading={updateContentLoader}
                        isModal
                        onClick={() => {
                          dispatch(
                            ContentManagementAction.UpdateContentRequest(
                              {
                                post_id: userId,
                                reason: "cleared",
                                current_time: moment().format("YYYY-MM-DD hh:mm:ss")
                              },
                              () => {
                                dispatch(
                                  ContentManagementAction.ContentsRequest({
                                    filter: "reported",
                                    limit: 10,
                                    offset: offset,
                                  })
                                );
                                setModal(false);
                              }
                            )
                          );
                        }}
                        style={{
                          backgroundColor: "#E06030",
                          color: "#FFFFFF",
                          fontSize: isXxl ? "18px" : "14px",
                          height: isXxl ? "50px" : "35px",
                        }}
                        span={12}
                      >
                        Yes
                      </ButtonForm>
                    </Col>
                    <Col span={10}>
                      <ButtonForm
                        loading={updateContentLoader}
                        isModal
                        onClick={() => setModal(false)}
                        style={{
                          backgroundColor: "#E06030",
                          color: "#FFFFFF",
                          fontSize: isXxl ? "18px" : "14px",
                          height: isXxl ? "50px" : "35px",
                        }}
                        span={12}
                      >
                        No
                      </ButtonForm>
                    </Col>
                  </>
                )}

            </Row>
          </Col>
        </Row>
      </Modal>
    </Col>
  );
};

export default ReportedUsers;
