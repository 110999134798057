import React from "react";
import { Row, Col } from "antd";
import { useMedia } from "use-media";
const TextAndTag = (props) => {
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });

  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const { text, tag } = props;
  return (
    <Row gutter={[20, 0]}>
      <Col style={{ color: "white", fontSize: isXxl ? "22px" : "15px" }}>
        {text} :
      </Col>
      <Col
        align="middle"
        span={13}
        style={{
          height: isXxl ? "32px" : "25px",
          color: "white",
          backgroundColor: "#E06030",
          borderRadius: "20px",
          textTransform: "capitalize",
          fontSize: isXxl ? "18px" : "15px",
        }}
      >
        {tag}
      </Col>
    </Row>
  );
};

export default TextAndTag;
