import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Please enter a valid email address"
    )
    .required("Required fields can not be left empty"),

  password: Yup.string()
    .min(8, "Password is Too Short! it must be between 8 and 16 characters")
    .max(16, "Password is Too Long! it must be between 8 and 16 characters")
    .matches(/^(?=.*[A-Z])(?=.*\W)(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase letter, one number and one symbol.')
    .matches(/^[a-zA-Z0-9\W\S]{8,16}$/, "atleast contain eight characters.")
    .required("Required fields can not be left empty")
})