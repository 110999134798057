import React, { useState, useEffect } from "react";
import { Row, Col, Pagination, Select, Spin, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useMedia } from "use-media";
import moment from "moment";
import { ButtonForm } from "../../components";
import Image from "../../components/image/index";
import newTopicLogo from "../../../src/assets/images/newTopicLogo.svg";
import AddTopic from "../../../src/assets/images/addTopic.svg";
import { RiSearchLine } from "react-icons/ri";
import { SearchBar, HeaderComponent } from "../../components";
import TopicManagementAction from "../../store/actions/appactions/TopicManagementAction";
import TopicManagementTable from "../TopicManagement/TopicManagementTable";
import SwitchWrapper from "../../components/switch";

const TopicManagement = () => {
  const [state, setState] = useState({
    name: "",
    is_age_limit: false,
    limit: 10,
    offset: 0,
    like: "",
    current: 1,
  });
  const [errors, setErrors] = useState({ name_error: "" });
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const handlePages = (pageNumber) => {
    const limit = 10;
    let offset = (pageNumber - 1) * limit;
    dispatch(TopicManagementAction.TopicsRequest({ limit, offset, like }));
    setState({ ...state, offset: offset, current: pageNumber });
  };
  const { limit, offset, like, current, name, is_age_limit } = state;

  useEffect(() => {
    dispatch(TopicManagementAction.TopicsRequest());
  }, []);
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const topics = useSelector((state) => state.TopicManagementReducer.topics);
  const topicsLoader = useSelector(
    (state) => state.TopicManagementReducer.getTopicsLoader
  );
  const addTopicLoader = useSelector(
    (state) => state.TopicManagementReducer.addTopicLoader
  );
  const topicsCount = useSelector(
    (state) => state.TopicManagementReducer.topicsCount
  );

  const handleTopic = (val) => {
    setState({ ...state, like: val, current: 1 });
    dispatch(TopicManagementAction.TopicsRequest({ like: val }));
    setErrors("");
  };
  function onHandleSubmit(obj) {
    const { name } = obj;
    let objError = {};
    if (!name.length) {
      if (!name.length) {
        objError = {
          ...objError,
          name_error: "Please Enter the name",
        };
      }
      if (name.length > 25) {
        objError = {
          ...objError,
          name_error: "Topic Length must be less than 25 characters!",
        };
      }
      setErrors({
        ...objError,
      });
    } else {
      dispatch(
        TopicManagementAction.AddTopicRequest(
          {
            name,
            is_age_limit: is_age_limit ? "true" : "false",
            current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
          },
          () => {
            dispatch(
              TopicManagementAction.TopicsRequest({
                limit: 10,
                offset: offset,
              })
            );
            setModal(false);
            setState({ ...state, name: "" });
          }
        )
      );
    }
  }
  const onCancel = () => {
    setErrors("");
    setState({ ...state, name: "" });
    setModal(false);
  };
  return (
    <HeaderComponent>
      {/* {locationsLoader ? (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col span={24}>
            <Spin size="large" />
          </Col>
        </Row>
      ) : ( */}
      <Row
        gutter={[0, 20]}
        justify="space-between"
        style={{
          padding: isXxl ? "8rem 4.5rem 0rem 4.5rem" : "5rem 2rem 0rem 2rem",
        }}
      >
        <Col span={1} align="left">
          <Image src={newTopicLogo} />
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={12} lg={10} xl={12} xxl={14} align="left">
              <h1
                style={{
                  fontSize: isXxl ? "46px" : "35px",
                  color: "#415465",
                  fontWeight: "700",
                }}
              >
                Topic Management
              </h1>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={12} xxl={10}>
              <Row align="middle" justify="space-between" gutter={[0, 20]}>
                <Col
                  xs={24}
                  sm={8}
                  md={8}
                  lg={9}
                  xl={8}
                  xxl={8}
                  // style={{

                  //   backgroundColor: "#009498",
                  //   cursor: "pointer",
                  // }}
                >
                  <ButtonForm
                    onClick={() => {
                      setModal(true);
                    }}
                    style={{
                      backgroundColor: "#009498",
                      borderRadius: "33px",
                    }}
                  >
                    <Row justify="space-around" align="middle">
                      <Col>
                        <Image src={AddTopic} />
                      </Col>
                      <Col
                        span={16}
                        align="start"
                        style={{
                          color: "white",
                          fontSize: isXxl ? "22px" : "18px",
                        }}
                      >
                        Add Topic
                      </Col>
                    </Row>
                  </ButtonForm>
                </Col>
                <Col xs={24} sm={14} md={14} lg={12} xl={12} xxl={13}>
                  <Row>
                    <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={21}>
                      <SearchBar
                        style={{ height: isXxl ? "55px" : "45px" }}
                        placeholder="Search"
                        isTable
                        onChange={(e) => {
                          handleTopic(e.target.value);
                        }}
                      />
                    </Col>
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      xxl={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#E06030",
                        borderTopRightRadius: "33px",
                        borderBottomRightRadius: "33px",
                      }}
                    >
                      {<RiSearchLine style={{ color: "#FFFFFF" }} size={20} />}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24} style={{ padding: "30px 0px" }}>
              <Row
                style={{
                  boxShadow: "0px 5px 20px #1E1E2214",
                  borderRadius: "10px",
                }}
              >
                <TopicManagementTable
                  locationData={topics}
                  limit={10}
                  offset={offset}
                />
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Pagination
                  responsive="true"
                  showLessItems="true"
                  current={current}
                  pageSize={10}
                  total={topicsCount ? topicsCount : 0}
                  onChange={(e) => {
                    handlePages(e);
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        visible={modal}
        closable={false}
        onCancel={onCancel}
        maskClosable={true}
        centered
        footer={null}
        bodyStyle={{
          height: isXxl ? "450px" : "300px",
          background: "#009498",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Row gutter={[0, isXxl ? 100 : 60]} justify="center">
          <Col span={24}>
            <Row justify="center" align="middle" gutter={[0, 20]}>
              <Col
                align="middle"
                span={24}
                style={{
                  color: "#FFFFFF",
                  fontSize: isXxl ? "28px" : "20px",
                  padding: "4px",
                }}
              >
                Add Topic
              </Col>
              <Col span={24}>
                <Row justify="space-around" align="middle">
                  <Col span={10}>
                    <SearchBar
                      placeholder="Topic Name"
                      isTopic
                      value={name}
                      style={{ borderRadius: "21px", color: "#FFFFFF" }}
                      onChange={(e) => {
                        setState({ ...state, name: e.target.value });
                        setErrors("");
                      }}
                    />
                    <span style={{ color: "red" }}>{errors.name_error}</span>
                  </Col>

                  <Col span={10}>
                    <Row gutter={[10, 0]} justify="center">
                      <Col style={{ color: "#FFFFFF" }}>Age Above 18+</Col>
                      <Col>
                        <SwitchWrapper
                          checked={is_age_limit}
                          onChange={(e) => {
                            setState({
                              ...state,
                              is_age_limit: e,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col span={20} style={{ background: "#F1F1F1" }}></Col>
            </Row>
          </Col>
          <Col span={24} style={{ backgroundColor: "#009498" }}>
            <Row justify="space-around" align="middle">
              <Col span={8}>
                <ButtonForm
                  isModal
                  loading={addTopicLoader}
                  onClick={() => onHandleSubmit({ ...state })}
                  style={{
                    backgroundColor: "white",
                    color: "#009498",
                    fontSize: isXxl ? "18px" : "14px",
                  }}
                  span={12}
                >
                  Add Topic
                </ButtonForm>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      {/* )} */}
    </HeaderComponent>
  );
};

export default TopicManagement;
