import React, { useState, useEffect } from "react";
import { Row, Col, Pagination, Select, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useMedia } from "use-media";
import Image from "../../components/image/index";
import locationLogo from "../../../src/assets/images/locationLogo.svg";
import { RiSearchLine } from "react-icons/ri";
import { SearchBar, HeaderComponent } from "../../components";
import LocationManagementAction from "../../store/actions/appactions/LocationManagementAction";
import LocationManagementTable from "../LocationManagement/LocationManagementTable";

const LocationManagement = () => {
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    like: "",
    current: 1,
  });
  const dispatch = useDispatch();

  const handlePages = (pageNumber) => {
    const limit = 10;
    let offset = (pageNumber - 1) * limit;
    dispatch(
      LocationManagementAction.LocationsRequest({ limit, offset, like })
    );
    setState({ ...state, offset: offset, current: pageNumber });
  };
  const { limit, offset, like, current } = state;
  useEffect(() => {
    dispatch(LocationManagementAction.LocationsRequest());
  }, []);
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const locations = useSelector(
    (state) => state.LocationManagementReducer.locations
  );
  const locationsLoader = useSelector(
    (state) => state.LocationManagementReducer.getLocationsLoader
  );
  const locationsCount = useSelector(
    (state) => state.LocationManagementReducer.locationsCount
  );
  const handleLocation = (val) => {
    setState({ ...state, like: val, current: 1 });
    dispatch(LocationManagementAction.LocationsRequest({ like: val }));
  };
  return (
    <HeaderComponent>
      <Row
        gutter={[0, 20]}
        justify="space-between"
        style={{
          padding: isXxl ? "8rem 4.5rem 0rem 4.5rem" : "5rem 2rem 0rem 2rem",
        }}
      >
        <Col span={1} align="left">
          <Image src={locationLogo} />
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={14} lg={10} xl={10} xxl={10} align="left">
              <h1
                style={{
                  fontSize: isXxl ? "46px" : "35px",
                  color: "#415465",
                  fontWeight: "700",
                }}
              >
                Location Management
              </h1>
            </Col>
            <Col xs={24} sm={24} md={10} lg={7} xl={6} xxl={6}>
              <Row>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={21}>
                  <SearchBar
                    placeholder="Search"
                    isTable
                    value={like}
                    onChange={(e) => {
                      handleLocation(e.target.value);
                    }}
                  />
                </Col>
                <Col
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E06030",
                    borderTopRightRadius: "33px",
                    borderBottomRightRadius: "33px",
                  }}
                >
                  {<RiSearchLine style={{ color: "#FFFFFF" }} size={20} />}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24} style={{ padding: "30px 0px" }}>
              <Row
                style={{
                  boxShadow: "0px 5px 20px #1E1E2214",
                  borderRadius: "10px",
                }}
              >
                <LocationManagementTable
                  locationData={locations}
                  limit={10}
                  offset={offset}
                  clearState={() => setState({ ...state, like: "" })}
                />
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Pagination
                  responsive="true"
                  showLessItems="true"
                  current={current}
                  pageSize={10}
                  total={locationsCount ? locationsCount : 0}
                  onChange={(e) => {
                    handlePages(e);
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* )} */}
    </HeaderComponent>
  );
};

export default LocationManagement;
