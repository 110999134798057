import React from "react";
import { withTheme } from "styled-components";
import {Colors} from "../../config"

const Blip = props => (
    <span style={
        { display: "inline-block", 
        width:  8, 
        height:  8, 
        //margin: props.small ? "0px 4px" : "0px 6px", 
        borderRadius: 5,
        backgroundColor: `${props.color ? props.color : Colors.Primary}` }} />
)

export default React.memo(withTheme(Blip));