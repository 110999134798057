import {
  GET_USERS_REQUEST,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILURE,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAILURE,
  GETAPPDOWNLOADCOUNT,
  GETAPPDOWNLOADCOUNT_SUCCESS,
  GETAPPDOWNLOADCOUNT_FAILURE,
  GETSUBSCRIBEDUSERCOUNT,
  GETSUBSCRIBEDUSERCOUNT_SUCCESS,
  GETSUBSCRIBEDUSERCOUNT_FAILURE,
  GETUSERTYPECOUNT,
  GETUSERTYPECOUNT_FAILURE,
  GETUSERTYPECOUNT_SUCCESS,
  GETEVENTSTATSCOUNT,
  GETEVENTSTATSCOUNT_FAILURE,
  GETEVENTSTATSCOUNT_SUCCESS,
  CLEARDASHBOARD,
  LOADER_TRUE,
  LOADER_FALSE,
} from "../../constants";

const initialState = {
  userstatistics: {},
  usercount: "",
  userdata: [],
  userdetails: {},
  appDownloadCounts: [],
  userTypeCounts: {},
  eventCounts: [],
  subscribedUserCount: [],
  userTableLoader: false,
  updateUserLoader: false,
  loader: false,
};
export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_REQUEST:
      state = {
        ...state,
        userTableLoader: true,
        userdata: [],
      };
      break;
    case GET_USERS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,

          userdata: action.payload,
          userCount: action.data,
          userTableLoader: false,
        };
      }
    }
    case GET_USERS_FAILURE: {
      state = {
        ...state,
        userTableLoader: false,
      };
      break;
    }

    case UPDATE_USER_STATUS_REQUEST:
      state = {
        ...state,
        updateUserLoader: true,
      };
      break;
    case UPDATE_USER_STATUS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          updateUserLoader: false,
        };
      }
    }
    case UPDATE_USER_STATUS_FAILURE: {
      state = {
        ...state,
        updateUserLoader: false,
      };
      break;
    }
    // case GETUSERSTATS:
    //   state = {
    //     ...state,
    //     loader: false,
    //   };
    //   break;
    // case GETUSERSTATS_SUCCESS: {
    //   if (action.payload) {
    //     state = {
    //       ...state,
    //       userstatistics: action?.payload,
    //       loader: true,
    //     };
    //   }
    // }
    // case GETUSERSTATS_FAILURE: {
    //   state = {
    //     ...state,
    //     loader: false,
    //   };
    //   break;
    // }

    case USER_DETAIL_REQUEST:
      state = {
        ...state,
        loader: false,
        userdetails: {},
      };
      break;
    case USER_DETAIL_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          userdetails: action.payload,
          loader: true,
        };
      }
    }
    case USER_DETAIL_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case GETAPPDOWNLOADCOUNT:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETAPPDOWNLOADCOUNT_SUCCESS: {
      if (action.payload) {
        const appData = action?.payload;
        let objKeys = Object.keys(appData);
        let objValues = Object.values(appData);
        let data = objKeys?.map((item) => {
          return {
            name: item.slice(0, 3),
          };
        });
        let newData = objValues.map((item, index) => {
          return {
            name: data[index].name,
            Ios: item,
            Android: item,
          };
        });

        state = {
          ...state,
          appDownloadCounts: newData,
          loader: true,
        };
      }
    }
    case GETAPPDOWNLOADCOUNT_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case GETSUBSCRIBEDUSERCOUNT:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETSUBSCRIBEDUSERCOUNT_SUCCESS: {
      if (action.payload) {
        const subscribedUserData = action?.payload?.subscribedUsersCount;
        let objKeys = Object.keys(subscribedUserData);
        let objValues = Object.values(subscribedUserData);
        let data = objKeys?.map((item) => {
          return {
            name: item.slice(0, 3),
          };
        });
        let newData = objValues.map((item, index) => {
          return {
            name: data[index].name,
            "Subscribed Users": item,
          };
        });
        state = {
          ...state,
          subscribedUserCount: newData,
          loader: true,
        };
      }
    }
    case GETSUBSCRIBEDUSERCOUNT_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case GETEVENTSTATSCOUNT:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETEVENTSTATSCOUNT_SUCCESS: {
      if (action.payload) {
        let completeData = Object.values(action?.payload?.eventCompletedCount);
        let createData = Object.values(action?.payload?.eventCreatedCount);
        let monthNames = Object.keys(action?.payload?.eventCreatedCount);
        let data = monthNames?.map((item) => {
          return {
            name: item.slice(0, 3),
          };
        });
        let newData = createData.map((item, index) => {
          return {
            name: data[index].name,
            "Event Creation": item,
          };
        });
        let combinedData = completeData.map((item, index) => {
          return {
            name: data[index].name,
            "Event Completion": item,
            "Event Creation": newData[index]["Event Creation"],
          };
        });
        state = {
          ...state,
          eventCounts: combinedData,
          loader: true,
        };
      }
    }
    case GETEVENTSTATSCOUNT_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case GETUSERTYPECOUNT:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETUSERTYPECOUNT_SUCCESS: {
      if (action.payload) {
        const newUserCount = [
          {
            name: "Rider",
            value: action?.payload?.rider,
          },
          {
            name: "Event Manager",
            value: action?.payload?.eventManager,
          },
          {
            name: "Judge",
            value: action?.payload?.judge,
          },
        ];
        state = {
          ...state,
          userTypeCounts: newUserCount,
          loader: true,
        };
      }
    }
    case GETUSERTYPECOUNT_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case CLEARDASHBOARD: {
      state = initialState;
    }
    default:
      break;
  }

  return state;
}
