import React, { useEffect, useState } from "react";
import { Row, Col, Table, Switch, Modal, Radio } from "antd";
import { useHistory } from "react-router-dom";
import { StatusBar, ButtonForm } from "../../components";
import { useMedia } from "use-media";
import { useDispatch, useSelector } from "react-redux";
import SwitchWrapper from "../../components/switch";
import { connect } from "react-redux";
import ContentManagementAction from "../../store/actions/appactions/ContentManagementAction";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { UserAction } from "../../store/actions";
import { isPromise } from "formik";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const SuspiciousFavourite = (props) => {
  const { data, paragraph } = props;
  const updateUserLoader = useSelector(
    (state) => state.UserReducer.updateUserLoader
  );
  const dispatch = useDispatch();
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const columns = [
    {
      title: "Post",
      dataIndex: "post",
      key: "post",
      className: "postName",
      render: (text) => {
        return (
          <p
            style={{
              color: "#8F8F8F",
              fontSize: isXxl ? "16px" : "14px",
              fontWeight: "bold",
            }}
          >
            <a href={text[0]?.thumb_url} target="_blank">
              link/thumb_url
            </a>
          </p>
        );
      },
    },
    {
      title: "Score Change",
      dataIndex: "difference",
      key: "difference",
      className: "postName",
      sorter: (a, b) => a.difference - b.difference,

      render: (text) => (
        <p
          style={{
            color: "#8F8F8F",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },

    {
      title: "User",
      dataIndex: "user",
      className: "postName",
      key: "user",
      render: (text) => {
        return (
          <p
            style={{
              color: "#8F8F8F",
              fontSize: isXxl ? "22px" : "14px",
              fontWeight: "bold",
            }}
          >
            {text[0]?.username}
          </p>
        );
      },
    },

    {
      title: "Poster",
      dataIndex: "user",
      className: "postName",
      key: "user",
      render: (text) => (
        <p
          style={{
            color: "#8F8F8F",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text[0]?.username}
        </p>
      ),
    },
  ];
  const [columnsNew, setColumnsNew] = useState(columns);
  useEffect(() => {
    setColumnsNew(columns);
  }, [data]);
// const { userData, userTableLoader } = props;

  return (
    <Row justify="center">
      <Col style={{ overflowX: "auto" }} span={24}>
        <Table
          style={{
            cursor: "pointer",
            borderRadius: "30px",
            height: isXxl ? "250px" : "300px",
          }}
          columns={columnsNew}
          pagination={false}
          dataSource={data}
        />
      </Col>
      <Col
        span={24}
        align="left"
        style={{ fontSize: "14px", color: "#727373" }}
      >
        {paragraph}
      </Col>
    </Row>
  );
};

export default SuspiciousFavourite;
