import React from "react";
import { Row, Col } from "antd";
import TextAndToggle from "./TextAndToggle";
import TextAndInput from "./TextAndInput";
import { useMedia } from "use-media";
const Users = (props) => {
  const { data, handleToggle, handleNumber, errors } = props;
  const {
    allow_auto_login,
    add_mob,
    custom_ad,
    minimum_reach_points,
    show_ads_interval,
  } = data;

  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const { minimum_reach_pointsError, show_ads_intervalError } = errors;
  return (
    <Row
      style={{
        borderLeft: "8px solid #009498",
        borderRadius: "9px",
        padding: "0rem 1rem",
      }}
    >
      <Col
        xl={8}
        xxl={4}
        style={{ fontSize: isXxl ? "28px" : "20px", borderRadius: "9px" }}
        align="left"
      >
        <h1>Users</h1>
      </Col>
      <Col span={24}>
        <Row justify="left" gutter={[0, 10]}>
          <Col xl={18} xxl={15}>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <TextAndToggle
                  title="Allow Auto Login"
                  name="allow_auto_login"
                  value={allow_auto_login || ""}
                  handleToggle={handleToggle}
                />
              </Col>
              <Col span={24}>
                <TextAndToggle
                  title="AdMob"
                  name="add_mob"
                  value={add_mob || ""}
                  handleToggle={handleToggle}
                />
              </Col>
              <Col span={24}>
                <TextAndToggle
                  title="Custom Ad"
                  name="custom_ad"
                  value={custom_ad || ""}
                  handleToggle={handleToggle}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <TextAndInput
              isUsers
              title="Minimum Points To Reach 100th Position"
              placeholder={minimum_reach_points}
              name="minimum_reach_points"
              value={minimum_reach_points || 0}
              handleNumber={handleNumber}
              errors={minimum_reach_pointsError}
            />
          </Col>
          <Col span={24}>
            <TextAndInput
              isUsers
              title="Shows Ads Interval"
              placeholder={minimum_reach_points}
              name="show_ads_interval"
              value={show_ads_interval || 0}
              handleNumber={handleNumber}
              errors={show_ads_intervalError}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Users;
