import React, { useEffect, useReducer, useState } from "react";
import { Row, Col } from "antd";
import { useMedia } from "use-media";
import { useSelector, useDispatch } from "react-redux";
import Image from "../../components/image/index";
import { useHistory } from "react-router-dom";
import moment from "moment";
import changePasswordLogo from "../../assets/images/changePasswordLogo.svg";
import PasswordEyeOpened from "../../assets/images/passwordEyeOpened.png";
import passwordPrefix from "../../assets/images/passwordPrefix.svg";
import PasswordSuffix from "../../assets/images/passwordSuffix.svg";
import { ButtonForm } from "../../components";
import {
  Blip,
  SearchBar,
  HeaderComponent,
  TransactionTable,
} from "../../components";
import adminRoleLogo from "../../assets/images/adminRoleLogo.svg";
import profileBackgroundLogo from "../../assets/images/profileBackgroundLogo.svg";
import { ProfileAction } from "../../store/actions";
import profileLogo from "../../../src/assets/images/profileLogo.svg";
import InputWrapper from "../../components/input";
import profileCorner from "../../../src/assets/images/profileCorner.svg";

const ChangePassword = () => {
  const [values, setValues] = useState({
    isType: false,
    isType1: false,
    isType2: false,
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({
    old_password_error: "",
    new_password_error: "",
    confirm_password_error: "",
  });

  const {
    old_password,
    new_password,
    confirm_password,
    isType,
    isType1,
    isType2,
  } = values;
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  const dispatch = useDispatch();
  const history = useHistory();
  const changePasswordLoader = useSelector(
    (state) => state.ProfileReducer.changePasswordLoader
  );

  function handleText(val) {
    setValues({ ...values, [val.target.name]: val.target.value });
    setErrors("");
  }
  function onHandleSubmit(obj) {
    const { old_password, new_password, confirm_password } = obj;
    let objError = {};
    if (
      !old_password.length ||
      !new_password.length ||
      !confirm_password.length ||
      confirm_password !== new_password
    ) {
      if (!old_password?.length) {
        objError = {
          ...objError,
          old_password_error: "Please Enter Old Password",
        };
      }
      if (new_password !== confirm_password) {
        objError = {
          ...objError,
          new_password_error: "Both Passwords should be same",
          confirm_password_error: "Both passwords should be same",
        };
      }
      if (!new_password?.length) {
        objError = {
          ...objError,
          new_password_error: "Please Enter New Password",
        };
      }
      if (!confirm_password?.length) {
        objError = {
          ...objError,
          confirm_password_error: "Please Confirm Your Password",
        };
      }

      setErrors({
        ...objError,
      });
    } else {
      dispatch(
        ProfileAction.ChangePasswordRequest(
          {
            old_password,
            new_password,
            confirm_password,
            current_time: moment().format("YYYY-MM-DD hh:mm:ss"),
          },
          () => {
            history.push("/profile");
          }
        )
      );
    }
  }
  return (
    <HeaderComponent>
      <Row
        gutter={[0, 10]}
        justify="space-between"
        style={{
          padding: isXxl ? "8rem 4.5rem 0rem 4.5rem" : "5rem 2rem 0rem 2rem",
        }}
      >
        <Col span={1} align="left">
          <Image src={profileCorner} />
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={14} lg={10} xl={10} xxl={8} align="left">
              <h1
                style={{
                  fontSize: isXxl ? "46px" : "35px",
                  color: "#415465",
                  fontWeight: "700",
                }}
              >
                Profile
              </h1>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            backgroundImage: `url(${profileBackgroundLogo})`,
            backgroundRepeat: "no-repeat",
            height: isXxl ? "70vh" : "80vh",
            backgroundPositionY: isXxl ? "50%" : "60%",
          }}
        >
          <Row gutter={[0, 10]} justify="center">
            <Col xs={24} sm={24} md={14} lg={12} xl={10} xxl={8}>
              <Row justify="center" gutter={[0, 20]} align="middle">
                <Col
                  span={24}
                  style={{
                    color: "#009498",
                    fontSize: isXxl ? "44px" : "25px",
                    fontWeight: "600",
                  }}
                >
                  Reset Password
                </Col>
                <Col
                  span={24}
                  style={{
                    color: "#8F8F8F",
                    fontSize: isXxl ? "26px" : "14px",
                  }}
                >
                  Make a new password strong and unique
                </Col>
                <Col span={20} className="inputDiv">
                  <InputWrapper
                    type={!isType ? "password" : "text"}
                    placeholder="Old Password"
                    prefix={<Image src={passwordPrefix} />}
                    name="old_password"
                    value={old_password}
                    isChangePassword
                    onChange={(e) => handleText(e)}
                    suffix={
                      !isType ? (
                        <img
                          onClick={() => {
                            setValues({ ...values, isType: !isType });
                          }}
                          src={PasswordSuffix}
                        />
                      ) : (
                        <img
                          onClick={() => {
                            setValues({ ...values, isType: !isType });
                          }}
                          src={PasswordEyeOpened}
                        />
                      )
                    }
                  />
                </Col>
                <span style={{ color: "red" }}>
                  {errors.old_password_error}
                </span>
                <Col span={20} className="inputDiv">
                  <InputWrapper
                    type={!isType1 ? "password" : "text"}
                    placeholder="New Password"
                    prefix={<Image src={passwordPrefix} />}
                    value={new_password}
                    name="new_password"
                    isChangePassword
                    onChange={(e) => handleText(e)}
                    suffix={
                      !isType1 ? (
                        <img
                          onClick={() => {
                            setValues({ ...values, isType1: !isType1 });
                          }}
                          src={PasswordSuffix}
                        />
                      ) : (
                        <img
                          onClick={() => {
                            setValues({ ...values, isType1: !isType1 });
                          }}
                          src={PasswordEyeOpened}
                        />
                      )
                    }
                  />
                </Col>
                <span style={{ color: "red" }}>
                  {errors.new_password_error}
                </span>
                <Col span={20} className="inputDiv">
                  <InputWrapper
                    type={!isType2 ? "password" : "text"}
                    placeholder="Confirm Password"
                    prefix={<Image src={passwordPrefix} />}
                    value={confirm_password}
                    name="confirm_password"
                    isChangePassword
                    onChange={(e) => handleText(e)}
                    suffix={
                      !isType2 ? (
                        <img
                          onClick={() => {
                            setValues({ ...values, isType2: !isType2 });
                          }}
                          src={PasswordSuffix}
                        />
                      ) : (
                        <img
                          onClick={() => {
                            setValues({ ...values, isType2: !isType2 });
                          }}
                          src={PasswordEyeOpened}
                        />
                      )
                    }
                  />
                </Col>
                <span style={{ color: "red" }}>
                  {errors.confirm_password_error}
                </span>
                <Col span={24}>
                  <Row justify="center">
                    <Col span={10}>
                      <ButtonForm
                        loading={changePasswordLoader}
                        onClick={() => onHandleSubmit({ ...values })}
                      >
                        Save
                      </ButtonForm>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </HeaderComponent>
  );
};

export default ChangePassword;
