import React from "react";
import { Row, Col } from "antd";
import StatsCard from "./StatsCard";
import { useMedia } from "use-media";
import { FaUsers } from "react-icons/fa";
import { BsImage } from "react-icons/bs";
import cloudIcon from "../../../src/assets/images/cloudIcon.svg";
import reportedPostsIcon from "../../../src/assets/images/reportedPostsIcon.png";
import hiddenPosts from "../../../src/assets/images/hiddenPosts.svg";
import multiUsersIcon from "../../../src/assets/images/multiUsersIcon.svg";
import dashboardImage from "../../../src/assets/images/dashboardImage.svg";
import Image from "../../components/image";
import expiredPostsIcon from "../../../src/assets/images/expiredPostsIcon.svg";
import autoSuspendedIcon from "../../../src/assets/images/autoSuspendedIcon.svg";
import adminSuspendedIcon from "../../../src/assets/images/adminSuspendedIcon.svg";
import ReleasedUsersIcon from "../../../src/assets/images/ReleasedUsersIcon.svg";

import "./styles.css";

const LiveReports = (props) => {
  const {
    data,
    ios_downloads,
    android_downloads,
    active_users,
    inactive_users,
  } = props;

  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  return (
    <Row gutter={[20, 20]}>
      <Col span={6}>
        <StatsCard
          backgroundColor="#3A3A3A"
          fontColor="#FFFFFF"
          title="Total User"
          icon={<Image src={multiUsersIcon} />}
          count={data ? active_users + inactive_users : 0}
        />
      </Col>
      <Col span={6}>
        <StatsCard
          backgroundColor="#F5BA3C"
          fontColor="#FFFFFF"
          title="Downloads"
          icon={<Image src={cloudIcon} />}
          count={data ? ios_downloads + android_downloads : 0}
        />
      </Col>
      <Col span={6}>
        <StatsCard
          backgroundColor="#014247"
          fontColor="#FFFFFF"
          title="Live Posts"
          icon={<Image src={dashboardImage} />}
          count={data ? data.active_post_count : 0}
        />
      </Col>
      <Col span={6}>
        <StatsCard
          backgroundColor="#E06030"
          fontColor="#FFFFFF"
          title="Reported Posts"
          icon={<Image src={reportedPostsIcon} />}
          count={data ? data.reported_count : 0}
        />
      </Col>

      <Col span={6}>
        <StatsCard
          backgroundColor="#3EB495"
          fontColor="#FFFFFF"
          title="Expired Posts"
          icon={<Image src={expiredPostsIcon} />}
          count={data ? data.expired_post_count : 0}
        />
      </Col>
      <Col span={6}>
        <StatsCard
          backgroundColor="#009498"
          fontColor="#FFFFFF"
          title="Auto Suspended"
          icon={<Image src={autoSuspendedIcon} />}
          count={data ? data.auto_suspend_count : 0}
        />
      </Col>
      <Col span={6}>
        <StatsCard
          backgroundColor="#415465"
          fontColor="#FFFFFF"
          title="Admin Suspended"
          icon={<Image src={adminSuspendedIcon} />}
          count={data ? data.admin_suspended_count : 0}
        />
      </Col>
      <Col span={6}>
        <StatsCard
          backgroundColor="#F5F5F5"
          fontColor="#000000"
          title="Released"
          icon={<Image src={ReleasedUsersIcon} />}
          count={data ? data.released_count : 0}
        />
      </Col>
    </Row>
  );
};

export default LiveReports;
