import {
  CONFIG_AND_SETTINGS_FAILURE,
  CONFIG_AND_SETTINGS_SUCCESS,
  CONFIG_AND_SETTINGS_REQUEST,
  UPDATE_CONFIG_AND_SETTINGS_REQUEST,
  UPDATE_CONFIG_AND_SETTINGS_SUCCESS,
  UPDATE_CONFIG_AND_SETTINGS_FAILURE,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAILURE,
} from "../../constants";

const initialState = {
  countries: [],
  dashboardStats: [],
  no_of_post_in_topic: {},
  no_of_post_by_topic: {},
  reports: {},
  topic: {},
  country: {},
  post_selected: {},
  configAndSettings: [],
  posts: [],
  post_ranking: [],
  users: [],
  reported_post_block_parameters: [],
  alternative_post_weights: [],
  configAndSettingLoader: false,
  updateConfigSettingLoader: false,
  dashboardLoader: false,
  countriesLoader: false,
};

export default function ConfigAndSettingReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIG_AND_SETTINGS_REQUEST:
      state = {
        ...state,
        configAndSettingLoader: true,
      };
      break;

    case CONFIG_AND_SETTINGS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          configAndSettings: action?.payload,
          posts: action?.payload ? action?.payload?.posts : [],
          users: action?.payload ? action?.payload?.users : [],
          post_ranking: action?.payload ? action?.payload?.post_ranking : [],
          reported_post_block_parameters: action?.payload
            ? action?.payload?.reported_post_block_parameters
            : [],

          alternative_post_weights: action?.payload
            ? action?.payload?.alternative_post_weights
            : [],
          configAndSettingLoader: false,
        };
      }
    }

    case CONFIG_AND_SETTINGS_FAILURE:
      state = {
        ...state,
        configAndSettingLoader: false,
      };
      break;

    case UPDATE_CONFIG_AND_SETTINGS_REQUEST:
      state = {
        ...state,
        updateConfigSettingLoader: true,
      };
      break;

    case UPDATE_CONFIG_AND_SETTINGS_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          updateConfigSettingLoader: false,
        };
      }
      break;

    case UPDATE_CONFIG_AND_SETTINGS_FAILURE:
      state = {
        ...state,
        updateConfigSettingLoader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
