import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Info from "../../components/message";
import { useDispatch, useSelector } from "react-redux";
import customAdManagement from "../../assets/images/customAdManagement.svg";
import { RiSearchLine } from "react-icons/ri";
import Image from "../../components/image/index";
import {
  CustomAdsManagementAction,
  DashboardAction,
  TopicManagementAction,
} from "../../store/actions";

import Select from "react-select";
import AllAds from "./AllAds";
import DraftAds from "./DraftAds";
import AddTopic from "../../assets/images/addTopic.svg";
import ExpiredAds from "./ExpiredAds";
import LiveAds from "./LiveAds";
import { Row, Col, Pagination, Modal } from "antd";
import { GENDER_OPTIONS } from "../../config/variables";
import {
  Blip,
  SearchBar,
  HeaderComponent,
  TransactionTable,
  ButtonForm,
} from "../../components";
import CreateAdModal from "./CreateAdModal";

const CustomAdManagement = () => {
  const [state, setState] = useState({
    all: true,
    live: false,
    draft: false,
    expired: false,
    current: 1,
    limit: 10,
    offset: 0,
    like: "",
    modal: false,
    country: [],
    gender: "",
  });

  // const obj={live:state.l}
  const [errors, setErrors] = useState({ name_error: "" });
  const {
    limit,
    like,
    offset,
    all,
    live,
    draft,
    expired,
    current,
    modal,
    country,
    gender,
  } = state;

  useEffect(() => {
    dispatch(
      CustomAdsManagementAction.CustomAdsRequest({
        filter: all ? "all" : live ? "live" : draft ? "draft" : "expired",
        limit: 10,
        offset: 0,
      })
    );

    dispatch(DashboardAction.CountriesRequest());
    dispatch(TopicManagementAction.TopicsRequest({ limit: "10000" }));
  }, []);
  const [isUpdateImg, setUpdateImg] = useState(false);
  const [img, setImg] = useState("");
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setUpdateImg(true);
      setImg(reader.result);
    };
    reader.onerror = function (error) {};
  }
  const handleCustomAd = (val) => {
    setState({ ...state, like: val });
    dispatch(CustomAdsManagementAction.CustomAdsRequest({ like: val }));
    setErrors("");
  };

  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  const dispatch = useDispatch();

  const customAds = useSelector(
    (state) => state?.CustomAdsManagementReducer?.customAds
  );

  const adsCount = useSelector(
    (state) => state?.CustomAdsManagementReducer?.adsCount
  );
  const handlePages = (val) => {
    const limit = 10;
    let offset = (val - 1) * limit;
    dispatch(
      CustomAdsManagementAction.CustomAdsRequest({
        filter: all ? "all" : live ? "live" : draft ? "draft" : "expired",
        limit: 10,
        offset: offset,
      })
    );
    setState({ ...state, offset: offset, current: val });
  };

  const topics = useSelector((state) => state?.TopicManagementReducer?.topics);
  var topicOptions =
    topics &&
    topics.map((item) => {
      return { value: item.id, label: item.name };
    });
  const countriesLoader = useSelector(
    (state) => state?.DashboardReducer?.contriesLoader
  );
  const countries = useSelector((state) => state?.DashboardReducer?.countries);
  var countriesOptions =
    countries &&
    countries.map((item) => {
      return { value: item.id, label: item.name };
    });
  var refImage;
  return (
    <HeaderComponent>
      <Row
        gutter={[0, 20]}
        justify="space-between"
        style={{
          padding: isXxl ? "8rem 4.5rem 0rem 4.5rem" : "5rem 2rem 0rem 2rem",
        }}
      >
        <Col span={1} align="left">
          <Image src={customAdManagement} />
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={14} align="left">
              <h1
                style={{
                  fontSize: isXxl ? "46px" : "35px",
                  color: "#415465",
                  fontWeight: "700",
                }}
              >
                Custom Ad Management
              </h1>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
              <Row align="middle" gutter={[30, 20]}>
                <Col xs={24} sm={8} md={8} lg={9} xl={10} xxl={9}>
                  <ButtonForm
                    onClick={() => {
                      setState({ ...state, modal: true });
                    }}
                    style={{
                      backgroundColor: "#009498",
                      borderRadius: "33px",
                      height: isXxl ? "55px" : "44px",
                    }}
                  >
                    <Row justify="space-around" align="middle">
                      <Col>
                        <Image src={AddTopic} />
                      </Col>
                      <Col
                        span={16}
                        align="start"
                        style={{
                          color: "white",
                          fontSize: isXxl ? "22px" : "18px",
                        }}
                      >
                        Create Ad
                      </Col>
                    </Row>
                  </ButtonForm>
                </Col>
                <Col xs={24} sm={14} md={14} lg={15} xl={14} xxl={15}>
                  <Row>
                    <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={21}>
                      <SearchBar
                        style={{ height: isXxl ? "55px" : "45px" }}
                        placeholder="Search"
                        isTable
                        onChange={(e) => {
                          handleCustomAd(e.target.value);
                        }}
                      />
                    </Col>
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      xxl={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#E06030",
                        borderTopRightRadius: "33px",
                        borderBottomRightRadius: "33px",
                      }}
                    >
                      {<RiSearchLine style={{ color: "#FFFFFF" }} size={20} />}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={18} xl={14} xxl={14} style={{ width: "100%" }}>
          <Row gutter={[5, 0]}>
            <Col span={6}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: true,
                    live: false,
                    draft: false,
                    expired: false,
                    current: 1,
                    offset: 0,
                  });
                  dispatch(
                    CustomAdsManagementAction.CustomAdsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "all",
                    })
                  );
                }}
                style={{
                  backgroundColor: all ? "#E06030" : "#F1F1F1",
                  color: all ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                All
              </ButtonForm>
            </Col>
            <Col span={6}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: false,
                    live: true,
                    draft: false,
                    expired: false,
                    current: 1,
                    offset: 0,
                  });
                  dispatch(
                    CustomAdsManagementAction.CustomAdsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "live",
                    })
                  );
                }}
                style={{
                  backgroundColor: live ? "#E06030" : "#F1F1F1",
                  color: live ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                Live
              </ButtonForm>
            </Col>
            <Col span={6}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: false,
                    live: false,
                    draft: true,
                    expired: false,
                    current: 1,
                    offset: 0,
                  });
                  dispatch(
                    CustomAdsManagementAction.CustomAdsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "draft",
                    })
                  );
                }}
                style={{
                  backgroundColor: draft ? "#E06030" : "#F1F1F1",

                  color: draft ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                Drafts
              </ButtonForm>
            </Col>
            <Col span={6}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: false,
                    live: false,
                    draft: false,
                    expired: true,
                    current: 1,
                    offset: 0,
                  });
                  dispatch(
                    CustomAdsManagementAction.CustomAdsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "expired",
                    })
                  );
                }}
                style={{
                  backgroundColor: expired ? "#E06030" : "#F1F1F1",
                  color: expired ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                Expired
              </ButtonForm>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {all ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px ##D4D4D4",
                  }}
                >
                  <AllAds
                    limit={10}
                    type={all}
                    offset={offset}
                    adsData={customAds}
                    countriesOption={countriesOptions}
                    like={like}
                    clearLike={() => setState({ ...state, like: "" })}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={adsCount ? adsCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : live ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px ##D4D4D4",
                  }}
                >
                  <LiveAds
                    limit={10}
                    offset={offset}
                    type={live}
                    countriesOption={countriesOptions}
                    adsData={customAds}
                    like={like}
                    clearLike={() => setState({ ...state, like: "" })}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={adsCount ? adsCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : draft ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px #1E1E2214",
                    borderRadius: "10px",
                  }}
                >
                  <DraftAds
                    adsData={customAds}
                    limit={10}
                    type={draft}
                    countriesOption={countriesOptions}
                    offset={offset}
                    clearLike={() => setState({ ...state, like: "" })}
                    like={like}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={adsCount ? adsCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : expired ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px #1E1E2214",
                    borderRadius: "10px",
                  }}
                >
                  <ExpiredAds
                    adsData={customAds}
                    limit={10}
                    offset={offset}
                    type={expired}
                    countriesOption={countriesOptions}
                    clearLike={() => setState({ ...state, like: "" })}
                    like={like}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={adsCount ? adsCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
      {modal && (
        <CreateAdModal
          offset={offset}
          modal={modal}
          countriesOptions={countriesOptions}
          topicsOptions={topicOptions}
          onCancel={() => setState({ ...state, modal: false })}
          type={{ all, live, draft, expired }}
        />
      )}
    </HeaderComponent>
  );
};

export default CustomAdManagement;
