//AUTH Constants

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_FAILURE = "SIGNOUT_FAILURE";

export const FORGETPASSWORD = "FORGETPASSWORD";
export const FORGETPASSWORD_SUCCESS = "FORGETPASSWORD_SUCCESS";
export const FORGETPASSWORD_FAILURE = "FORGETPASSWORD_FAILURE";

export const CHANGEPASSWORD = "CHANGEPASSWORD";
export const CHANGEPASSWORD_SUCCESS = "CHANGEPASSWORD_SUCCESS";
export const CHANGEPASSWORD_FAILURE = "CHANGEPASSWORD_FAILURE";

export const VERIFYCODE = "VERIFYCODE";
export const VERIFYCODE_SUCCESS = "VERIFYCODE_SUCCESS";
export const VERIFYCODE_FAILURE = "VERIFYCODE_FAILURE";

export const RESETPASSWORD = "RESETPASSWORD";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE";

//App Constants

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";

export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAILURE = "USER_DETAIL_FAILURE";

export const GETUSERSTATS = "USERSTATS";
export const GETUSERSTATS_SUCCESS = "USERSTATS_SUCCESS";
export const GETUSERSTATS_FAILURE = "USERSTATS_FAILURE";

export const GETEVENTSTATSCOUNT = "EVENTSTATS";
export const GETEVENTSTATSCOUNT_SUCCESS = "EVENTSTATS_SUCCESS";
export const GETEVENTSTATSCOUNT_FAILURE = "EVENTSTATS_FAILURE";

export const GETUSERTYPECOUNT = "USERTYPECOUNT";
export const GETUSERTYPECOUNT_SUCCESS = "USERTYPECOUNT_SUCCESS";
export const GETUSERTYPECOUNT_FAILURE = "USERTYPECOUNT_FAILURE";

export const GETSUBSCRIBEDUSERCOUNT = "SUBSCRIBEDUSERCOUNT";
export const GETSUBSCRIBEDUSERCOUNT_SUCCESS = "SUBSCRIBEDUSERCOUNT_SUCCESS";
export const GETSUBSCRIBEDUSERCOUNT_FAILURE = "SUBSCRIBEDUSERCOUNT_FAILURE";

export const GETAPPDOWNLOADCOUNT = "APPDOWGETAPPDOWNLOADCOUNT";
export const GETAPPDOWNLOADCOUNT_SUCCESS = "APPDOWGETAPPDOWNLOADCOUNT_SUCCESS";
export const GETAPPDOWNLOADCOUNT_FAILURE = "APPDOWGETAPPDOWNLOADCOUNT_FAILURE";

export const GETEVENTS = "GETEVENTS";
export const GETEVENTS_SUCCESS = "GETEVENTS_SUCCESS";
export const GETEVENTS_FAILURE = "GETEVENTS_FAILURE";

export const CLEARDASHBOARD = "CLEARDASHBOARD";

export const GETTRANSACTIONS = "GETTRANSACTIONS";
export const GETTRANSACTIONS_SUCCESS = "GETTRANSACTIONS_SUCCESS";
export const GETTRANSACTIONS_FAILURE = "GETTRANSACTIONS_FAILURE";

export const GETFORMS = "GETFORMS";
export const GETFORMS_SUCCESS = "GETFORMS_SUCCESS";
export const GETFORMS_FAILURE = "GETFORMS_FAILURE";

export const UPLOADFORMS = "UPLOADFORMS";
export const UPLOADFORMS_SUCCESS = "UPLOADFORMS_SUCCESS";
export const UPLOADFORMS_FAILURE = "UPLOADFORMS_FAILURE";

export const DELETEFORMS = "DELETEFORMS";
export const DELETEFORMS_SUCCESS = "DELETEFORMS_SUCCESS";
export const DELETEFORMS_FAILURE = "DELETEFORMS_FAILURE";

export const DELETEEVENTS = "DELETEEVENTS";
export const DELETEEVENTS_SUCCESS = "DELETEEVENTS_SUCCESS";
export const DELETEEVENTS_FAILURE = "DELETEEVENTS_FAILURE";
//Loader
export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";

export const CONFIG_AND_SETTINGS_REQUEST = "CONFIG_AND_SETTINGS_REQUEST";
export const CONFIG_AND_SETTINGS_SUCCESS = "CONFIG_AND_SETTINGS_SUCCESS";
export const CONFIG_AND_SETTINGS_FAILURE = "CONFIG_AND_SETTINGS_FAILURE";

export const UPDATE_CONFIG_AND_SETTINGS_REQUEST =
  "UPDATE_CONFIG_AND_SETTINGS_REQUEST";
export const UPDATE_CONFIG_AND_SETTINGS_SUCCESS =
  "UPDATE_CONFIG_AND_SETTINGS_SUCCESS";
export const UPDATE_CONFIG_AND_SETTINGS_FAILURE =
  "UPDATE_CONFIG_AND_SETTINGS_FAILURE";

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

export const NON_LIVE_REPORTS_REQUEST = "NON_LIVE_REPORTS_REQUEST";
export const NON_LIVE_REPORTS_SUCCESS = "NON_LIVE_REPORTS_SUCCESS";
export const NON_LIVE_REPORTS_FAILURE = "NON_LIVE_REPORTS_FAILURE";

export const COUNTRIES_REQUEST = "COUNTRIES_REQUEST";
export const COUNTRIES_SUCCESS = "COUNTRIES_SUCCESS";
export const COUNTRIES_FAILURE = "COUNTRIES_FAILURE";

export const LOCATIONS_REQUEST = "LOCATIONS_REQUEST";
export const LOCATIONS_SUCCESS = "LOCATIONS_SUCCESS";
export const LOCATIONS_FAILURE = "LOCATIONS_FAILURE";

export const UPDATE_LOCATION_REQUEST = "UPDATE_LOCATION_REQUEST";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAILURE = "UPDATE_LOCATION_FAILURE";

export const CONTENTS_REQUEST = "CONTENTS_REQUEST";
export const CONTENTS_SUCCESS = "CONTENTS_SUCCESS";
export const CONTENTS_FAILURE = "CONTENTS_FAILURE";

export const UPDATE_CONTENT_REQUEST = "UPDATE_CONTENT_REQUEST";
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS";
export const UPDATE_CONTENT_FAILURE = "UPDATE_CONTENT_FAILURE";

export const TOPICS_REQUEST = "TOPICS_REQUEST";
export const TOPICS_SUCCESS = "TOPICS_SUCCESS";
export const TOPICS_FAILURE = "TOPICS_FAILURE";

export const ADD_TOPIC_REQUEST = "ADD_TOPIC_REQUEST";
export const ADD_TOPIC_SUCCESS = "ADD_TOPIC_SUCCESS";
export const ADD_TOPIC_FAILURE = "ADD_TOPIC_FAILURE";

export const UPDATE_TOPIC_REQUEST = "UPDATE_TOPIC_REQUEST";
export const UPDATE_TOPIC_SUCCESS = "UPDATE_TOPIC_SUCCESS";
export const UPDATE_TOPIC_FAILURE = "UPDATE_TOPIC_FAILURE";

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";

export const ADD_PROFILE_REQUEST = "ADD_PROFILE_REQUEST";
export const ADD_PROFILE_SUCCESS = "ADD_PROFILE_SUCCESS";
export const ADD_PROFILE_FAILURE = "ADD_PROFILE_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const SUSPICIOUS_ACTIVITY_REQUEST = "SUSPICIOUS_ACTIVITY_REQUEST";
export const SUSPICIOUS_ACTIVITY_SUCCESS = "SUSPICIOUS_ACTIVITY_SUCCESS";
export const SUSPICIOUS_ACTIVITY_FAILURE = "SUSPICIOUS_ACTIVITY_FAILURE";

export const CUSTOM_ADS_REQUEST = "CUSTOM_ADS_REQUEST";
export const CUSTOM_ADS_SUCCESS = "CUSTOM_ADS_SUCCESS";
export const CUSTOM_ADS_FAILURE = "CUSTOM_ADS_FAILURE";

export const ADD_CUSTOM_AD_REQUEST = "ADD_CUSTOM_AD_REQUEST";
export const ADD_CUSTOM_AD_SUCCESS = "ADD_CUSTOM_AD_SUCCESS";
export const ADD_CUSTOM_AD_FAILURE = "ADD_CUSTOM_AD_FAILURE";

export const UPDATE_CUSTOM_AD_REQUEST = "UPDATE_CUSTOM_AD_REQUEST";
export const UPDATE_CUSTOM_AD_SUCCESS = "UPDATE_CUSTOM_AD_SUCCESS";
export const UPDATE_CUSTOM_AD_FAILURE = "UPDATE_CUSTOM_AD_FAILURE";

export const DELETE_CUSTOM_AD_REQUEST = "DELETE_CUSTOM_AD_REQUEST";
export const DELETE_CUSTOM_AD_SUCCESS = "DELETE_CUSTOM_AD_SUCCESS";
export const DELETE_CUSTOM_AD_FAILURE = "DELETE_CUSTOM_AD_FAILURE";

export const TOGGLE_AD_REQUEST = "TOGGLE_AD_REQUEST";
export const TOGGLE_AD_SUCCESS = "TOGGLE_AD_SUCCESS";
export const TOGGLE_AD_FAILURE = "TOGGLE_AD_FAILURE";

export const POST_DETAILS_REQUEST = "POST_DETAILS_REQUEST";
export const POST_DETAILS_SUCCESS = "POST_DETAILS_SUCCESS";
export const POST_DETAILS_FAILURE = "POST_DETAILS_FAILURE";

export const UPDATE_POST_REQUEST = " UPDATE_POST_REQUEST";
export const UPDATE_POST_SUCCESS = " UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAILURE = " UPDATE_POST_FAILURE";

export const GET_INTERACTION_REQUEST = " GET_INTERACTION_REQUEST";
export const GET_INTERACTION_SUCCESS = " GET_INTERACTION_SUCCESS";
export const GET_INTERACTION_FAILURE = " GET_INTERACTION_FAILURE";
