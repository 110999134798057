import React, { useEffect, useState } from "react";
import { Row, Col, Table, Switch } from "antd";
import { StatusBar } from "../../components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ButtonForm } from "../../components";
import { useMedia } from "use-media";
import { connect } from "react-redux";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { UserAction } from "../../store/actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const RegistrationUsers = (props) => {
  const { userType, offset, typee, like, userStatus, clearLike } = props;
  const dispatch = useDispatch();
  const updateUserLoader = useSelector(
    (state) => state?.UserReducer?.updateUserLoader
  );
  const [offsett, setOffsett] = useState(offset);
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const columns = [
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <p
          style={{
            color: "black",
            fontSize: isXxl ? "22px" : "14px",
            fontWeight: "bold",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      render: (text) => (
        <p style={{ fontWeight: "bold", fontSize: isXxl ? "20px" : "16px" }}>
          {text}
        </p>
      ),
    },
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <p style={{ color: "black", fontSize: isXxl ? "18px" : "14px" }}>
          {text}
        </p>
      ),
    },
    {
      title: "Location",
      dataIndex: "country",
      key: "country",
      render: (text) => (
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: isXxl ? "22px" : "16px",
          }}
        >
          {text?.name}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (e, record) => (
        <Row gutter={[10, 10]} justify="center">
          <Col xs={24} sm={24} md={12} lg={12} xl={10} xxl={9}>
            <ButtonForm
              isRegistration
              loading={updateUserLoader}
              onClick={() => {
                dispatch(
                  UserAction.UpdateUserStatus(
                    {
                      limit: 10,
                      offset,
                      type: "registration",
                      user_id: record.id,
                      status: "true",
                      current_time: moment().format("MM-DD-YYYY hh:mm:ss"),
                    },

                    () => {
                      clearLike();
                      dispatch(
                        UserAction.GetUsersRequest({
                          limit: 10,
                          offset,
                          type: "registration",
                        })
                      );
                    }
                  )
                );
              }}
            >
              Approve
            </ButtonForm>
          </Col>
          <Col xs={24} sm={24} md={12} lg={14} xl={10} xxl={9}>
            <ButtonForm
              isRegistration
              loading={updateUserLoader}
              onClick={() => {
                dispatch(
                  UserAction.UpdateUserStatus(
                    {
                      limit: 10,
                      offset,
                      type: "registration",
                      user_id: record.id,
                      status: "false",
                      current_time: moment().format("MM-DD-YYYY hh:mm:ss"),
                    },
                    () =>
                      dispatch(
                        UserAction.GetUsersRequest({
                          limit: 10,
                          offset,
                          type: "registration",
                        })
                      )
                  )
                );
              }}
              style={{ backgroundColor: "#415465" }}
            >
              Deny
            </ButtonForm>
          </Col>
        </Row>
      ),
    },
  ];
  const [columnsNew, setColumnsNew] = useState(columns);
  useEffect(() => {
    setColumnsNew(columnsNew);
  }, [updateUserLoader]);
  useEffect(() => {
    setOffsett(offset);
  }, [offset]);
  const history = useHistory(props);
  const { userData, userTableLoader } = props;
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
      <Table
        style={{ cursor: "pointer" }}
        columns={columnsNew}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => {
        //       history.push({
        //         pathname: `/users/user-details/${record.id}`,
        //         state: {
        //           id: record.id,
        //         },
        //       });
        //     },
        //   };
        // }}
        pagination={false}
        dataSource={props.userData}
        // loading={{ spinning: userTableLoader, indicator: antIcon }}
      />
    </Col>
  );
};

export default RegistrationUsers;
