import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
  NON_LIVE_REPORTS_REQUEST,
  NON_LIVE_REPORTS_SUCCESS,
  NON_LIVE_REPORTS_FAILURE,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAILURE,
  SUSPICIOUS_ACTIVITY_REQUEST,
  SUSPICIOUS_ACTIVITY_FAILURE,
  SUSPICIOUS_ACTIVITY_SUCCESS,
  GET_INTERACTION_REQUEST,
  GET_INTERACTION_SUCCESS,
  GET_INTERACTION_FAILURE,
} from "../../../store/constants";

export default class DashboardAction {
  static DashboardRequest(params) {
    return {
      type: DASHBOARD_REQUEST,
      params,
    };
  }
  static DashboardSuccess(payload) {
    return {
      type: DASHBOARD_SUCCESS,
      payload,
    };
  }
  static DashboardFailure() {
    return {
      type: DASHBOARD_FAILURE,
    };
  }

  static GetInteractionData(payload){
    return{
      type: GET_INTERACTION_REQUEST,
      payload
    }
  }
  static GetInteractionData_Success(payload){
    return{
      type: GET_INTERACTION_SUCCESS,
      payload
    }
  }
  static GetInteractionData_Failure(payload){
    return{
      type: GET_INTERACTION_FAILURE,
      payload
    }
  }

  static NonLiveReportsRequest(params) {
    return {
      type: NON_LIVE_REPORTS_REQUEST,
      params,
    };
  }
  static NonLiveReportsSuccess(payload) {
    return {
      type: NON_LIVE_REPORTS_SUCCESS,
      payload,
    };
  }
  static NonLiveReportsFailure() {
    return {
      type: NON_LIVE_REPORTS_FAILURE,
    };
  }

  static CountriesRequest() {
    return {
      type: COUNTRIES_REQUEST,
    };
  }
  static CountriesSuccess(payload) {
    return {
      type: COUNTRIES_SUCCESS,
      payload,
    };
  }
  static CountriesFailure() {
    return {
      type: COUNTRIES_FAILURE,
    };
  }

  static SuspiciousActivityRequest() {
    return {
      type: SUSPICIOUS_ACTIVITY_REQUEST,
    };
  }
  static SuspiciousActivitySuccess(payload) {
    return {
      type: SUSPICIOUS_ACTIVITY_SUCCESS,
      payload,
    };
  }
  static SuspiciousActivityFailure() {
    return {
      type: SUSPICIOUS_ACTIVITY_FAILURE,
    };
  }
}
