import React, { useEffect, useState } from "react";
import { Row, Col, Table, Switch, Radio, Modal } from "antd";
import { StatusBar, ButtonForm } from "../../components";
import { useHistory } from "react-router-dom";
import { useMedia } from "use-media";
import { useDispatch, useSelector } from "react-redux";
import medal from "../../assets/images/medal.png";
import SwitchWrapper from "../../components/switch";
import CreateAdModal from "./CreateAdModal";
import CustomAdManagementAction from "../../store/actions/appactions/CustomAdsManagementAction";
import deleteCustomAd from "../../assets/images/deleteCustomAd.svg";
import editCustomAd from "../../assets/images/editCustomAd.svg";
import { connect } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import { withRouter } from "react-router-dom";
import { UserAction } from "../../store/actions";
import DeleteAdModal from "./DeleteAdModal";
import EditBasicModal from "./EditBasicModal";
import EditPremiumModal from "./EditPremiumModal";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const DraftAds = (props) => {
  const {
    userType,
    offset,
    typee,
    like,
    userStatus,
    clearLike,
    customStyles,
    adsData,
    countriesOption,
  } = props;
  const updateUserLoader = useSelector(
    (state) => state.UserReducer.updateUserLoader
  );

  const ids = adsData.map((item) => {
    return item.id;
  });

  const dispatch = useDispatch();
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });

  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  const [state, setState] = useState({
    modal: false,
    modal1: false,
    userId: 0,
    tabType: "",
  });
  const [selectedRow, setSelectedRow] = useState({});
  const { modal, modal1, userId, tabType } = state;
  const onCancel = () => {
    setState({ ...state, modal: false, modal1: false });
  };
  const columns = [
    {
      title: "Ad",
      dataIndex: "",
      key: "custom_add_primary",
      render: (text) => {
        return text.type == "basic" ? (
          <img
            src={text?.custom_add_primary?.thumb_url}
            style={{
              borderRadius: "10px",
              width: isXxl ? "100px" : "70px",
              height: isXxl ? "100px" : "70px",
            }}
          />
        ) : (
          <>
            <div style={{ position: "relative" }}>
              <img
                src={text?.custom_add_primary?.thumb_url}
                style={{
                  borderRadius: "10px",
                  width: isXxl ? "100px" : "70px",
                  height: isXxl ? "100px" : "70px",
                }}
              />
              <img
                src={medal}
                style={{ position: "absolute", bottom: -13, right: -10 }}
              />
            </div>
            <div style={{ position: "relative", marginTop: "20px" }}>
              <img
                src={text?.custom_add_secondary?.thumb_url}
                style={{
                  borderRadius: "10px",
                  width: isXxl ? "100px" : "70px",
                  height: isXxl ? "100px" : "70px",
                }}
              />
              <img
                src={medal}
                style={{ position: "absolute", bottom: -13, right: -10 }}
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Ad Id",
      dataIndex: "",
      key: "custom_add_id",
      render: (text) => {
        return text.type == "basic" ? (
          <p
            style={{
              color: "black",
              fontSize: isXxl ? "22px" : "14px",
              fontWeight: "bold",
            }}
          >
            {text?.custom_add_primary?.id}
          </p>
        ) : (
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_primary?.id}
              </p>
            </Col>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_secondary?.id}
              </p>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Linked Id",
      dataIndex: "",
      key: "",
      render: (text) =>
        text.type == "basic" ? (
          <p
            style={{
              color: "black",
              fontSize: isXxl ? "22px" : "14px",
              fontWeight: "bold",
            }}
          >
            -
          </p>
        ) : (
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text.other_custom_add_id == null
                  ? "-"
                  : text.other_custom_add_id}
              </p>
            </Col>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text.custom_add_id == null ? "-" : text.custom_add_id}
              </p>
            </Col>
          </Row>
        ),
    },

    {
      title: "Views",
      dataIndex: "",
      key: "",
      render: (text) => {
        return text.type == "basic" ? (
          <p
            style={{
              color: "black",
              fontSize: isXxl ? "22px" : "14px",
              fontWeight: "bold",
            }}
          >
            {text?.custom_add_primary?.views}
          </p>
        ) : (
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_primary?.views}
              </p>
            </Col>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_primary?.views}
              </p>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Favourite",
      dataIndex: "",
      key: "",
      render: (text) => {
        return text.type == "basic" ? (
          <p
            style={{
              color: "black",
              fontSize: isXxl ? "22px" : "14px",
              fontWeight: "bold",
            }}
          >
            {text?.custom_add_primary?.add_like_count}
          </p>
        ) : (
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_primary?.add_like_count}
              </p>
            </Col>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_secondary?.add_like_count}
              </p>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Unfavourite",
      dataIndex: "",
      key: "",
      render: (text) => {
        return text.type == "basic" ? (
          <p
            style={{
              color: "black",
              fontSize: isXxl ? "22px" : "14px",
              fontWeight: "bold",
            }}
          >
            {text?.custom_add_primary?.add_dislike_count}
          </p>
        ) : (
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_primary?.add_dislike_count}
              </p>
            </Col>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_secondary?.add_dislike_count}
              </p>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Clicks",
      dataIndex: "",
      key: "",
      render: (text) => {
        return text.type == "basic" ? (
          <p
            style={{
              color: "black",
              fontSize: isXxl ? "22px" : "14px",
              fontWeight: "bold",
            }}
          >
            {text?.custom_add_primary?.clicks}
          </p>
        ) : (
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_primary?.clicks}
              </p>
            </Col>
            <Col span={24}>
              <p
                style={{
                  color: "black",
                  fontSize: isXxl ? "22px" : "14px",
                  fontWeight: "bold",
                }}
              >
                {text?.custom_add_secondary?.clicks}
              </p>
            </Col>
          </Row>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "",
      width: "15%",
      key: "custom_add_primary",
      render: (text) => (
        <Row align="middle">
          <Col
            style={{
              borderRadius: "20px",
              backgroundColor:
                text?.custom_add_primary?.status &&
                text?.custom_add_secondary?.status == "published"
                  ? "#E06030"
                  : text?.custom_add_primary?.status &&
                    text?.custom_add_secondary?.status == "expired"
                  ? "#009498"
                  : "#F5BA3C",
              color: "#FFFFFF",
              height: isXxl ? "40px" : "42px",
              fontSize: isXxl || isXl ? "16px" : "14px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              textTransform: "capitalize",
            }}
            span={24}
          >
            draft
          </Col>
        </Row>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "mapper_id",
      render: (text, record) => {
        return (
          <Row gutter={[10, 10]} align="middle" justify="center">
            <Col>
              <SwitchWrapper
                loading={updateUserLoader}
                checked={
                  text.custom_add_primary.status == "published" ? true : false
                }
                onChange={(e) => {
                  dispatch(
                    CustomAdManagementAction.ToggleAdRequest(
                      {
                        id: text?.mapper_id,
                      },
                      () => {
                        dispatch(
                          CustomAdManagementAction.CustomAdsRequest({
                            filter: "draft",
                            limit: 10,
                            offset: offset,
                          })
                        );
                      }
                    )
                  );
                }}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      render: (text) => (
        <Row align="middle" gutter={[20, 0]}>
          <Col>
            <img
              onClick={() => {
                setSelectedRow({ ...text });
                setState({ ...state, modal: true, tabType: text?.type });
              }}
              src={editCustomAd}
              style={{
                cursor: "pointer",
                width: isXxl ? "25.73px" : "20px",
                height: isXxl ? "25.73px" : "20px",
                objectFit: "cover",
              }}
            ></img>
          </Col>
          <Col>
            <img
              onClick={() => {
                setSelectedRow({ ...text });
                setState({ ...state, modal1: true });
              }}
              src={deleteCustomAd}
              style={{
                cursor: "pointer",
                width: isXxl ? "25.73px" : "20px",
                height: isXxl ? "25.73px" : "20px",
                objectFit: "cover",
              }}
            ></img>
          </Col>
        </Row>
      ),
    },
  ];
  const [columnsNew, setColumnsNew] = useState(columns);
  useEffect(() => {
    setColumnsNew(columns);
  }, [offset]);
  const history = useHistory();
  const { userData, userTableLoader } = props;
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
      <Table
        style={{
          borderRadius: "30px",
        }}
        columns={columnsNew}
        pagination={false}
        dataSource={adsData}
        // loading={{ spinning: userTableLoader, indicator: antIcon }}
      />
      <DeleteAdModal
        modal={modal1}
        onCancel={onCancel}
        data={selectedRow}
        offset={offset}
        isDraft
      />
      {tabType == "basic" ? (
        <EditBasicModal
          tabType={tabType}
          type="draft"
          data={selectedRow}
          customStyles={customStyles}
          offset={offset}
          modal={modal}
          onCancel={onCancel}
          countriesOption={countriesOption}
        />
      ) : (
        <EditPremiumModal
          tabType={tabType}
          type="draft"
          data={selectedRow}
          customStyles={customStyles}
          offset={offset}
          modal={modal}
          onCancel={onCancel}
          countriesOption={countriesOption}
        />
      )}
    </Col>
  );
};

export default DraftAds;
