import React, { useState } from "react";
import { Layout, Row, Col } from "antd";
import Image from "../image";
import { useMedia } from "use-media";
import Modal from "../modal/index";
import { Link } from "react-router-dom";
import { Colors, Images } from "../../config";
import "../../index.css";
const { Footer } = Layout;
const FooterNav = () => {
  const pathName = window.location.pathname;
  const isUser = pathName.includes("users");
  const isProfile = pathName.includes("profile");
  const isAd = pathName.includes("custom-ad-management");
  const isContent = pathName.includes("content-management");
  const isTopic = pathName.includes("topic-management");
  const isLocation = pathName.includes("location-management");
  const isSettings = pathName.includes("configuration-and-settings");
  const isDashboard =
    !isUser &&
    !isContent &&
    !isAd &&
    !isTopic &&
    !isProfile &&
    !isLocation &&
    !isSettings;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    localStorage.removeItem("eventauth");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  return (
    <>
      <Footer
        style={{
          backgroundColor: "#E06030",
          boxShadow: "0px 5px 20px #1E1E220C",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Row justify="space-between">
          <Col style={{ width: "11.111%" }}>
            <Link to="/profile">
              <Image
                src={isProfile ? Images.profileLogoClicked : Images.profileLogo}
                style={{ cursor: "pointer" }}
              ></Image>
            </Link>
          </Col>
          <Col style={{ width: "11.111%" }}>
            <Link to="/">
              <Image
                src={
                  isDashboard
                    ? Images.dashboardClicked
                    : Images.dashboardDashboard
                }
                style={{ cursor: "pointer" }}
              ></Image>
            </Link>
          </Col>
          <Col style={{ width: "11.111%" }}>
            <Link to="/users">
              <Image
                src={
                  isUser ? Images.dashboardUsersClicked : Images.dashboardUsers
                }
                style={{ cursor: "pointer" }}
              ></Image>
            </Link>
          </Col>
          <Col style={{ width: "11.111%" }}>
            <Link to="/content-management">
              <Image
                src={
                  isContent
                    ? Images.dashboardContentClicked
                    : Images.dashboardContent
                }
                style={{ cursor: "pointer" }}
              ></Image>
            </Link>
          </Col>
          <Col style={{ width: "11.111%" }}>
            <Link to="/topic-management">
              <Image
                src={isTopic ? Images.topicNewIconClicked : Images.topicNewIcon}
                style={{ cursor: "pointer" }}
              ></Image>
            </Link>
          </Col>
          <Col style={{ width: "11.111%" }}>
            <Link to="/location-management">
              <Image
                src={
                  isLocation
                    ? Images.dashboardLocationClicked
                    : Images.dashboardLocation
                }
                style={{ cursor: "pointer" }}
              ></Image>
            </Link>
          </Col>
          <Col style={{ width: "11.111%" }}>
            <Link to="/custom-ad-management">
              <Image
                src={isAd ? Images.dashboardAdClicked : Images.adManagement}
                style={{ cursor: "pointer" }}
              ></Image>
            </Link>
          </Col>
          <Col style={{ width: "11.111%" }}>
            <Link to="/configuration-and-settings">
              <Image
                src={
                  isSettings
                    ? Images.dashboardSettingsClicked
                    : Images.dashboardSettings
                }
                style={{ cursor: "pointer" }}
              ></Image>
            </Link>
          </Col>
          <Col style={{ width: "11.111%" }} onClick={showModal}>
            <Link>
              <Image
                src={Images.dashboardLogout}
                style={{ cursor: "pointer" }}
              ></Image>
            </Link>
          </Col>
        </Row>
      </Footer>
      <Modal
        closable={false}
        // title="Basic Modal"
        visible={isModalVisible}
        width={isXxl ? "721px" : isXl ? "600px" : "400px"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {/* <Row>
          <Col span={24}>
            <Row>
              <Col></Col>
              <Col></Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}></Col>
              <Col span={24}></Col>
            </Row>
          </Col>
        </Row> */}
      </Modal>
    </>
  );
};

export default React.memo(FooterNav);
