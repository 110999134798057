import React from 'react';
import styled from "styled-components";
import {Button} from 'antd';
import {Colors} from "../../config";

const StyledBox = styled.div`
  background-color: ${Colors.Primary};
  color: ${Colors.White};
  border-radius: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  //overflow-wrap: break-word;
`;

const FileBox = (props) => {
  return <StyledBox  {...props}></StyledBox>;
}

export default React.memo(FileBox)