import React, { useState } from "react";
import { Row, Col, Table, Space } from "antd";
import { ModalComponent } from "../../components";
import { StatusBar } from "../../components";
import moment from "moment";
import { RiDeleteBinLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { EventAction } from "../../store/actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const EventTable = (props) => {
  const { eventData, limit, offset, like } = props;
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState("");
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: "Events Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Event Manager Name",
      dataIndex: "eventManagerName",
      key: "eventManagerName",
      render: (text) => <p>{text ? text : "-"}</p>,
    },
    {
      title: "Date/Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <p>{moment(text).format("YYYY-MM-DD/HH:mm:ss")}</p>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => <p>{text ? text : "-"}</p>,
    },
    {
      title: "No. of Riders",
      dataIndex: "RiderCount",
      key: "RiderCount",
      render: (text, record) => <p>{text ? text : "-"}</p>,
    },
    {
      title: "No. of Judges",
      dataIndex: "JudgeCount",
      key: "JudgeCount",
      render: (text, record) => <p>{text ? text : "-"}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Row justify="center">
          <StatusBar status={record.status} />
        </Row>
      ),
    },
    {
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <RiDeleteBinLine
            style={{ fontSize: "25px" }}
            onClick={() => {
              setId(record.id);
              showModal();
            }}
          />
        </Space>
      ),
    },
  ];
  const { eventTableLoader } = props;
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
      <Table
        style={{ cursor: "pointer" }}
        columns={columns}
        pagination={false}
        dataSource={eventData}
        loading={{ spinning: eventTableLoader, indicator: antIcon}}
      />
      <ModalComponent
        id={id}
        limit={limit}
        offset={offset}
        like={like}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        centered
        closable={false}
        footer={null}
        content={
          <p
            style={{ fontWeight: "bold", fontSize: "22px", marginLeft: "27px" }}
          >
            Are you sure you want to delete that event?
          </p>
        }
      />
    </Col>
  );
};
export default React.memo(
  withRouter(
    connect((storeState) => ({
      eventTableLoader: storeState.EventReducer.eventTableLoader,
    }))(EventTable)
  )
);
