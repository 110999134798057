import { Row, Col } from "antd";
import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { useMedia } from "use-media";
import { Colors } from "../../config";
import LegendBox from "../ChartBox/LegendBox";

const COLORS = [
  Colors.SeaGreen,
  Colors.Yellow,
  Colors.Primary,
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={document.body.clientWidth <= 770 ? 7 : 14}
    >
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};
const CustomTooltip = ({ payload }) => {
  return (
    <Col
      style={{
        margin: "0px",
        padding: "10px",
        backgroundColor: "rgb(255, 255, 255)",
        border: "1px solid rgb(204, 204, 204)",
        whiteSpace: "nowrap",
        height: "2.8rem",
      }}
      span={24}
    >
      <p
        className="recharts-tooltip-label"
        style={{
          color:
            payload?.[0]?.payload?.name == "Event Manager"
              ? Colors.Secondary
              : payload?.[0]?.payload?.name == "Rider"
              ? Colors.Cyan
              : Colors.LightRed,
        }}
      >
        {payload?.[0]?.payload?.name + ": " + payload?.[0]?.payload?.value}
      </p>
    </Col>
  );
};

class PieChartComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static demoUrl =
    "https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj";
  renderLegend = (props) => {
    const { payload } = props;
    const COLORS = [
      Colors.Cyan,
      Colors.Secondary,
      Colors.LightRed,
      Colors.Blue,
    ];
    return (
      <Row
        style={{
          display: "flex",
        }}
        justify="center"
        align="middle"
      >
        {payload.map((entry, index) => (
          <div
            style={{
              display: "flex",
              marginLeft: "1.5rem",
              marginRight: "1.5rem",
            }}
          >
            <Col
              style={{
                borderRadius: 15,
                width: 15,
                height: 15,
                backgroundColor: entry.color,
                margin: 15,
              }}
            ></Col>
            <p
              style={{
                color: entry.color,
                display: "flex",
                justifyContent: "center",
                marginTop: "12px",
                fontSize: "0.8rem",
              }}
            >
              {"       " + entry.value}
            </p>
          </div>
        ))}
      </Row>
    );
  };
  render() {
    const { isWide, isLow, newData, isXs, isSm, isMd, isLg, isXl, isXxl } =
      this.props;
    return (
      <>
        {newData.length ? (
          <Row gutter={[20, 0]} justify="space-between">
            <Col
              xs={18}
              sm={16}
              md={14}
              lg={14}
              xl={18}
              xxl={16}
              align="middle"
              style={{ marginTop: "0.5rem" }}
            >
              <ResponsiveContainer width="100%" height={390}>
                <PieChart
                  // width={isXxl || isXl ? 400 : isLg ? 300 : 500}
                  height={400}
                >
                  <Pie
                    data={newData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    // outerRadius={isWide ? 130 : !isLow ? 88 : 91}
                    margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
                    innerRadius={isXxl || isXl ? 120 : isLg ? 80 : 120}
                    outerRadius={isXxl || isXl ? 180 : isLg ? 150 : 170}
                    fill="#009498"
                    dataKey="value"
                  >
                    {(newData || [])?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={8}
              lg={8}
              xl={6}
              xxl={8}
              style={{
                marginTop: "2rem",
                overflowX: "auto",
                height: "340px",
              }}
            >
              <Row>
                {(newData || []).map((item, index) => (
                  <Col span={this.props.isPostSelected ? 24 : 12}>
                    {!item.name ? null : (
                      <LegendBox
                        color={COLORS[index % COLORS.length]}
                        title={item.name ? item.name.split("_").join(" ") : ""}
                      />
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ) : (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ height: "100%" }}
          >
            <Col span={24} align="center">
              <h4>No Data exists with these filters</h4>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const MediaWrapper = (props) => {
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <PieChartComponent
      isXs={isXs}
      isSm={isSm}
      isMd={isMd}
      isLg={isLg}
      isXl={isXl}
      isXxl={isXxl}
      {...props}
    />
  );
};

export default React.memo(MediaWrapper);
