import {
  CONTENTS_REQUEST,
  CONTENTS_SUCCESS,
  CONTENTS_FAILURE,
  UPDATE_CONTENT_REQUEST,
  UPDATE_CONTENT_SUCCESS,
  UPDATE_CONTENT_FAILURE,
  POST_DETAILS_REQUEST,
  POST_DETAILS_SUCCESS,
  POST_DETAILS_FAILURE,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAILURE,
} from "../../constants";

const initialState = {
  contentManagementUsers: [],
  postDetails: [],
  userCount: 0,
  getContentsLoader: false,
  updateContentLoader: false,
  postDetailsLoader: false,
  updatePostLoader: false,
};

export default function ConfigAndSettingReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENTS_REQUEST:
      state = {
        ...state,
        contentManagementUsers: [],
        getContentsLoader: true,
      };
      break;

    case CONTENTS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          contentManagementUsers: action.payload ? action?.payload : [],
          userCount: action.count ? action.count : 0,
          getContentsLoader: false,
        };
      }
    }

    case CONTENTS_FAILURE:
      state = {
        ...state,
        getContentsLoader: false,
      };
      break;

    case POST_DETAILS_REQUEST:
      state = {
        ...state,
        postDetails: [],
        postDetailsLoader: true,
      };
      break;

    case POST_DETAILS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          postDetails: action.payload ? action?.payload : [],
          postDetailsLoader: false,
        };
      }
    }

    case POST_DETAILS_FAILURE:
      state = {
        ...state,
        postDetailsLoader: false,
      };
      break;

    case UPDATE_CONTENT_REQUEST:
      state = {
        ...state,
        updateContentLoader: true,
      };
      break;

    case UPDATE_CONTENT_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          updateContentLoader: false,
        };
      }
      break;

    case UPDATE_CONTENT_FAILURE:
      state = {
        ...state,
        updateContentLoader: false,
      };
      break;

    case UPDATE_POST_REQUEST:
      state = {
        ...state,
        updatePostLoader: true,
      };
      break;

    case UPDATE_POST_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          updatePostLoader: false,
        };
      }
      break;

    case UPDATE_POST_FAILURE:
      state = {
        ...state,
        updatePostLoader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
