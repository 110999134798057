import { FormAction } from "../../actions";
import { ApiCaller } from "../../../config";
import {
  GETFORMS_ENDPOINT,
  UPLOADFORM_ENDPOINT,
  DELETEFORM_ENDPOINT,
} from "../../../config/variables";
import Info from "../../../components/message";
import { put } from "redux-saga/effects";
export default class FormMiddleware {
  static *GetForms(params) {
    const { cb, offset, limit } = params;
    try {
      let res = yield ApiCaller.Get(
        `${GETFORMS_ENDPOINT}?offset=${offset}&limit=${limit}`
      );
      if (res.status == 200) {
        yield put(FormAction.GetFormsSuccess(res.data.data));
        if (cb) {
          cb(true);
        }
      } else {
        yield put(FormAction.GetFormsFailure());
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(FormAction.GetFormsFailure());
    }
  }

  static *UploadForms(params) {
    const { payload, cb, limit, offset } = params;
    const { file } = payload;
    let bodyAPI = {
      file: file,
      name: file.name,
    };
    let formData = new FormData();

    for (const key in bodyAPI) {
      formData.append(key, bodyAPI[key]);
    }
    try {
      let res = yield ApiCaller.Put(UPLOADFORM_ENDPOINT, formData);
      if (res.status == 200) {
        yield put(FormAction.UploadFormsSuccess(res.data.data));
        Info("success", "Form Uploaded");
        if (cb) {
          cb(true);
        }
        yield put(FormAction.GetForms(limit, offset));
      } else {
        yield put(FormAction.UploadFormsFailure());
        Info("error", res.data.error.message);
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(FormAction.UploadFormsFailure());
    }
  }

  static *DeleteForms(params) {
    const { payload, cb, limit, offset } = params;
    const { id } = payload;
    try {
      let res = yield ApiCaller.Delete(`${DELETEFORM_ENDPOINT}/${id}`);
      if (res.status == 200) {
        yield put(FormAction.DeleteFormsSuccess(res.data.data));
        Info("success", res.data.data.message);
        if (cb) {
          cb(true);
        }
        yield put(FormAction.GetForms(limit, offset));
      } else {
        yield put(FormAction.DeleteFormsFailure());
        Info("error", res.data.error.message);
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(FormAction.DeleteFormsFailure());
    }
  }
}
