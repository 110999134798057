import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Dashboard,
  Forms,
  Users,
  Profile,
  TopicManagement,
  LocationManagement,
  ContentManagement,
  ConfigurationAndSettings,
  UserDetails,
  Events,
  Transactions,
  CustomAdManagement,
} from "../../containers";
import EditProfile from "../../containers/Profile/EditProfile";
import ChangePassword from "../../containers/Profile/ChangePassword";
class AppRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/forms" component={Forms} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/topic-management" component={TopicManagement} />
          <Route exact path="/profile/edit-profile" component={EditProfile} />
          <Route
            exact
            path="/profile/change-password"
            component={ChangePassword}
          />
          <Route
            exact
            path="/content-management"
            component={ContentManagement}
          />
          <Route
            exact
            path="/location-management"
            component={LocationManagement}
          />
          <Route
            exact
            path="/configuration-and-settings"
            component={ConfigurationAndSettings}
          />
          <Route
            exact
            path="/custom-ad-management"
            component={CustomAdManagement}
          />

          <Route exact path="/users/user-details/:id" component={UserDetails} />
          <Route
            exact
            path="/content-management/user-details/:id"
            component={UserDetails}
          />
          <Route exact path="/events" component={Events} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/transactions" component={Transactions} />
          <Route exact path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

export default connect((state) => ({ storeState: state }))(AppRoutes);
