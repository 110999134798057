import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Menu } from "antd";
import Image from "../image";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Colors, Images } from "../../config";
import loginBackgroundArrow from "../../../src/assets/images/loginBackgroundArrow.svg";
import toggledArrow from "../../../src/assets/images/toggledArrow.svg";
import { ProfileAction } from "../../store/actions";
import { useMedia } from "use-media";
import Modal from "../modal/index";
import "./styles.css";
import { FiMoreVertical, FiMoreHorizontal } from "react-icons/fi";
import "../../index.css";
const { Sider } = Layout;
const NavigationBar = () => {
  const pathName = window.location.pathname;
  const [collapsed, setCollapsed] = useState(true);
  //console.log("pathname is:", pathName.includes("/event_pluz_admin"));
  const isUser = pathName.includes("users");
  const isProfile = pathName.includes("profile");
  const isAd = pathName.includes("custom-ad-management");
  const isContent = pathName.includes("content-management");
  const isTopic = pathName.includes("topic-management");
  const isLocation = pathName.includes("location-management");
  const isSettings = pathName.includes("configuration-and-settings");
  const isDashboard =
    !isUser &&
    !isContent &&
    !isAd &&
    !isTopic &&
    !isProfile &&
    !isLocation &&
    !isSettings;
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    localStorage.removeItem("eventauth");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const dispatch = useDispatch();

  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  useEffect(() => {
    dispatch(ProfileAction.ProfileRequest());
  }, []);
  const profile = useSelector((state) => state.ProfileReducer.profile);

  const { email } = profile;
  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          backgroundImage:
            "linear-gradient(#04737B, #023F43,#01767E,#01828B,#02484D,#023A3E)",
          boxShadow: "0px 5px 20px #1E1E220C",
          minHeight: isXxl ? "120vh" : "150vh",
          borderRadius: "0px 78px 78px 0px",
          backgroundColor: "#FFFFFF",
        }}
        width={isXxl ? "438" : "270"}
        collapsedWidth={isXxl ? "138" : "95"}
        inlineCollapsed={collapsed}
      >
        <Col span={24}>
          <Row
            gutter={!collapsed && isXl ? [0, 20] : [0, 25]}
            justify="center"
            style={{
              backgroundColor: "#E06030",
              backgroundImage: !collapsed ? `url(${toggledArrow})` : "",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top right",
              borderRadius: "0px 67px 0px 0px",
              height: "303px",
            }}
          >
            {!collapsed ? (
              <Col span={22} align="right" style={{ marginTop: "2rem" }}>
                <FiMoreVertical
                  size={34}
                  color="#FFFFFF"
                  onClick={toggleCollapsed}
                />
              </Col>
            ) : (
              <Col span={24}>
                <FiMoreHorizontal
                  size={34}
                  color="#FFFFFF"
                  onClick={toggleCollapsed}
                />
              </Col>
            )}
            <Col span={24} style={{ marginTop: "16px" }}>
              <Row justify="start">
                {collapsed ? (
                  <Col span={24}>
                    <Image src={Images.Logo}></Image>
                  </Col>
                ) : (
                  <Col xl={20} xxl={18}>
                    <Image src={Images.toggledLogo}></Image>
                  </Col>
                )}
              </Row>
            </Col>

            <Col
              span={24}
              style={{ marginTop: !collapsed && isXxl ? "37px" : "70px" }}
            >
              <Row gutter={[0, 50]}>
                <Col span={24}>
                  {!collapsed ? (
                    <Link to="/profile">
                      <Row justify="space-between" align="middle">
                        <Col md={4} lg={4} xl={4} xxl={7} align="right">
                          <Image
                            src={
                              isProfile
                                ? Images.profileLogoClicked
                                : Images.profileLogo
                            }
                            style={{ cursor: "pointer" }}
                          ></Image>
                        </Col>
                        <Col
                          md={17}
                          lg={17}
                          xl={18}
                          xxl={15}
                          style={{ color: "#FFFFFF", fontSize: "20px" }}
                          align="left"
                        >
                          <Row>
                            <Col
                              span={24}
                              style={{
                                fontSize: isXxl
                                  ? "32px"
                                  : isXl
                                  ? "22px"
                                  : "26px",
                              }}
                            >
                              Admin
                            </Col>
                            <Col
                              span={24}
                              style={{
                                fontSize: isXxl
                                  ? "14px"
                                  : isXl
                                  ? "10px"
                                  : "12px",
                              }}
                            >
                              {email}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Link>
                  ) : (
                    <Link to="/profile">
                      <Image
                        src={
                          isProfile
                            ? Images.profileLogoClicked
                            : Images.profileLogo
                        }
                        style={{ cursor: "pointer" }}
                      ></Image>
                    </Link>
                  )}
                </Col>
                <Col span={24}>
                  {!collapsed ? (
                    <Link to="/">
                      <Row justify="space-between" align="middle">
                        <Col md={4} lg={4} xl={4} xxl={7} align="right">
                          <Image
                            src={
                              isDashboard
                                ? Images.dashboardClicked
                                : Images.dashboardDashboard
                            }
                            style={{ cursor: "pointer" }}
                          ></Image>
                        </Col>
                        <Col
                          md={17}
                          lg={17}
                          xl={18}
                          xxl={15}
                          style={{
                            color: "#FFFFFF",
                            fontSize: isXxl ? "20px" : isXl ? "15px" : "18px",
                          }}
                          align="left"
                        >
                          Dashboard
                        </Col>
                      </Row>
                    </Link>
                  ) : (
                    <Link to="/">
                      <Image
                        src={
                          isDashboard
                            ? Images.dashboardClicked
                            : Images.dashboardDashboard
                        }
                        style={{ cursor: "pointer" }}
                      ></Image>
                    </Link>
                  )}
                </Col>
                <Col span={24}>
                  {!collapsed ? (
                    <Link to="/users">
                      <Row justify="space-between">
                        <Col md={4} lg={4} xl={4} xxl={7} align="right">
                          <Image
                            src={
                              isUser
                                ? Images.dashboardUsersClicked
                                : Images.dashboardUsers
                            }
                            style={{ cursor: "pointer" }}
                          ></Image>
                        </Col>
                        <Col
                          md={17}
                          lg={17}
                          xl={18}
                          xxl={15}
                          style={{
                            color: "#FFFFFF",
                            fontSize: isXxl ? "20px" : isXl ? "15px" : "18px",
                          }}
                          align="left"
                        >
                          User Management
                        </Col>
                      </Row>
                    </Link>
                  ) : (
                    <Link to="/users">
                      <Image
                        src={
                          isUser
                            ? Images.dashboardUsersClicked
                            : Images.dashboardUsers
                        }
                        style={{ cursor: "pointer" }}
                      ></Image>
                    </Link>
                  )}
                </Col>
                <Col span={24}>
                  {!collapsed ? (
                    <Link to="/content-management">
                      <Row justify="space-between" align="middle">
                        <Col md={4} lg={4} xl={4} xxl={7} align="right">
                          <Image
                            src={
                              isContent
                                ? Images.dashboardContentClicked
                                : Images.dashboardContent
                            }
                            style={{ cursor: "pointer" }}
                          ></Image>
                        </Col>
                        <Col
                          md={17}
                          lg={17}
                          xl={18}
                          xxl={15}
                          style={{
                            color: "#FFFFFF",
                            fontSize: isXxl ? "20px" : isXl ? "15px" : "18px",
                          }}
                          align="left"
                        >
                          Content Management
                        </Col>
                      </Row>
                    </Link>
                  ) : (
                    <Link to="/content-management">
                      <Image
                        src={
                          isContent
                            ? Images.dashboardContentClicked
                            : Images.dashboardContent
                        }
                        style={{ cursor: "pointer" }}
                      ></Image>
                    </Link>
                  )}
                </Col>
                <Col span={24}>
                  {!collapsed ? (
                    <Link to="/topic-management">
                      <Row justify="space-between" align="middle">
                        <Col md={4} lg={4} xl={4} xxl={7} align="right">
                          <Image
                            src={
                              isTopic
                                ? Images.topicNewIconClicked
                                : Images.topicNewIcon
                            }
                            style={{ cursor: "pointer" }}
                          ></Image>
                        </Col>
                        <Col
                          md={17}
                          lg={17}
                          xl={18}
                          xxl={15}
                          style={{
                            color: "#FFFFFF",
                            fontSize: isXxl ? "20px" : isXl ? "15px" : "18px",
                          }}
                          align="left"
                        >
                          Topic Management
                        </Col>
                      </Row>
                    </Link>
                  ) : (
                    <Link to="/topic-management">
                      <Image
                        src={
                          isTopic
                            ? Images.topicNewIconClicked
                            : Images.topicNewIcon
                        }
                        style={{ cursor: "pointer" }}
                      ></Image>
                    </Link>
                  )}
                </Col>
                <Col span={24}>
                  {!collapsed ? (
                    <Link to="/location-management">
                      <Row justify="space-between" align="middle">
                        <Col md={4} lg={4} xl={4} xxl={7} align="right">
                          <Image
                            src={
                              isLocation
                                ? Images.dashboardLocationClicked
                                : Images.dashboardLocation
                            }
                            style={{ cursor: "pointer" }}
                          ></Image>
                        </Col>
                        <Col
                          md={17}
                          lg={17}
                          xl={18}
                          xxl={15}
                          style={{
                            color: "#FFFFFF",
                            fontSize: isXxl ? "20px" : isXl ? "15px" : "18px",
                          }}
                          align="left"
                        >
                          Location Management
                        </Col>
                      </Row>
                    </Link>
                  ) : (
                    <Link to="/location-management">
                      <Image
                        src={
                          isLocation
                            ? Images.dashboardLocationClicked
                            : Images.dashboardLocation
                        }
                        style={{ cursor: "pointer" }}
                      ></Image>
                    </Link>
                  )}
                </Col>
                <Col span={24}>
                  {!collapsed ? (
                    <Link to="/custom-ad-management">
                      <Row justify="space-between" align="middle">
                        <Col md={4} lg={4} xl={4} xxl={7} align="right">
                          <Image
                            src={
                              isAd
                                ? Images.dashboardAdClicked
                                : Images.adManagement
                            }
                            style={{ cursor: "pointer" }}
                          ></Image>
                        </Col>
                        <Col
                          md={17}
                          lg={17}
                          xl={18}
                          xxl={15}
                          style={{
                            color: "#FFFFFF",
                            fontSize: isXxl ? "20px" : isXl ? "15px" : "18px",
                          }}
                          align="left"
                        >
                          Custom Ad Management
                        </Col>
                      </Row>
                    </Link>
                  ) : (
                    <Link to="/custom-ad-management">
                      <Image
                        src={
                          isAd ? Images.dashboardAdClicked : Images.adManagement
                        }
                        style={{ cursor: "pointer" }}
                      ></Image>
                    </Link>
                  )}
                </Col>
                <Col span={24}>
                  {!collapsed ? (
                    <Link to="/configuration-and-settings">
                      <Row justify="space-between" align="middle">
                        <Col md={4} lg={4} xl={4} xxl={7} align="right">
                          <Image
                            src={
                              isSettings
                                ? Images.dashboardSettingsClicked
                                : Images.dashboardSettings
                            }
                            style={{ cursor: "pointer" }}
                          ></Image>
                        </Col>
                        <Col
                          md={17}
                          lg={17}
                          xl={18}
                          xxl={15}
                          style={{
                            color: "#FFFFFF",
                            fontSize: isXxl ? "20px" : isXl ? "15px" : "18px",
                          }}
                          align="left"
                        >
                          Configuration & Settings
                        </Col>
                      </Row>
                    </Link>
                  ) : (
                    <Link to="/configuration-and-settings">
                      <Image
                        src={
                          isSettings
                            ? Images.dashboardSettingsClicked
                            : Images.dashboardSettings
                        }
                        style={{ cursor: "pointer" }}
                      ></Image>
                    </Link>
                  )}
                </Col>
                <Col span={24} onClick={showModal}>
                  {!collapsed ? (
                    <Link>
                      <Row justify="space-between" align="middle">
                        <Col md={4} lg={4} xl={4} xxl={7} align="right">
                          <Image
                            src={Images.dashboardLogout}
                            style={{ cursor: "pointer" }}
                          ></Image>
                        </Col>
                        <Col
                          md={17}
                          lg={17}
                          xl={18}
                          xxl={15}
                          style={{
                            color: "#FFFFFF",
                            fontSize: isXxl ? "20px" : isXl ? "15px" : "18px",
                          }}
                          align="left"
                        >
                          Log Out
                        </Col>
                      </Row>
                    </Link>
                  ) : (
                    <Link>
                      <Image
                        src={Images.dashboardLogout}
                        style={{ cursor: "pointer" }}
                      ></Image>
                    </Link>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Sider>
      <Modal
        closable={false}
        // title="Basic Modal"
        visible={isModalVisible}
        width={isXxl ? "721px" : isXl ? "600px" : "400px"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {/* <Row>
          <Col span={24}>
            <Row>
              <Col></Col>
              <Col></Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}></Col>
              <Col span={24}></Col>
            </Row>
          </Col>
        </Row> */}
      </Modal>
    </>
  );
};

export default React.memo(NavigationBar);
