import {
  CUSTOM_ADS_REQUEST,
  CUSTOM_ADS_SUCCESS,
  CUSTOM_ADS_FAILURE,
  ADD_CUSTOM_AD_REQUEST,
  ADD_CUSTOM_AD_SUCCESS,
  ADD_CUSTOM_AD_FAILURE,
  UPDATE_CUSTOM_AD_REQUEST,
  UPDATE_CUSTOM_AD_SUCCESS,
  UPDATE_CUSTOM_AD_FAILURE,
  DELETE_CUSTOM_AD_SUCCESS,
  DELETE_CUSTOM_AD_FAILURE,
  DELETE_CUSTOM_AD_REQUEST,
  TOGGLE_AD_REQUEST,
  TOGGLE_AD_SUCCESS,
  TOGGLE_AD_FAILURE,
} from "../../constants";

const initialState = {
  customAds: [],
  adsCount: 0,
  getAdsLoader: false,
  createAdLoader: false,
  updateAdLoader: false,
  deleteAdLoader: false,
  toggleAdLoader: false,
};

export default function CustomAdsManagementReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CUSTOM_ADS_REQUEST:
      state = {
        ...state,
        getAdsLoader: true,
        customAds: [],
      };
      break;

    case CUSTOM_ADS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          customAds: action ? action?.payload : [],
          adsCount: action ? action?.count : 0,
          getAdsLoader: false,
        };
      }
    }

    case CUSTOM_ADS_FAILURE:
      state = {
        ...state,
        getAdsLoader: false,
      };
      break;

    case ADD_CUSTOM_AD_REQUEST:
      state = {
        ...state,
        createAdLoader: true,
      };
      break;

    case ADD_CUSTOM_AD_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          createAdLoader: false,
        };
      }
      break;

    case ADD_CUSTOM_AD_FAILURE:
      state = {
        ...state,
        createAdLoader: false,
      };
      break;

    case UPDATE_CUSTOM_AD_REQUEST:
      state = {
        ...state,
        updateAdLoader: true,
      };
      break;

    case UPDATE_CUSTOM_AD_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          updateAdLoader: false,
        };
      }
      break;

    case UPDATE_CUSTOM_AD_FAILURE:
      state = {
        ...state,
        updateAdLoader: false,
      };
      break;

    case DELETE_CUSTOM_AD_REQUEST:
      state = {
        ...state,
        deleteAdLoader: true,
      };
      break;

    case DELETE_CUSTOM_AD_SUCCESS:
      state = {
        ...state,
        deleteAdLoader: false,
      };

      break;

    case DELETE_CUSTOM_AD_FAILURE:
      state = {
        ...state,
        deleteAdLoader: false,
      };
      break;

    case TOGGLE_AD_REQUEST:
      state = {
        ...state,
        toggleAdLoader: true,
      };
      break;

    case TOGGLE_AD_SUCCESS:
      if (action.payload) {
        state = {
          ...state,
          toggleAdLoader: false,
        };
      }
      break;

    case TOGGLE_AD_FAILURE:
      state = {
        ...state,
        toggleAdLoader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
