import React from "react";
import { Row, Col, Tooltip } from "antd";
import { PopconfirmWrapper, FileBox } from "..";
import { useDispatch } from "react-redux";
import { FormAction } from "../../store/actions/index";

const FormTable = (props) => {
  const { id, fileName, URL, limit, offset } = props;
  const dispatch = useDispatch();
  const deleteForms = () => {
    dispatch(FormAction.DeleteForms({ id }, limit, offset));
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        <Tooltip title={fileName}>
          <FileBox>
            <p
              style={{
                marginTop: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {fileName}
            </p>
          </FileBox>
        </Tooltip>
      </Col>
      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
        <label
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={() => window.open(URL)}
        >
          View
        </label>
      </Col>
      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
        <PopconfirmWrapper
          title={<h4>Are you sure you want to delete this Form?</h4>}
          cancelText="No"
          okText="Yes"
          onConfirm={deleteForms}
          placement="leftBottom"
        >
          <div
            style={{
              padding: "8px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <label style={{ cursor: "pointer", fontWeight: "bold" }}>
              Delete
            </label>
          </div>
        </PopconfirmWrapper>
      </Col>
    </Row>
  );
};

export default React.memo(FormTable);
