import React from "react";
import { DatePicker } from "antd";
// import moment from "moment";
import styled, { css } from "styled-components";

const { RangePicker } = DatePicker;

const RangePickerStyles =
  //styled(RangePicker).attrs(() => ({ size: "large" }))
  css`
    height: 39px;
    border-radius: 5px;
  `;

// ${props => [1,2,3,4,5,6,7,8,9,10,11,12].map((val, ind) => ind > props.currentMonth.month() ? `
//   .ant-calendar-range-right
//   .ant-calendar-month-panel-tbody
//   tr:nth-child(${Math.ceil((props.currentMonth.month() + 1)/3)})
//   .ant-calendar-month-panel-cell:nth-child(${(val % 3) ? (val % 3) : 3})
//   .ant-calendar-month-panel-month {
//     color: rgba(0, 0, 0, 0.25) !important;
//     background: #f5f5f5 !important;
//     cursor: not-allowed !important;
// }

// ` : ``)}

const RangePickerWrapper = (props) => {
  return <RangePicker dropdownClassName={props.className} {...props} />;
};
export default styled(RangePickerWrapper).attrs({ className: "inputField" })`
  ${RangePickerStyles}
`;

// cb funtion recieverd from props to get the selected date
// function onChange(date, dateString) {
// }
