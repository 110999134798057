import React from "react";
import { Row, Col } from "antd";
import InputWrapper from "../../components/input";
import { useMedia } from "use-media";
import TextAndInput from "./TextAndInput";
const AlternativePostWeight = (props) => {
  const { data, handleNumber, errors } = props;
  const {
    within_10,
    score_lt_20,
    score_lt_30,
    score_lt_50,
    score_lt_40,
    post_lt_y_days_old,
    post_gt_x_days_old,
  } = data;
  const {
    within_10Error,
    score_lt_20Error,
    score_lt_30Error,
    score_lt_50Error,
    score_lt_40Error,
    post_lt_y_days_oldError,
    post_gt_x_days_oldError,
  } = errors;
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <Row style={{ borderLeft: "8px solid #E06030",borderRadius: "9px" ,padding: "0rem 1rem" }}>
      <Col
        xs={24}
        sm={24}
        xl={14}
        xxl={7}
        style={{ fontSize: isXxl ? "28px" : isXl ? "20px" : "24px" }}
        align="left"
      >
        <h1>Alternative Post Weights</h1>
      </Col>
      <Col span={24}>
        <Row justify="left" gutter={[0, 10]}>
          <Col span={24}>
            <Row justify="space-between" gutter={[20, 20]}>
              <Col xs={24} sm={24} xl={5} xxl={6}>
                <TextAndInput
                  isAlternativePostWeight
                  title="With in 10"
                  placeholder={within_10 || 0}
                  name="within_10"
                  value={within_10 || 0}
                  handleNumber={handleNumber}
                  errors={within_10Error}
                />
              </Col>
              <Col xs={24} sm={24} xl={5} xxl={6}>
                <TextAndInput
                  isAlternativePostWeight
                  title="Score <= 20"
                  placeholder={score_lt_20 || 0}
                  name="score_lt_20"
                  value={score_lt_20 || 0}
                  handleNumber={handleNumber}
                  errors={score_lt_20Error}
                />
              </Col>
              <Col xs={24} sm={24} xl={5} xxl={6}>
                <TextAndInput
                  isAlternativePostWeight
                  title="Score <=30"
                  placeholder={score_lt_30 || 0}
                  name="score_lt_30"
                  value={score_lt_30 || 0}
                  handleNumber={handleNumber}
                  errors={score_lt_30Error}
                />
              </Col>

              <Col xs={24} sm={24} xl={5} xxl={6}>
                <TextAndInput
                  isAlternativePostWeight
                  title="Score <=40"
                  placeholder={score_lt_40 || 0}
                  name="score_lt_40"
                  value={score_lt_40 || 0}
                  handleNumber={handleNumber}
                  errors={score_lt_40Error}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} xl={18} xxl={18}>
            <Row justify="space-between" gutter={[10, 10]}>
              <Col xs={24} sm={24} xl={7} xxl={8}>
                <TextAndInput
                  isAlternativePostWeight
                  title="Score <=50"
                  placeholder={score_lt_50 || 0}
                  name="score_lt_50"
                  value={score_lt_50 || 0}
                  handleNumber={handleNumber}
                  errors={score_lt_50Error}
                />
              </Col>
              <Col xs={24} sm={24} xl={7} xxl={8}>
                <TextAndInput
                  isAlternativePostWeight
                  title="Post ≤ Y days old"
                  placeholder={post_lt_y_days_old || 0}
                  name="post_lt_y_days_old"
                  value={post_lt_y_days_old || 0}
                  handleNumber={handleNumber}
                  errors={post_lt_y_days_oldError}
                />
              </Col>

              <Col xs={24} sm={24} xl={7} xxl={8}>
                <TextAndInput
                  isAlternativePostWeight
                  title="Post > X days old"
                  placeholder={post_gt_x_days_old || 0}
                  name="post_gt_x_days_old"
                  value={post_gt_x_days_old || 0}
                  handleNumber={handleNumber}
                  errors={post_gt_x_days_oldError}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AlternativePostWeight;
