import { Row, Col, Input } from "antd";
import React from "react";
import { Colors } from "../../config";
import styled from "styled-components";
import Image from "../../components/image";
import usersubs from "../../assets/images/usersubs.svg";
import userdelete from "../../assets/images/userdelete.svg";
const Search = styled(Input)`
  background-color: ${(props) => (props.isTopic ? "#009498" : Colors.White)};
  color: ${Colors.Black};
  padding: 0.5rem 1.5rem;
  border-top-left-radius: ${(props) =>
    props.isTable ? "33px" : props.isTopic ? "0px" : "10px"};
  border-bottom-left-radius: ${(props) =>
    props.isTable ? "33px" : props.isTopic ? "0px" : "10px"};
  & ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #707070;
    opacity: 1; /* Firefox */
  }
  & :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #707070;
  }
  & ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #707070;
  }
`;
const SearchBar = (props) => {
  return <Search {...props}></Search>;
};

export default React.memo(SearchBar);
