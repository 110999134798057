import { CustomAdsManagementAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  CUSTOM_ADS_REQUEST_ENDPOINT,
  ADD_CUSTOM_AD_ENDPOINT,
  UPDATE_CUSTOM_AD_ENDPOINT,
  DELETE_CUSTOM_AD_ENDPOINT,
  TOGGLE_AD_ENDPOINT,
} from "../../../config/variables";
import { put } from "redux-saga/effects";
import moment from "moment";
export default class CustomAdsManagementMiddleware {
  static *CustomAdsRequest(action) {
    const { params } = action;

    const queryParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(
        `${CUSTOM_ADS_REQUEST_ENDPOINT}?${queryParams}`
      );

      if (res.status == 200) {
        yield put(
          CustomAdsManagementAction.CustomAdsSuccess(
            res.data.data,
            res.data.count
          )
        );
      } else {
        yield put(CustomAdsManagementAction.CustomAdsFailure());
      }
    } catch (err) {
      yield put(CustomAdsManagementAction.CustomAdsFailure());
    }
  }

  static *AddCustomAdRequest(action) {
    const { body, cb } = action;

    try {
      let formData = new FormData();

      body.countries.forEach((item) => {
        formData.append("countries[]", item);
      });
      body.gender.forEach((item) => {
        formData.append("gender[]", item);
      });

      body.topic_ids.forEach((item) => {
        formData.append("topic_ids[]", item);
      });
      const { countries, gender, topic_ids, ...rest } = body;

      for (const key in rest) {
        formData.append(key, rest[key]);
      }
      let res = yield ApiCaller.Post(`${ADD_CUSTOM_AD_ENDPOINT}`, formData);

      if (res.status == 200) {
        yield put(
          CustomAdsManagementAction.AddCustomAdSuccess(res.data, res.data.count)
        );
        Info("success", res?.data?.success?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error);
        yield put(CustomAdsManagementAction.AddCustomAdFailure());
      }
    } catch (err) {
      yield put(CustomAdsManagementAction.AddCustomAdFailure());
    }
  }

  static *UpdateCustomAdRequest(action) {
    let { body, cb } = action;
    let formData = new FormData();

    body.countries.forEach((item, index) => {
      formData.append(`countries[${index}]`, item.value);
    });
    body.gender.forEach((item, index) => {
      formData.append(`gender[${index}]`, item.value);
    });

    body.topic_ids.forEach((item, index) => {
      formData.append(`topic_ids[${index}]`, item.value);
    });

    const { countries, gender, topic_ids, ...rest } = body;

    for (const key in rest) {
      formData.append(key, rest[key]);
    }
    try {
      let res = yield ApiCaller.Post(
        `${UPDATE_CUSTOM_AD_ENDPOINT}/${body.id}`,
        formData
      );

      if (res.status == 200) {
        yield put(
          CustomAdsManagementAction.UpdateCustomAdSuccess(res.data.success)
        );
        Info("success", res?.data?.success?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error);
        yield put(CustomAdsManagementAction.UpdateCustomAdFailure());
      }
    } catch (err) {
      yield put(CustomAdsManagementAction.UpdateCustomAdFailure());
    }
  }

  static *ToggleAdRequest(action) {
    let { params, cb } = action;
    try {
      let res = yield ApiCaller.Put(`${TOGGLE_AD_ENDPOINT}/${params.id}`);

      if (res.status == 200) {
        yield put(CustomAdsManagementAction.ToggleAdSuccess(res.data.success));
        Info("success", res?.data?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error.message);
        yield put(CustomAdsManagementAction.ToggleAdFailure());
      }
    } catch (err) {
      yield put(CustomAdsManagementAction.ToggleAdFailure());
    }
  }

  static *DeleteCustomAdRequest(action) {
    let { params, cb } = action;

    const qureyParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Delete(
        `${DELETE_CUSTOM_AD_ENDPOINT}/${params.id}`
      );
      if (res.status == 200) {
        yield put(
          CustomAdsManagementAction.DeleteCustomAdSuccess(res.data.success)
        );
        Info("success", res?.data?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error.message);
        yield put(CustomAdsManagementAction.DeleteCustomAdFailure());
      }
    } catch (err) {
      yield put(CustomAdsManagementAction.DeleteCustomAdFailure());
    }
  }
}
