import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  ADD_PROFILE_REQUEST,
  ADD_PROFILE_SUCCESS,
  ADD_PROFILE_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "../../../store/constants";

export default class LocationManagementAction {
  static ProfileRequest() {
    return {
      type: PROFILE_REQUEST,
    };
  }
  static ProfileSuccess(payload) {
    return {
      type: PROFILE_SUCCESS,
      payload,
    };
  }
  static ProfileFailure() {
    return {
      type: PROFILE_FAILURE,
    };
  }

  static AddProfileRequest(body, cb) {
    return {
      type: ADD_PROFILE_REQUEST,
      body,
      cb,
    };
  }
  static AddProfileSuccess(payload) {
    return {
      type: ADD_PROFILE_SUCCESS,
      payload,
    };
  }
  static AddProfileFailure() {
    return {
      type: ADD_PROFILE_FAILURE,
    };
  }

  static ChangePasswordRequest(body, cb) {
    return {
      type: CHANGE_PASSWORD_REQUEST,
      body,
      cb,
    };
  }
  static ChangePasswordSuccess(payload) {
    return {
      type: CHANGE_PASSWORD_SUCCESS,
      payload,
    };
  }
  static ChangePasswordFailure() {
    return {
      type: CHANGE_PASSWORD_FAILURE,
    };
  }
}
