import {
  GET_USERS_REQUEST,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILURE,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAILURE,
  GETUSERSTATS,
  GETUSERSTATS_SUCCESS,
  GETUSERSTATS_FAILURE,
  GETAPPDOWNLOADCOUNT,
  GETAPPDOWNLOADCOUNT_SUCCESS,
  GETAPPDOWNLOADCOUNT_FAILURE,
  GETUSERTYPECOUNT,
  GETUSERTYPECOUNT_SUCCESS,
  GETUSERTYPECOUNT_FAILURE,
  GETSUBSCRIBEDUSERCOUNT,
  GETSUBSCRIBEDUSERCOUNT_SUCCESS,
  GETSUBSCRIBEDUSERCOUNT_FAILURE,
  GETEVENTSTATSCOUNT,
  GETEVENTSTATSCOUNT_SUCCESS,
  GETEVENTSTATSCOUNT_FAILURE,
  CLEARDASHBOARD,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../../../store/constants";

export default class UserAction {
  static GetUsersRequest(params) {
    return {
      type: GET_USERS_REQUEST,
      params,
    };
  }
  static GetUsersSuccess(payload, data) {
    return {
      type: GET_USERS_SUCCESS,
      payload,
      data,
    };
  }
  static GetUsersFailure() {
    return {
      type: GET_USERS_FAILURE,
    };
  }

  static UpdateUserStatus(params, cb) {
    return {
      type: UPDATE_USER_STATUS_REQUEST,
      params,
      cb,
    };
  }
  static UpdateUserStatusSuccess(payload) {
    return {
      type: UPDATE_USER_STATUS_SUCCESS,
      payload,
    };
  }
  static UpdateUserStatusFailure() {
    return {
      type: UPDATE_USER_STATUS_FAILURE,
    };
  }
  static UserDetailsRequest(params) {
    return {
      type: USER_DETAIL_REQUEST,
      params,
    };
  }
  static UserDetailsSuccess(payload) {
    return {
      type: USER_DETAIL_SUCCESS,
      payload,
    };
  }
  static UserDetailsFailure() {
    return {
      type: USER_DETAIL_FAILURE,
    };
  }

  static GetUserTypeCount() {
    return {
      type: GETUSERTYPECOUNT,
    };
  }
  static GetUserTypeCountSuccess(payload) {
    return {
      type: GETUSERTYPECOUNT_SUCCESS,
      payload,
    };
  }
  static GetUserTypeCountFailure() {
    return {
      type: GETUSERTYPECOUNT_FAILURE,
    };
  }

  static GetSubscribedUserCount(startMonth, startYear, endMonth, endYear) {
    return {
      type: GETSUBSCRIBEDUSERCOUNT,
      startMonth,
      startYear,
      endMonth,
      endYear,
    };
  }
  static GetSubscribedUserCountSuccess(payload) {
    return {
      type: GETSUBSCRIBEDUSERCOUNT_SUCCESS,
      payload,
    };
  }
  static GetSubscribedUserCountFailure() {
    return {
      type: GETSUBSCRIBEDUSERCOUNT_FAILURE,
    };
  }

  static GetUserStats() {
    return {
      type: GETUSERSTATS,
    };
  }
  static GetUserStatsSuccess(payload) {
    return {
      type: GETUSERSTATS_SUCCESS,
      payload,
    };
  }
  static GetUserStatsFailure() {
    return {
      type: GETUSERSTATS_FAILURE,
    };
  }

  static GetEventStats(startMonth, startYear, endMonth, endYear) {
    return {
      type: GETEVENTSTATSCOUNT,
      startMonth,
      startYear,
      endMonth,
      endYear,
    };
  }
  static GetEventStatsSuccess(payload) {
    return {
      type: GETEVENTSTATSCOUNT_SUCCESS,
      payload,
    };
  }
  static GetEventStatsFailure() {
    return {
      type: GETEVENTSTATSCOUNT_FAILURE,
    };
  }

  static GetAppDownloadCount(startMonth, startYear, endMonth, endYear) {
    return {
      type: GETAPPDOWNLOADCOUNT,
      startMonth,
      startYear,
      endMonth,
      endYear,
    };
  }
  static GetAppDownloadCountSuccess(payload) {
    return {
      type: GETAPPDOWNLOADCOUNT_SUCCESS,
      payload,
    };
  }
  static GetAppDownloadCountFailure() {
    return {
      type: GETAPPDOWNLOADCOUNT_FAILURE,
    };
  }

  static ClearDashboard() {
    return {
      type: CLEARDASHBOARD,
    };
  }

  static LoaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }

  static LoaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
}
