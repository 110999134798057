import React, { Component } from "react";
import { LoginBox, ButtonForm } from "../../components";
import { connect } from "react-redux";
import { Row, Col, Input } from "antd";
import { Formik, Form } from "formik";
import { Colors, Schemas } from "../../config";
import { AuthAction } from "../../store/actions";
import { withRouter } from "react-router-dom";
class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      validEmail: true,
    };
  }

  render() {
    //console.log("these are props forgetpassword: ", this.props);
    const { forgetpassword, forgetPasswordLoader, history } = this.props;
    return (
      <LoginBox>
        <Col span={24} style={{ marginTop: "20px" }}>
          <h3>
            <b>Reset your Password</b>
          </h3>
          <p>
            Enter your email address to recieve
            <br /> information about password reset.
          </p>
        </Col>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Schemas.forgetPasswordValidations}
          onSubmit={(values, { resetForm }) => {
            //console.log("these are values forget password: ", values);
            forgetpassword(values, (data, error) => {
              //console.log("IS SIGNED IN ? ", data, error);
              data
                ? history.push({
                    pathname: "/verify-code",
                    state: {
                      email: values.email,
                    },
                  })
                : history.push({ pathname: "/forget-password" });
            });
            resetForm({ values: "" });
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form>
              <Row
                style={{
                  justifyContent: "center",
                }}
                gutter={[0, 60]}
              >
                <Col span={16}>
                  <Input
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  ></Input>
                  <label style={{ color: Colors.Red }}>
                    {errors.email && touched.email && errors.email}
                  </label>
                </Col>
                <Col span={24}></Col>
                <Col span={24} style={{ marginBottom: "20px" }}>
                  <ButtonForm loading={forgetPasswordLoader} htmlType="submit">
                    Continue
                  </ButtonForm>
                </Col>
              </Row>
            </Form>
          )}
        />
      </LoginBox>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    forgetpassword: (email, cb) => {
      dispatch(AuthAction.ForgetPassword(email, cb));
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({
      forgetPasswordLoader: storeState.AuthReducer.forgetPasswordLoader,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(ForgetPassword)
);
