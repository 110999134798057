import { LocationManagementAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  LOCATIONS_REQUEST_ENDPOINT,
  UPDATE_LOCATION_REQUEST_ENDPOINT,
} from "../../../config/variables";
import { put } from "redux-saga/effects";
import moment from "moment";
export default class LocationsRequestMiddleware {
  static *LocationsRequest(action) {
    const { params, cb } = action;
    const qureyParams = new URLSearchParams(params);

    try {
      let res = yield ApiCaller.Get(
        `${LOCATIONS_REQUEST_ENDPOINT}?${qureyParams}`
      );

      if (res.status == 200) {
        yield put(
          LocationManagementAction.LocationsSuccess(
            res.data.data,
            res.data.count
          )
        );
      } else {
        yield put(LocationManagementAction.LocationsFailure());
      }
    } catch (err) {
      yield put(LocationManagementAction.LocationsFailure());
    }
  }
  static *UpdateLocationRequest(action) {
    let { params, cb } = action;
    const qureyParams = new URLSearchParams(params);

    try {
      let res = yield ApiCaller.Put(
        `${UPDATE_LOCATION_REQUEST_ENDPOINT}?${qureyParams}`
      );

      if (res.status == 200) {
        yield put(
          LocationManagementAction.UpdateLocationSuccess(res.data.success)
        );
        Info("success", res?.data?.message);
        if (cb) {
          cb();
        }
      } else {
        Info("error", res.data.error.message);
        yield put(LocationManagementAction.UpdateLocationFailure());
      }
    } catch (err) {
      yield put(LocationManagementAction.UpdateLocationFailure());
    }
  }
}
