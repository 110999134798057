import * as Yup from "yup";

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
  .required("Required fields can not be left empty"),
  password: Yup.string()
  .min(8, "Password is Too Short! it must be between 8 and 16 characters")
  .max(16, "Password is Too Long! it must be between 8 and 16 characters")
  .matches(/^(?=.*[A-Z])(?=.*\W)(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase letter, one number and one symbol.')
  .matches(/^[a-zA-Z0-9\W\S]{8,16}$/, "atleast contain eight characters.")
  .required("Required fields can not be left empty"),
  confirm_password: Yup.mixed().oneOf(
  [Yup.ref('password')], 'Password do not match!'
  )
  .required("Required fields can not be left empty"),
  })