import React, { useState, useEffect } from "react";
import { Row, Col, Pagination } from "antd";
import { ButtonForm } from "../../components";
import { RiSearchLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import ContentManagementAction from "../../store/actions/appactions/ContentManagementAction";
import {
  Blip,
  SearchBar,
  HeaderComponent,
  TransactionTable,
} from "../../components";
import { useMedia } from "use-media";
import Image from "../../components/image/index";
import AllUsers from "./AllUsers";
import RemovedUsers from "./RemovedUsers";
import ReportedUsers from "./ReportedUsers";
import SuspendedUsers from "./SuspendedUsers";
import ReleasedUsers from "./ReleasedUsers";
import contentLogo from "../../../src/assets/images/contentLogo.svg";
import ClearedUsers from "./ClearedUsers";

const ContentManagement = () => {
  const [state, setState] = useState({
    all: true,
    reported: false,
    removed: false,
    suspended: false,
    released: false,
    cleared: false,
    current: 1,
    limit: 10,
    offset: 0,
    like: "",
  });
  const {
    limit,
    like,
    offset,
    all,
    cleared,
    reported,
    removed,
    suspended,
    released,
    current,
  } = state;
  const handleContent = (val) => {
    setState({ ...state, like: val });
    dispatch(ContentManagementAction.ContentsRequest({ like: val }));
  };

  const dispatch = useDispatch();
  const allUsersData = useSelector(
    (state) => state.ContentManagementReducer.contentManagementUsers
  );

  const userCount = useSelector(
    (state) => state.ContentManagementReducer.userCount
  );

  const handlePages = (val) => {
    const limit = 10;
    let offset = (val - 1) * limit;
    // const { getusers } = this.props;
    // const { userSelectType, userStatus, like } = this.state;
    dispatch(
      ContentManagementAction.ContentsRequest({
        filter: all
          ? "all"
          : reported
            ? "reported"
            : removed
              ? "removed"
              : suspended
                ? "suspended"
                : cleared
                  ? "cleared"
                  : "released",
        limit: 10,
        offset: offset,
      })
    );
    setState({ ...state, offset: offset, current: val });
  };
  useEffect(() => {
    dispatch(
      ContentManagementAction.ContentsRequest({
        filter: all ? "all" : reported ? "reported" : "hidden",
        limit: 10,
        offset: 0,
      })
    );
  }, []);
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });

  return (
    <HeaderComponent>
      {/* {updateUserLoader ? (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col span={24}>
            <Spin size="large" />
          </Col>
        </Row>
      ) : ( */}
      <Row
        gutter={[0, 20]}
        justify="space-between"
        style={{
          padding: isXxl ? "8rem 4.5rem 0rem 4.5rem" : "5rem 2rem 0rem 2rem",
        }}
      >
        <Col span={1} align="left">
          <Image src={contentLogo} />
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={10} align="left">
              <h1
                style={{
                  fontSize: isXxl ? "46px" : "35px",
                  color: "#415465",
                  fontWeight: "700",
                }}
              >
                Content Management
              </h1>
            </Col>
            <Col xs={24} sm={24} md={10} lg={7} xl={6} xxl={6}>
              <Row>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={21}>
                  <SearchBar
                    placeholder="Search"
                    isTable
                    onChange={(e) => {
                      handleContent(e.target.value);
                    }}
                    value={like}
                  />
                </Col>
                <Col
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E06030",
                    borderTopRightRadius: "33px",
                    borderBottomRightRadius: "33px",
                  }}
                >
                  {<RiSearchLine style={{ color: "#FFFFFF" }} size={20} />}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} xl={24} xxl={24} style={{ width: "100%" }}>
          <Row gutter={[5, 5]}>
            <Col xl={4}  style={{ width: "10%" }}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: true,
                    reported: false,
                    removed: false,
                    suspended: false,
                    released: false,
                    cleared: false,
                    current: 1,
                  });
                  dispatch(
                    ContentManagementAction.ContentsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "all",
                    })
                  );
                }}
                style={{
                  backgroundColor: all ? "#E06030" : "#F1F1F1",
                  color: all ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                Top 100`s
              </ButtonForm>
            </Col>
            <Col xl={4} style={{ width: "10%" }}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: false,
                    cleared: false,
                    reported: true,
                    removed: false,
                    suspended: false,
                    released: false,
                    current: 1,
                    like: "",
                  });
                  dispatch(
                    ContentManagementAction.ContentsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "reported",
                    })
                  );
                }}
                style={{
                  backgroundColor: reported ? "#E06030" : "#F1F1F1",
                  color: reported ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                Reported
              </ButtonForm>
            </Col>
            <Col xl={4} style={{ width: "10%" }}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: false,
                    cleared: false,
                    reported: false,
                    removed: true,
                    suspended: false,
                    released: false,
                    current: 1,
                    like: "",
                  });
                  dispatch(
                    ContentManagementAction.ContentsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "removed",
                    })
                  );
                }}
                style={{
                  backgroundColor: removed ? "#E06030" : "#F1F1F1",

                  color: removed ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                Admin-Suspended
              </ButtonForm>
            </Col>
            <Col xl={4} style={{ width: "10%" }}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: false,
                    cleared: false,
                    reported: false,
                    removed: false,
                    suspended: true,
                    released: false,
                    current: 1,
                    like: "",
                  });
                  dispatch(
                    ContentManagementAction.ContentsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "suspended",
                    })
                  );
                }}
                style={{
                  backgroundColor: suspended ? "#E06030" : "#F1F1F1",
                  color: suspended ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                Auto-Suspended
              </ButtonForm>
            </Col>
            <Col xl={4} style={{ width: "10%" }}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: false,
                    cleared: false,
                    reported: false,
                    removed: false,
                    suspended: false,
                    released: true,
                    current: 1,
                    like: "",
                  });
                  dispatch(
                    ContentManagementAction.ContentsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "released",
                    })
                  );
                }}
                style={{
                  backgroundColor: released ? "#E06030" : "#F1F1F1",
                  color: released ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                Released
              </ButtonForm>
            </Col>
            <Col xl={4} style={{ width: "10%" }}>
              <ButtonForm
                onClick={() => {
                  setState({
                    ...state,
                    all: false,
                    cleared: true,
                    reported: false,
                    removed: false,
                    suspended: false,
                    released: false,
                    current: 1,
                    like: "",
                  });
                  dispatch(
                    ContentManagementAction.ContentsRequest({
                      limit: 10,
                      offset: 0,
                      filter: "cleared",
                    })
                  );
                }}
                style={{
                  backgroundColor: cleared ? "#E06030" : "#F1F1F1",
                  color: cleared ? "#FFFFFF" : "#3A3A3A",
                  fontSize: isXxl ? "18px" : "14px",
                }}
                isTable
                span={12}
              >
                Cleared
              </ButtonForm>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {all ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px ##D4D4D4",
                  }}
                  className="manageCategoryTable"
                >
                  <AllUsers
                    limit={10}
                    offset={offset}
                    userData={allUsersData}
                    like={like}
                    clearLike={() => setState({ ...state, like: "" })}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={userCount ? userCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : reported ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px ##D4D4D4",
                  }}
                  className="manageCategoryTable"
                >
                  <ReportedUsers
                    limit={10}
                    offset={offset}
                    userData={allUsersData}
                    like={like}
                    clearLike={() => setState({ ...state, like: "" })}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={userCount ? userCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : removed ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px #1E1E2214",
                    borderRadius: "10px",
                  }}
                >
                  <RemovedUsers
                    userData={allUsersData}
                    limit={10}
                    offset={offset}
                    clearLike={() => setState({ ...state, like: "" })}
                    like={like}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={userCount ? userCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : suspended ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px #1E1E2214",
                    borderRadius: "10px",
                  }}
                >
                  <SuspendedUsers
                    userData={allUsersData}
                    limit={10}
                    offset={offset}
                    clearLike={() => setState({ ...state, like: "" })}
                    like={like}
                  // userStatus={userStatus}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={userCount ? userCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : released ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px #1E1E2214",
                    borderRadius: "10px",
                  }}
                >
                  <ReleasedUsers
                    userData={allUsersData}
                    limit={10}
                    offset={offset}
                    like={like}
                    clearLike={() => setState({ ...state, like: "" })}
                  // userStatus={userStatus}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={userCount ? userCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : cleared ? (
            <Row>
              <Col span={24} style={{ padding: "30px 0px" }}>
                <Row
                  style={{
                    boxShadow: "0px 5px 20px #1E1E2214",
                    borderRadius: "10px",
                  }}
                >
                  <ClearedUsers
                    userData={allUsersData}
                    limit={10}
                    offset={offset}
                    like={like}
                    clearLike={() => setState({ ...state, like: "" })}
                  // userStatus={userStatus}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    current={current}
                    pageSize={10}
                    total={userCount ? userCount : 0}
                    onChange={(e) => {
                      handlePages(e);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
      {/* )} */}
    </HeaderComponent>
  );
};

export default ContentManagement;
