import React from "react";
import { Row, Col } from "antd";
import useMedia from "use-media";
import ReactPlayer from "react-player";
import { Image, HeaderComponent } from "../../components";
import postsPlayButton from "../../assets/images/postsPlayButton.png";

const UsersPost = (props) => {
  const { file_url, status, thumb_url, media_type } = props;
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return media_type == "image" ? (
    <Row
      justify="center"
      style={{
        height: isXxl ? "280px" : "180px",
        backgroundImage: `url(${thumb_url})`,
        backgroundColor: "#F1F1F1",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",

        backgroundPosition: "center",
        borderTop: "9px solid #009498",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Col></Col>
    </Row>
  ) : (
    <Row
      justify="center"
      style={{
        height: isXxl ? "280px" : "180px",
        backgroundImage: `url(${thumb_url})`,
        backgroundColor: "#F1F1F1",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        borderTop: "9px solid #009498",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Col>
        <img src={postsPlayButton} width="70px" height="70px" />
      </Col>
    </Row>
  );
};

export default UsersPost;
