import {
  GETFORMS,
  GETFORMS_SUCCESS,
  GETFORMS_FAILURE,
  UPLOADFORMS,
  UPLOADFORMS_SUCCESS,
  UPLOADFORMS_FAILURE,
  DELETEFORMS,
  DELETEFORMS_SUCCESS,
  DELETEFORMS_FAILURE,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../../../store/constants";

export default class FormAction {
  static GetForms(limit, offset) {
    return {
      type: GETFORMS,
      limit,
      offset,
    };
  }
  static GetFormsSuccess(payload) {
    return {
      type: GETFORMS_SUCCESS,
      payload,
    };
  }
  static GetFormsFailure() {
    return {
      type: GETFORMS_FAILURE,
    };
  }

  static UploadForms(payload, limit, offset) {
    return {
      type: UPLOADFORMS,
      payload,
      limit,
      offset,
    };
  }
  static UploadFormsSuccess(payload) {
    return {
      type: UPLOADFORMS_SUCCESS,
      payload,
    };
  }
  static UploadFormsFailure() {
    return {
      type: UPLOADFORMS_FAILURE,
    };
  }

  static DeleteForms(payload, limit, offset) {
    return {
      type: DELETEFORMS,
      payload,
      limit,
      offset,
    };
  }
  static DeleteFormsSuccess(payload) {
    return {
      type: DELETEFORMS_SUCCESS,
      payload,
    };
  }
  static DeleteFormsFailure() {
    return {
      type: DELETEFORMS_FAILURE,
    };
  }

  static LoaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }

  static LoaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
}
